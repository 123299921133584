import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchMaterials(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/catalog/material/?format=datatables' + extra)
    },
    getMaterial(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/material/${id}/`)
    },
    changeMaterial(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/catalog/material/${id}/`, params)
    },
    newMaterial(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/material/`, params)
    },
    deleteMaterial(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/material/${id}/`)
    }
}
