<template>
    <panel title="Dettaglio Categoria" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-primary" @click="changePublished"><i :class="`fa fa-${published ? 'eye' : 'eye-slash'}`"></i> Pubblicato: {{ published ? "SI" : "NO" }}</a>
            <a class="btn btn-danger" v-if="id" @click="deleteCategory"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-4" >
                <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Attuale</label>
                <img :src="coverImage" class="pointer mt-2" @click="utils.download(coverImage)" width="100%">
            </div>
            <div class="col-md-7 col-lg-8 col-xl-8">
                <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Copertina</label>
                <file-pond :maxFileSize="constants.MAX_FILE_SIZE" 
                    labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                    class="mt-2"
                    name="test"
                    ref="coverImage"
                    label-idle="Clicca qui o trascina il file"
                    v-bind:allow-multiple="false"
                    :accepted-file-types="constants.ACCEPTED_FILE_TYPES"
                    v-on:updatefiles="updateCoverImage"
                    />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4 my-3">
                <label>Colore</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" :style="`color: ${color}; background-color: ${color}`" @click="showPicker">om</span>
                    </div>
                    <input type="text" v-model="color" class="form-control" placeholder="Colore">
                </div>
                <chrome-picker ref="colorpicker" v-if="displayPicker" :value="color" @input="updateColor"/>
            </div>
            <div class="form-group col-md-4 my-3">
                <label>Ordine</label>
                <div class="input-group">
                    <input type="text" v-model="weight" class="form-control" placeholder="Ordine">
                </div>
            </div>
        </div>
        <lang-tabs ref="langTab" :availableLangs="availableLangs">
            <template v-slot:content="props">
                <div class="form-group mb-3">
                    <label>Titolo</label>
                    <input type="text" v-model="title[props.selectedLang]" class="form-control" placeholder="Titolo">
                </div>
                <div class="form-group mb-3">
                    <label>Sottotitolo</label>
                    <input type="text" v-model="summary[props.selectedLang]" class="form-control" placeholder="Sottotitolo">
                </div>
            </template>
        </lang-tabs>
    </panel>
</template>
<script>
import CategoryService from '../api/CategoryService.js'
import utils from '../config/utils.js'
import swal from '../config/swal.js'
import loader from '../config/loader.js'
import constants from '../config/constants.js'
export default {
    data() {
        return {
            constants,
            id: "",
            title: utils.getEmptyObjectLang(),
            summary: utils.getEmptyObjectLang(),
            published: true,
            coverImage: null,
            newCoverImage: null,
            utils: utils,
            reserved: false,
            color: "",
            displayPicker: false,
            availableLangs: [],
            isSaved: false,
            oldData: "",
            weight: 0
        }
    },
    async mounted() {
        if(this.isReserved) {
            this.reserved = true
        }
        if(this.isEdit) {
            this.id = this.$route.params.id
            loader.showLoader()
            await CategoryService.getCategory(this.id)
                .then(resp => {
                    this.title = resp.data.title
                    this.summary = resp.data.summary
                    this.published = resp.data.published
                    this.coverImage = resp.data.cover_image
                    this.color = resp.data.color
                    this.weight = resp.data.weight
                    if(!utils.checkIsUndefined(this.title)){
                        for(var key in this.title){
                            this.availableLangs.push(key)
                        }
                    }
                })
                .catch(err => console.log(err))
            loader.hideLoader()
        }
        this.oldData = utils.getJsonData(this.$data)
    },
    computed: {
        isEdit() {
            return this.$route.name.includes("Edit")
        },
        isReserved() {
            return this.$route.name.includes("Reserved")
        }
    },
    methods: {
        changePublished() {
            swal.withConfirm(
                'Sei sicuro di voler cambiare la visibilità?', 
                `Confermando ${this.published ? "non" : ""} sarà visibile!`, 
                'Visibilità cambiata. Ricordati di salvare!',
                () => {
                    this.published = !this.published
                })
        },
		showPicker() {
			this.displayPicker = !this.displayPicker;
		},
        updateColor(color) {
            this.color = color.hex
        },
        updateCoverImage(files) {
            this.newCoverImage = files[0]
        },
        backRoute(){
            if(this.isReserved) {
                this.$router.push({name: 'ReservedCategories'})
                return
            }
            this.$router.push({name: 'Categories'})
        },
        async save(){
            loader.showLoader()
            let params = new FormData()
                params.append("reserved", this.reserved)
                params.append("published", this.published)
                params.append("weight", this.weight)
                if(!utils.checkIsUndefined(this.$refs.langTab.langs)) {
                    var title = {}
                    var summary = {}
                    this.$refs.langTab.langs.forEach(ls => {
                        if(ls.visible){
                            var langValue = ls.value
                            if(utils.checkIsUndefined(this.summary[langValue])){
                                summary[langValue] = ""
                            } else {
                                summary[langValue] = this.summary[langValue]
                            }
                            if(utils.checkIsUndefined(this.title[langValue])){
                                title[langValue] = ""
                            } else {
                                title[langValue] = this.title[langValue]
                            }
                        }
                    })
                    params.append('summary', JSON.stringify(summary))
                    params.append('title', JSON.stringify(title))
                }
                params.append("color", this.color)
                if(!utils.checkIsUndefinedOrEmpty(this.newCoverImage) && typeof this.newCoverImage != "string"){
                    params.append('cover_image', this.newCoverImage.file)
                }
            if(this.isEdit) {
                await CategoryService.changeCategory(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await CategoryService.newCategory(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteCategory() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await CategoryService.deleteCategory(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }

}
</script>