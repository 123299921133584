export default {
    categories: require('@/assets/icons/dashboard/categorie.svg'),
    materials: "Materiali",
    articles_total: "Totali Articoli",
    articles_public: require('@/assets/icons/dashboard/articoli.svg'),
    articles_reserved: "Articoli Riservati",
    articles_components_total: "Totali Componenti",
    articles_components_public: "Totali Componenti Pubblicati",
    downloads: require('@/assets/icons/dashboard/download.svg'),
    downloads_public: "Download Pubblicati",
    downloads_authenticated: require('@/assets/icons/dashboard/download_riservati.svg'),
    downloads_reserved: require('@/assets/icons/dashboard/download_riservati.svg'),
    news: require('@/assets/icons/dashboard/novita.svg'),
    news_public: require('@/assets/icons/dashboard/novita.svg'),
    users: require('@/assets/icons/dashboard/utenti.svg'),
    users_active: require('@/assets/icons/dashboard/utenti_attivi.svg'),
    users_inactive: "url(@/assets/icons/dashboard/utenti.svg)",
    users_staff: "Utenti Staff",
    users_admin: "Utenti Admin"
}