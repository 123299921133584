import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchArticles(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/catalog/article/?format=datatables' + extra)
    },
    getArticle(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/article/${id}/`)
    },
    getArticleReserved(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/article_reserved/${id}/`)
    },
    getArticleComponents(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/article_components/${id}/`)
    },
    changeArticle(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/catalog/article/${id}/`, params)
    },
    newArticle(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/article/`, params)
    },
    deleteArticle(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/article/${id}/`)
    },
    changeComponent(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/catalog/component/${id}/`, params)
    },
    newComponent(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/component/`, params)
    },
    deleteComponent(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/component/${id}/`)
    },
    changeImage(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/catalog/image/${id}/`, params)
    },
    newImage(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/image/`, params)
    },
    deleteImage(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/image/${id}/`)
    },
    generateArticle(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/generate/article/${id}/`)
    },
    newArticleReserved(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/reserved_article/`, params)
    },
    deleteArticleReserved(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/reserved_article/${id}/`)
    },
    importComponents(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/component/dropzone/`, params)
    },
    exportExcel() {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/catalog/export-user-reserved/', {
            responseType: 'blob'
          })
    },
    importExcel(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post('api/admin/catalog/import-user-reserved/', params)
    },
}
