import Vue from 'vue'
import Vuex from 'vuex'
import router from '../config/AppRoutes.vue'
import LoginService from '../api/LoginService.js'
import axios from 'axios'
import notification from '../config/notification.js'
// import VueRouter from 'vue-router'

Vue.use(Vuex);

// const router = new VueRouter({
// 	routes
// })

const store = new Vuex.Store({
    state: {
        user: JSON.parse(localStorage.getItem('user')) || '',
        token: localStorage.getItem('token') || '',
        refresh: localStorage.getItem('refresh') || '',
        loader: false
    },
    actions: {
        async signinUser(context, payload) {
            const { user } = payload;
            context.commit('loginUser');
            await LoginService.authenticate(user)
                .then(resp => {
                    context.commit('setUser', resp.data.user);
                    context.commit('loginUserSuccess', resp.data.access);
                    context.commit('setRefresh', resp.data.refresh);
                })
                .catch(error => {
                    var err = error.response
                    console.log(err)
                    if(Array.isArray(Object.values(err.data)[0])) {
                        notification.notifyError(Object.values(err.data)[0][0])
                    } else {
                        notification.notifyError(Object.values(err.data)[0])
                    }
                    
                    if(err.status === 400){
                        context.commit('loginUserFailure', 'Credenziali Errate');
                    } else {
                        context.commit('loginUserFailure', error.message);
                    }
                });
        },
        logout(context) {
            context.commit('logoutUser')
        }
    },
    getters: {
        user(state) {
            return state.user
        },
        loader(state) {
            return state.loader
        }
    },
    mutations: {
        setLoader(state, value) {
            state.loader = value
        },
        loginUser(state) {
            state.loader = true
        },
        setUser(state, user){
            state.user = user
            localStorage.setItem('user',JSON.stringify(user));
        },
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token);
        },
        setRefresh(state, refresh) {
            state.refresh = refresh
            localStorage.setItem('refresh', refresh);
        },
        loginUserSuccess(state, token) {
            state.token = token
            localStorage.setItem('token', token);
            setTimeout(function(){
                router.push(router.history.current.query.redirect || {name: 'Home'})
                // Vue.notify({
                //     group: 'message',
                //     type: 'success',
                //     text: 'Login avvenuto con successo!'
                // });
                state.loader = false
           },1500);
        },
        loginUserFailure(state, error) {
            console.log(error)
            state.loader = false
            // Vue.notify({
            //     group: 'message',
            //     type: 'error',
            //     text: error
            // });
        },
        logoutUser(state) {
            delete axios.defaults.headers.common['Authorization']
            state.token = ''
            localStorage.removeItem('user');
            state.user = null
            localStorage.removeItem('token');
            router.push({name: 'Login'});
        },
        logoutUserWithoutLogin(state) {
            delete axios.defaults.headers.common['Authorization']
            state.token = ''
            localStorage.removeItem('user');
            state.user = null
            localStorage.removeItem('token');
        }
    }
})

export default store
