export default {
    categories: "Categorie",
    materials: "Materiali",
    articles_total: "Totali Articoli",
    articles_public: "Articoli",
    articles_reserved: "Articoli Riservati",
    articles_components_total: "Totali Componenti",
    articles_components_public: "Totali Componenti Pubblicati",
    downloads: "Downloads",
    downloads_public: "Download Pubblicati",
    downloads_authenticated: "Download Autenticati",
    downloads_reserved: "Download Riservati",
    news: "Novità",
    news_public: "Novità",
    users: "Utenti",
    users_active: "Utenti Attivi",
    users_inactive: "Utenti Inattivi",
    users_staff: "Utenti Staff",
    users_admin: "Utenti Admin"
}