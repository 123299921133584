<template>
    <panel title="Dettaglio Template" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-danger" v-if="id" @click="deleteTemplate"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="form-group col-12">
                <label>Codice</label>
                <div class="input-group">
                    <select v-model="code" class="form-select" placeholder="Codice">
                        <option v-for="code_template in constants.CODE_TEMPLATE_EMAIL" :key="code_template.value" :value="code_template.value">
                            {{ code_template.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <lang-tabs ref="langTab" :availableLangs="availableLangs">
            <template v-slot:content="props">
                <div class="form-group mb-3">
                    <label>Soggetto <strong>{{ hintCurriculum }}</strong></label>
                    <input type="text" v-model="subject[props.selectedLang]" class="form-control" placeholder="Soggetto">
                </div>
                <div class="form-group mb-3">
                    <label>Testo Body</label>
                    <textarea rows="10" type="text" v-model="body_text[props.selectedLang]" class="form-control" placeholder="Testo Body"></textarea>
                </div>
                <div class="form-group mb-3">
                    <label>Testo Html</label>
                    <div class="form-check form-switch mb-3">
                        <label class="form-check-label" for="flexSwitchCheckDefault">HTML</label>
                        <input v-model="html" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                    </div>
                    <div v-if="code" class="mb-3">
                        <label class="mr-3">Controlla i placeholder da inserire</label>
                        <b-button variant="primary" class="btn btn-icon btn-primary" id="placeholders"><i class="fa fa-question"></i></b-button>
                        <b-popover target="placeholders" title="Placeholder da inserire">
                            <span v-html="placeholders"></span>
                        </b-popover>
                    </div>
                    <textarea v-if="html" type="text" v-model="body_html[props.selectedLang]" class="form-control" placeholder="Testo Html" rows="10"></textarea>
                    <Editor
                        v-else
                        v-model="body_html[props.selectedLang]"
                        tinymce-script-src="/tinymce/tinymce.min.js"
                        :init="{
                            plugins: 'lists link image table code help wordcount'
                        }"
                    />
                    <!-- <vue-editor v-model="body_html[props.selectedLang]" :editorToolbar="customToolbar" /> -->
                    <!-- <mavon-editor :ref="`editor-${props.selectedLang}`" v-model="body_html[props.selectedLang]" language="it"/> -->
                </div>
                <div class="row">
                    <div :class="['col-md-12']">
                        <label class="form-check-label" for="flexSwitchCheckDefault">File</label>
                        <file-pond
                            :maxFileSize="constants.MAX_FILE_SIZE_BIG"
                            labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                            class="mt-2"
                            name="test"
                            ref="file"
                            label-idle="Clicca qui o trascina i file"
                            :files="$data[`files_${props.selectedLang}`]"
                            v-bind:allow-multiple="true"
                            v-on:updatefiles="files => updateFile(files, [props.selectedLang])"
                            />
                    </div>
                    <div class="col-12">
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center wrap-anywhere"
                                v-for="(file, index) in $data[`fetch_files_${props.selectedLang}`] " :key="index">
                                <span>{{ file.name }}</span>
                                <span class="badge badge-primary badge-pill">
                                    <!-- <button class="btn btn-info mr-2 mreye handle cursor-grab" :disabled="!isEditArticle"
                                        @click.prevent><i class="fa fa-arrows"></i></button> -->
                                    <button class="btn btn-primary mr-2 mreye" @click="utils.download(file.file)"><i class="fa fa-eye"></i></button>
                                    <button class="btn btn-danger"
                                        @click="deleteFile(file, index, props.selectedLang)"><i class="fa fa-trash"></i></button>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </lang-tabs>
    </panel>
</template>
<script>
import EmailTemplateService from '../api/EmailTemplateService'
import utils from '../config/utils.js'
import swal from '../config/swal.js'
import loader from '../config/loader.js'
import constants from '../config/constants.js'
import notification from '../config/notification'
import Editor from '@tinymce/tinymce-vue'
export default {
    components: {
        Editor
    },
    data() {
        return {
            id: "",
            html: false,
            tag: null,
            subject: utils.getEmptyObjectLang(),
            body_html: utils.getEmptyObjectLang(),
            body_text: utils.getEmptyObjectLang(),
            files_it: [],
            files_en: [],
            files_fr: [],
            files_de: [],
            files_es: [],
            fetch_files_it: [],
            fetch_files_en: [],
            fetch_files_fr: [],
            fetch_files_de: [],
            fetch_files_es: [],
            utils: utils,
            availableLangs: [],
            isSaved: false,
            oldData: "",
            constants,
            code: "",
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                // ['table', 'column-left', 'column-right', 'row-above', 'row-below', 'row-remove', 'column-remove'],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link", "image", "video"],
                ["clean"] // remove formatting button
            ]
        }
    },
    async mounted() {
        loader.showLoader()
        if(this.isEdit) {
            this.id = this.$route.params.id
            await EmailTemplateService.getEmailTemplate(this.id)
                .then(resp => {
                    this.subject = resp.data.subject
                    this.body_html = resp.data.body_html
                    this.body_text = resp.data.body_text
                    this.code = resp.data.code
                    
                    if(!utils.checkIsUndefined(this.subject)){
                        for(var key in this.subject){
                            this.availableLangs.push(key)
                            if(!utils.checkObjectIsUndefinedOrEmpty(resp.data.attachments)) {
                                if(!utils.checkArrayIsUndefinedOrEmpty(resp.data.attachments[key])){
                                    this.$data[`fetch_files_${key}`] = resp.data.attachments[key]
                                }
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            this.oldData = utils.getJsonData(this.$data)
        }
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name.includes("Edit")
        },
        placeholders() {
            if(utils.checkIsUndefinedOrEmpty(this.code)) {
                return ''
            }
            var object = constants.CODE_TEMPLATE_EMAIL.find(e => e.value === this.code)
            if(utils.checkObjectIsUndefinedOrEmpty(object)){
                return ''
            }
            var string = ''
            object.placeholders.forEach(placeholder => {
                string += `{{ ${placeholder} }}<br/>`
            })
            return string
        },
        hintCurriculum() {
            return this.code.includes('curriculum') ? "DEVI INSERIRE {{ sequential_number }} per mettere il numero del curriculum" : ""
        }
    },
    methods: {
        async deleteFile(file, index, lang) {
            swal.withConfirm(
                'Sei sicuro di eliminare l\'allegato?',
                `Confermando l'allegato verrà eliminato definitivamente!`,
                'Allegato eliminato!',
                async () => {
                    loader.showLoader()
                    await EmailTemplateService.deleteEmailTemplateFile(file.id)
                        .then(() => {
                            this.$data[`fetch_files_${lang}`].splice(index, 1)
                        })
                        .catch(err => {
                            console.log(err)
                        })

                    loader.hideLoader()
                })
        },
        updateFile(files, lang) {
            this[`files_${lang[0]}`] = files
        },
        backRoute(){
            this.$router.push({name: 'EmailTemplates'})
        },
        async save(){
            loader.showLoader()
            let params = new FormData()
                params.append("code", this.code)
                if(!utils.checkIsUndefined(this.$refs.langTab.langs)) {
                    var subject = {}
                    var body_text = {}
                    var body_html = {}
                    this.$refs.langTab.langs.forEach(ls => {
                        if(ls.visible){
                            var langValue = ls.value
                            if(utils.checkIsUndefined(this.subject[langValue])){
                                subject[langValue] = ""
                            } else {
                                subject[langValue] = this.subject[langValue]
                            }
                            if(utils.checkIsUndefined(this.body_text[langValue])){
                                body_text[langValue] = ""
                            } else {
                                body_text[langValue] = this.body_text[langValue]
                            }
                            if(utils.checkIsUndefined(this.body_html[langValue])){
                                body_html[langValue] = ""
                            } else {
                                console.log(this.$refs[`editor-${langValue}`])
                                body_html[langValue] = this.body_html[langValue]
                            }
                            if(!utils.checkArrayIsUndefinedOrEmpty(this[`files_${langValue}`]) && typeof this[`files_${langValue}`]!= "string"){
                                this[`files_${langValue}`].forEach(file => {
                                    params.append(`files_${langValue}`, file.file)
                                });
                            }
                        }
                    })
                    params.append('subject', JSON.stringify(subject))
                    params.append('body_text', JSON.stringify(body_text))
                    params.append('body_html', JSON.stringify(body_html))
                }
                // if(!utils.checkIsUndefinedOrEmpty(this.file) && typeof this.file != "string"){
                //     params.append('file', this.file.file)
                // }
            if(this.isEdit) {
                await EmailTemplateService.changeEmailTemplate(this.id, params)
                    .then(() => {
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        notification.notifyError("Errore, durante la modifica")
                        console.log(err)
                    })
            } else {
                await EmailTemplateService.newEmailTemplate(params)
                    .then(() => {
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        notification.notifyError("Errore, durante la creazione")
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteTemplate() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await EmailTemplateService.deleteEmailTemplate(this.id)
                        .then(() => {
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }

}
</script>

<style scoped>
div.markdown-body {
    box-shadow: none !important;
    border-radius: 0;
    border: 1px solid rgba(var(--app-component-border-color-rgb));
}
</style>