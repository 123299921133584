export default {
    AVAILABLE_FROM: ["available_from_date"],
    SUBMISSION_DATE: ["submission_date"],
    PERSONAL_DATA: ["first_name", "last_name", "phone_number", "email", "birth_city", "birth_province", "birth_country", "birth_date", "residence_city", "residence_postal_code", "residence_province", 
        "residence_address", "residence_address_number", "residence_country"],
    WORK: ["work_administration_control", "work_sales_italy", "work_sales_foreign", "work_warehouse_shipping", "work_packaging", "work_tooling", "work_production", "work_technical_office", "work_other"],
    HEALTH: ["health_suitability_any_job"],
    DRIVER: ["driver_own_vehicle", "driver_license_type"],
    FAMILY: ["family_father", "family_mother", "family_partner", "family_sons"],
    STUDY: ["study_primary_school", "study_middle_school", "study_diploma", "study_degree", "study_other"],
    STUDY_LAST: ["study_last_year", "study_last_institute", "study_last_city", "study_last_province", "study_last_score"],
    EXPERIENCE: ["experience"],
    LANG_IT: ["lang_it_comprehension_listen", "lang_it_comprehension_read", "lang_it_verbal_interaction", "lang_it_verbal_oral", "lang_it_write"],
    LANG_EN: ["lang_en_comprehension_listen", "lang_en_comprehension_read", "lang_en_verbal_interaction", "lang_en_verbal_oral", "lang_en_write"], 
    LANG_FR: ["lang_fr_comprehension_listen", "lang_fr_comprehension_read", "lang_fr_verbal_interaction", "lang_fr_verbal_oral", "lang_fr_write"], 
    LANG_DE: ["lang_de_comprehension_listen", "lang_de_comprehension_read", "lang_de_verbal_interaction", "lang_de_verbal_oral", "lang_de_write"], 
    LANG_ES: ["lang_es_comprehension_listen", "lang_es_comprehension_read", "lang_es_verbal_interaction", "lang_es_verbal_oral", "lang_es_write"], 
    LANG_OT: ["lang_other_notes",],
    COMPETENCES_TECHNICAL: ["competences_technical_drawing_read", "competences_technical_drawing_draw", "competences_technical_drawing_cad"],
    COMPETENCES_IT: ["competences_it_windows", "competences_it_linux", "competences_it_sap", "competences_it_docs", "competences_it_calc", "competences_it_other_name", "competences_it_other"],
    COMPETENCES_METALWORKERS: ["competences_metalworkers_forklifts", "competences_metalworkers_welding", "competences_metalworkers_mechanical_presses", "competences_metalworkers_molds_on_press", "competences_metalworkers_mold_construction", "competences_metalworkers_manual_control", "competences_metalworkers_cnc", "competences_metalworkers_robot"],
    TRAVEL_ITALY: ["travel_italy", "exhibitions_italy"],
    TRAVEL_FOREIGN: ["travel_foreign", "exhibitions_foreign"],
    CONTRACT: ["contract_part_time", "contract_apprentice", "contract_fixed_term", "contract_permanent", "contract_collaboration", "contract_month_retribution",
        "contract_month_retribution_type", "contract_month_payouts", "contract_minimum"],
    NOTE: ["note"],
    FILE: ["files"],
    CONSENT: ["send_to_user_email", "privacy_consent"]
}