<template>
    <vue-good-table
        :totalRows="recordsTotal"
        :sort-options="{enabled: true}"
        :pagination-options="pagination || constants.PAGINATION_OPTIONS"
        mode="remote"
        :columns="columns"
        ref="table"
        :rows="rows"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :select-options="{
            enabled: selectable,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'elementi selezionati',
            clearSelectionText: 'Rimuovi',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }">
        <template slot='table-row' slot-scope='props'>
            <div v-if="props.column.field == 'cmd'" class="d-flex flex-column">
                <button type="button" @click="$emit('edit', props.row.id)" class="btn btn-primary"><i class='fa mr-2' :class="isCurriculum ? 'fa-eye' : 'fa-pencil'" /> {{ isCurriculum ? 'Visualizza' : 'Modifica'}}</button>
                <button type="button" v-if="isTemplate" @click="$emit('duplicate', props.row.id)" class="btn btn-warning mt-3"><i class='fa fa-copy mr-2 ' /> Duplica</button>
            </div>
                <span v-else-if="props.column.field == 'text'">
                    <div class="max-height-text" v-html="props.row.text.it"></div>
                </span>
                <span v-else-if="props.column.field == 'title'">
                    <div class="max-height-text" v-if="utils.checkIsUndefined(props.row.title.it)" v-html="props.row.title"></div>
                    <div class="max-height-text" v-else v-html="props.row.title.it"></div>
                </span>
                <span v-else-if="props.column.field == 'name'">
                    <div class="max-height-text" v-if="utils.checkIsUndefined(props.row.name.it)" v-html="props.row.name"></div>
                    <div class="max-height-text" v-else v-html="props.row.name.it"></div>
                </span>
                <span v-else-if="props.column.field == 'code' && $route.name === 'EmailTemplates'">
                    {{ utils.findObject(constants.CODE_TEMPLATE_EMAIL, props.row.code, 'value').name }}
                </span>
                <span v-else-if="props.column.field == 'value'">
                    <div class="max-height-text" v-if="utils.checkIsUndefined(props.row.value.it)" v-html="props.row.value"></div>
                    <div class="max-height-text" v-else v-html="props.row.value.it"></div>
                </span>
                <span v-else-if="props.column.field == 'material__id'">
                    <div class="max-height-text" v-if="utils.checkIsUndefined(props.row.material)"></div>
                    <div class="max-height-text" v-else>{{ props.row.material.title.it }}</div>
                </span>
                <span v-else-if="props.column.field == 'summary'">
                    <div class="max-height-text" v-html="props.row.summary.it"></div>
                </span>
                <span v-else-if="props.column.field == 'user'">
                    <div class="max-height-text" v-if="utils.checkIsUndefined(props.row.user)"></div>
                    <div v-else>{{ props.row.user.email }}</div>
                </span>
                <span v-else-if="props.column.field == 'action'">
                    <div>{{ getStringAction(props.row.action) }}</div>
                </span>
                <span v-else-if="props.column.field == 'description'">
                    <div class="max-height-text" v-html="props.row.description.it"></div>
                </span>
                <span v-else-if="props.column.field == 'company'">
                    <div class="max-height-text" v-html="props.row.user.extra.company"></div>
                </span>
                <span v-else-if="props.column.field == 'state'">
                    <div class="max-height-text">{{ utils.findObject(constants.STATE_REQUEST, props.row.state, 'value').name  }}</div>
                </span>
                <span v-else-if="props.column.field == 'category__id'">
                    <div class="max-height-text" v-html="props.row.category.title.it"></div>
                </span>
                <span v-else-if="props.column.field == 'in_footer'">
                    <i class="fa fa-check check-true" v-if="props.row.in_footer"></i>
                    <i class="fa fa-times check-false" v-else></i>
                </span>
                <span v-else-if="props.column.field == 'published'">
                    <i class="fa fa-check check-true" v-if="props.row.published"></i>
                    <i class="fa fa-times check-false" v-else></i>
                </span>
                <span v-else-if="props.column.field == 'data'">
                    <div v-if="$route.name === 'Audit'">
                        <div v-if="props.row.action === 'catalog_download_reserved_catalog'">
                        </div>
                        <div v-else-if="props.row.action === 'download_price_list'">
                            Listino prezzi: <router-link :to="{name: 'EditPriceList', params: {id: props.row.data.price_list.id }}">{{ props.row.data.price_list.name.it }} </router-link>
                        </div>
                        <div v-else-if="props.row.action === 'catalog_search'">
                            <span v-if="!utils.checkArrayIsUndefinedOrEmpty(props.row.data.categories)">
                                Categorie: <router-link v-for="category in props.row.data.categories" :key="category.id" :to="{name: 'EditCategory', params: {id: category.id }}">{{ category.title.it }}, </router-link>
                                <br/>
                            </span>
                            <span v-if="!utils.checkArrayIsUndefinedOrEmpty(props.row.data.materials)">
                                Materiali: <router-link v-for="material in props.row.data.materials" :key="material.id" :to="{name: 'EditMaterial', params: {id: material.id }}">{{ material.title.it }}, </router-link>
                                <br/>
                            </span>
                            <span v-if="!utils.checkIsUndefinedOrEmpty(props.row.data.title)">
                                Descrizione: {{ props.row.data.title }}
                                <br/>
                            </span>
                            <span v-if="!utils.checkIsUndefinedOrEmpty(props.row.data.article)">
                                Articolo: {{ props.row.data.article }}
                                <br/>
                            </span>
                            <span v-if="!utils.checkIsUndefinedOrEmpty(props.row.data.code)">
                                Codice: {{ props.row.data.code }}
                                <br/>
                            </span>
                            <span v-if="props.row.data.diameter.value[1] > 0">
                                Diametro: <span>{{ props.row.data.diameter.value[0] }}</span> - <span>{{ props.row.data.diameter.value[1] }}</span> <span>{{ props.row.data.diameter.unit }}</span>
                                <br/>
                            </span>
                            <!-- {{ props }} -->
                        </div>
                        <div v-else-if="props.row.action === 'download_download'">
                          <router-link :to="{name: 'EditDownload', params: {id: props.row.data.download.id }}" v-if="!utils.checkObjectIsUndefinedOrEmpty(props.row.data.download)">{{ props.row.data.download.title.it }}</router-link>
                        </div>
                        <div v-else-if="props.row.action === 'download_reserved_download'">
                          <router-link :to="{name: 'EditDownload', params: {id: props.row.data.reserved_download.id }}" v-if="!utils.checkObjectIsUndefinedOrEmpty(props.row.data.reserved_download)">{{ props.row.data.reserved_download.title.it }}</router-link>
                        </div>
                        <div v-else>
                            N articolo:
                            <router-link :to="{name: 'EditArticle', params: {id: props.row.data.article_id }}" v-if="utils.checkObjectIsUndefinedOrEmpty(props.row.data.article)">{{ props.row.data.article_id }}</router-link>
                            <router-link v-else :to="{name: 'EditArticle', params: {id: props.row.data.article.id }}" >{{ props.row.data.article.code }}</router-link>
                            <span v-if="!utils.checkObjectIsUndefinedOrEmpty(props.row.data.article) && !utils.checkIsUndefinedOrEmpty(props.row.data.article.description)">
                              <br/>
                              Descrizione: <router-link :to="{name: 'EditArticle', params: {id: props.row.data.article.id }}" >{{ props.row.data.article.description.it }}</router-link>
                            </span>

                            
                            <span v-if="!utils.checkIsUndefinedOrEmpty(props.row.data.component_id)">
                                <br/>
                                Tavola: 
                                <span v-if="utils.checkObjectIsUndefinedOrEmpty(props.row.data.component)">{{ props.row.data.component_id }}</span>
                                <span v-else-if="utils.checkObjectIsUndefinedOrEmpty(props.row.data.component.file)">{{ props.row.data.component.code }}</span>
                                <a :href="props.row.data.component.file.file" target="_blank" v-else>{{ props.row.data.component.code }}</a>
                            </span>
                            
                            <span v-if="!utils.checkIsUndefinedOrEmpty(props.row.data.article_image_id)">
                                <br/>
                                Immagine: 
                                <span v-if="utils.checkObjectIsUndefinedOrEmpty(props.row.data.article_image)">{{ props.row.data.article_image_id }}</span>
                                <a :href="props.row.data.article_image.image" target="_blank" v-else>{{ props.row.data.article_image.id }}</a>
                            </span>
                        </div>
                        
                        <!-- <ul>
                            <li v-for="(element, key) in props.row.data" :key="element">{{ key }}: {{ element }}</li>
                        </ul> -->
                    </div>
                    <div v-else>
                        <ul>
                            <li v-for="element in props.row.data" :key="element.title">{{ element.title }}</li>
                        </ul>
                    </div>
                </span>
        </template>
        <div slot="emptystate">
            Non sono presenti elementi
        </div>
    </vue-good-table>
</template>

<script>
import utils from '../../config/utils.js'
import constants from '../../config/constants.js'
import audit_keys from '../../config/audit_keys.js'
export default {
    props: ["columns", "rows", "recordsTotal", "selectable", "pagination"],
    data() {
        return {
            utils,
            constants,
            start: 0,
            length: 25
        }
    },
    methods: {
        getStringAction(key) {
            if(utils.checkIsUndefinedOrEmpty(audit_keys[key.toUpperCase()])){
                return key
            }
            return audit_keys[key.toUpperCase()]
        },
        async fetchData() {
            this.$emit("fetchData" , this.getString())
        },
        getString() {
            var string = `&start=${this.start}&length=${this.length}`
            for(var key in this.$refs.table.columnFilters){
                var index = this.columns.find(c => c.field === key).index
                if(utils.checkIsUndefinedOrEmpty(this.$refs.table.columnFilters[key])){
                    localStorage.removeItem(`${this.$route.name}/${index}/${constants.SEARCH_KEY}`)
                    continue
                }
                localStorage.setItem(`${this.$route.name}/${index}/${constants.SEARCH_KEY}`, this.$refs.table.columnFilters[key])
                string += `&columns[${index}][search][value]=${this.$refs.table.columnFilters[key]}`
            }
            if(this.$refs.table.sorts.length > 0){
                var indexS = this.columns.find(c => c.field === this.$refs.table.sorts[0].field).index
                // localStorage.setItem(`${this.$route.name}/${indexS}/${constants.SORT_KEY}`, this.$refs.table.sorts[0].type)
                string += `&order[0][column]=${indexS}&order[0][dir]=${this.$refs.table.sorts[0].type}`
            }
            return string
        },
        onPageChange(params) {
            if(params.currentPage > params.prevPage){
                this.start += params.currentPerPage
            } else {
                this.start -= params.currentPerPage
            }
            this.fetchData();
        },

        onPerPageChange(params) {
            this.start = 0
            this.length = params.currentPerPage
            this.fetchData();
        },

        onSortChange() {
            this.fetchData();
        },
        
        onColumnFilter() {
            this.fetchData();
        },
    },
    computed: {
        isTemplate() {
            return this.$route.name === 'CharacteristicTemplate'
        },
        isCurriculum() {
            return this.$route.name === 'Curriculum'
        }
    }
}
</script>