import Swal from "sweetalert2";

export default {
    withConfirm,
    alertLeave
}

function withConfirm(title, text, successText, callback){
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: "No"
    }).then((result) => {
        if (result.isConfirmed) {
            callback()
            Swal.fire({
                confirmButtonColor: '#3085d6',
                title: 'Confermato!',
                text: successText,
                icon: 'success'
            })
        }
    })
}
function alertLeave(next) {
    Swal.fire({
        title: 'Sei sicuro di abbandonare?',
        text: "Perderai tutte le modifiche effettuate",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
    }).then((result) => {
    if (result.value) {
        next()
    }
    })
}