import constants from "./constants"

export default {
    checkIsUndefined,
    checkIsUndefinedOrEmpty,
    checkObjectIsUndefinedOrEmpty,
    checkArrayIsUndefinedOrEmpty,
    checkAreEquals,
    sortArrayOfObjects,
    sortArrayOfObjectsWithDates,
    getEmptyObjectLang,
    getEmptyObjectLangArray,
    findObject,
    findIndexWithAttr,
    deleteElementArray,
    download,
    insertAt,
    newTemplate,
    newTemplateChildren,
    newComponent,
    getStringFilter,
    getJsonData,
    getSearcParameter,
    getLocalStorageFilter,
    newElementFilter,
    isValidHttpUrl,
    decodeHtml,
    downloadFile
}

function downloadFile(data) {
    if (checkObjectIsUndefinedOrEmpty(data)) {
        return
    }
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var blob = new Blob([data.data], {type: data.headers['content-type']})
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    const filename = data.headers['content-disposition'].split('filename=')[1]
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

function getSearcParameter(from = null, to = null) {
    return {
        from: from,
        to: to
    }
}

function getLocalStorageFilter(name) {
    var array = []
    for(var key in localStorage) {
        if(constants.KEYS_STORAGE.includes(key)){
            continue
        }
        var keyArray = key.split("/")
        if(keyArray[0] === name) {
            array.push(newElementFilter(keyArray[1], keyArray[2], localStorage[key]))
        }
    }
    return array
}

function newElementFilter(index, type, value) {
    return {
        index: index,
        type: type,
        value: value
    }
}

function getJsonData(data) {
    var old = {}
    Object.assign(old, data)
    delete old.oldData
    return JSON.stringify(old)
}

function getStringFilter(columns) {
    var string = ""
    var arrayData = []
    columns.forEach(col => {
        if(col.field === "cmd") {
            return
        }
        arrayData.push(col.field)
    })
    arrayData.forEach((element, index) => {
        string += `&columns[${index}][data]=${element}&columns[${index}][name]=&columns[${index}][searchable]=true&columns[${index}][orderable]=true&columns[${index}][search][regex]=false`
    });
    return string
}

function newComponent(length, id) {
    var component = {
        data: {},
        on_request: false,
        published: true,
        price: "0.00",
        article: id,
        code: "",
        search_parameter: {},
        file: null
    }
    for(var i = 0; i < length; i++){
        component.data[i] = ""
    }
    return component
}

function newTemplate(id = "") {
    return {
        id: id,
        cols: 1,
        rows: 1,
        title: "",
        type: "",
        children: []
    }
}

function newTemplateChildren(id = "") {
    return {
        id: id,
        cols: 1,
        rows: 1,
        title: "",
        type: "",
        columnIndex: 0
    }
}

function checkIsUndefined(obj) {
    return (obj === null || obj === undefined)
}

function checkIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || obj === "" || obj === " ")
}

function checkObjectIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || Object.keys(obj).length === 0)
}

function checkArrayIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || obj.length === 0)
}

function checkAreEquals(obj1, obj2){
    return (!checkIsUndefined(obj1) && obj1 === obj2)
}

function sortArrayOfObjects(array, attribute1, desc = true, attribute2 = undefined){
    var toReturn = array
    if(desc === true){
        toReturn.sort((a,b) => {
            var element1 = a[attribute1]
            var element2 = b[attribute1]
            if(checkIsUndefinedOrEmpty(attribute2)){
                return (element2 > element1) ? 1 : ((element1 > element2) ? -1 : 0)
            }
            return (element2[attribute2] > element1[attribute2]) ? 1 : ((element1[attribute2] > element2[attribute2]) ? -1 : 0)
        });
    } else {
        toReturn.sort((a,b) => {
            var element1 = a[attribute1]
            var element2 = b[attribute1]
            if(checkIsUndefinedOrEmpty(attribute2)){
                return (element1 > element2) ? 1 : ((element2 > element1) ? -1 : 0)
            }
            return (element1[attribute2] > element2[attribute2]) ? 1 : ((element2[attribute2] > element1[attribute2]) ? -1 : 0)
        });
    }
    return toReturn
}

function sortArrayOfObjectsWithDates(array, attribute1, desc = true, attribute2 = undefined){
    var toReturn = array
    if(desc === true){
        toReturn.sort((a,b) => {
            var element1 = a[attribute1]
            var element2 = b[attribute1]
            if(checkAreEquals(new Date(element1).getTime(), new Date(element2).getTime()) && !checkIsUndefinedOrEmpty(attribute2)){
                return (element2[attribute2] > element1[attribute2]) ? 1 : ((element1[attribute2] > element2[attribute2]) ? -1 : 0)
            }
            return (new Date(element2).getTime() > new Date(element1).getTime()) ? 1 : (new Date(element1).getTime() > new Date(element2).getTime()) ? -1 : 0
        });
    } else {
        toReturn.sort((a,b) => {
            var element1 = a[attribute1]
            var element2 = b[attribute1]
            if(checkAreEquals(new Date(element1).getTime(), new Date(element2).getTime()) && !checkIsUndefinedOrEmpty(attribute2)){
                return (element1[attribute2] > element2[attribute2]) ? 1 : ((element2[attribute2] > element1[attribute2]) ? -1 : 0)
            }
            return (new Date(element1).getTime() > new Date(element2).getTime()) ? 1 : (new Date(element2).getTime() > new Date(element1).getTime()) ? -1 : 0
        });
    }
    return toReturn
}

function getEmptyObjectLang(){
    return {
        it: "",
        en: "",
        fr: "",
        de: "",
        es: ""
    }
}

function getEmptyObjectLangArray(){
    return {
        it: [],
        en: [],
        fr: [],
        de: [],
        es: []
    }
}

function findObject(array, value, key){
    return array.find(el => el[key] === value)
}

function findIndexWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function deleteElementArray(array, index){
    array.splice(index, 1)
}

function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
}

function download(link){
    window.open(link, "_blank")
}