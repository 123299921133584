import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchTemplates(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/catalog/characteristic_template/?format=datatables' + extra)
    },
    getTemplate(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/characteristic_template/${id}/`)
    },
    duplicateTemplate(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/characteristic_template/${id}/duplicate/`)
    },
    changeTemplate(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/catalog/characteristic_template/${id}/`, params)
    },
    newTemplate(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/characteristic_template/`, params)
    },
    deleteTemplate(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/characteristic_template/${id}/`)
    }
}
