<template>
  <div>
    <panel title="Modifica Articolo" :back="true" @backFn="backRoute">
      <template v-slot:buttons>
        <a class="btn btn-info" v-if="id" @click="generate"><i class="fa fa-file-pdf"></i> Rigenera PDF Articolo</a>
        <a class="btn btn-primary" @click="changePublished"><i
            :class="`fa fa-${published ? 'eye' : 'eye-slash'}`"></i> Pubblicato:
          {{ published ? "SI" : "NO" }}</a>
        <a class="btn btn-secondary" v-if="id" @click="isEditArticle = !isEditArticle"><i class="fa fa-pen"></i>
          {{ isEditArticle ? "Fine Modifica" : "Modifica Dati" }}</a>
        <a class="btn btn-danger" v-if="id" @click="deleteArticle"><i class="fa fa-trash"></i> Elimina</a>
        <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
      </template>
      <div class="row mb-3">
        <div class="col-md-5 col-lg-4 col-xl-4">
          <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Attuale</label>
          <img :src="coverImage" class="pointer mt-2" @click="utils.download(coverImage)" width="100%">
        </div>
        <div class="col-md-7 col-lg-8 col-xl-8 d-flex flex-column">
          <div class="form-group">
            <label>Ordinamento Articolo</label>
            <input type="text" :disabled="!isEditArticle" v-model="order" class="form-control mt-2"
                   placeholder="Ordinamento Articolo"/>
          </div>
          <div class="mt-auto">
            <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Copertina</label>
            <file-pond :disabled="!isEditArticle" class="mt-2" name="test" ref="coverImage"
                       :maxFileSize="constants.MAX_FILE_SIZE"
                       labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                       label-idle="Clicca qui o trascina il file" v-bind:allow-multiple="false"
                       :accepted-file-types="constants.ACCEPTED_FILE_TYPES" v-on:updatefiles="updateCoverImage"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 form-group">
          <label>N° Articolo</label>
          <input type="text" :disabled="!isEditArticle" v-model="code" class="form-control"
                 placeholder="N° Articolo"/>
        </div>
        <div class="col-md-3 form-group">
          <label>Categoria</label>
          <select type="text" :disabled="!isEditArticle" v-model="category" class="form-select"
                  placeholder="Categoria">
            <option v-for="t in categories" :key="t.id" :value="t.id">
              {{ t.title.it }}
            </option>
          </select>
        </div>
        <div class="col-md-3 form-group">
          <label>Materiale</label>
          <select type="text" :disabled="!isEditArticle" v-model="material" class="form-select"
                  placeholder="Materiale">
            <option v-for="t in materials" :key="t.id" :value="t.id">{{ t.title.it }}</option>
          </select>
        </div>
        <div class="col-md-3 form-group">
          <label>Orientamento Immagini</label>
          <select type="text" :disabled="!isEditArticle" v-model="galleryOrientation" class="form-select"
                  placeholder="Materiale">
            <option v-for="t in constants.ORIENTATION_INPUT" :key="t.value" :value="t.value">
              {{ t.title }}
            </option>
          </select>
        </div>
      </div>
      <lang-tabs ref="langTab" class="mb-4" :availableLangs="availableLangs">
        <template v-slot:content="props">
          <div class="form-group mb-4">
            <label>Descrizione</label>
            <input type="text" :disabled="!isEditArticle" v-model="description[props.selectedLang]" class="form-control"
                   placeholder="Descrizione"/>
          </div>
          <div class="form-group mb-4">
            <label>Note <strong>(Separa le varie note da un #)</strong></label>
            <textarea rows="1" type="text" :disabled="!isEditArticle" v-model="notes[props.selectedLang]"
                      class="form-control"
                      placeholder="Note"/>
          </div>
        </template>
      </lang-tabs>
      <div class="card p-md-3 mb-3">
        <h5 class="mb-3">Immagini</h5>
        <div class="row">
          <div class="col-md-6">
            <label class="form-check-label" for="flexSwitchCheckDefault">Disegno</label>
            <file-pond :disabled="!isEditArticle" class="mt-3" name="test" :maxFileSize="constants.MAX_FILE_SIZE"
                       :maxFiles="constants.MAX_FILES"
                       labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                       label-idle="Clicca qui o trascina i file" v-bind:allow-multiple="true"
                       :accepted-file-types="constants.ACCEPTED_FILE_TYPES" ref="technicals" :files="technicals"
                       v-on:addfile="(error, file) => updateImages(true)"
                       v-on:removefile="(error, file) => updateImages(true)"/>
            <draggable
                :list="technicalsSaved"
                tag="ul"
                class="list-group"
                handle=".handle"
                ghost-class="ghost"
                drag-class="ghost"
                :options="{ forceFallback: true }"
            >
              <li class="list-group-item d-flex justify-content-between align-items-center wrap-anywhere"
                  v-for="(att, index) in technicalsSaved" :key="index">
                <img width="50%" class="max-height-200" :src="att.image">
                <span class="badge badge-primary badge-pill">
                                    <button class="btn btn-info mr-2 mreye handle cursor-grab"
                                            :disabled="!isEditArticle"
                                            @click.prevent><i class="fa fa-arrows"></i></button>
                                    <button class="btn btn-primary mr-2 mreye" :disabled="!isEditArticle"
                                            @click="utils.download(att.image)"><i class="fa fa-eye"></i></button>
                                    <button class="btn btn-danger" :disabled="!isEditArticle"
                                            @click="deleteImage(att, index, true)"><i class="fa fa-trash"></i></button>
                                </span>
              </li>
            </draggable>
          </div>
          <div class="col-md-6">
            <label class="form-check-label" for="flexSwitchCheckDefault">Foto</label>
            <file-pond :disabled="!isEditArticle" class="mt-3" name="test" :maxFileSize="constants.MAX_FILE_SIZE"
                       labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                       label-idle="Clicca qui o trascina i file" v-bind:allow-multiple="true"
                       :accepted-file-types="constants.ACCEPTED_FILE_TYPES" ref="photos" :files="photos"
                       :maxFiles="constants.MAX_FILES"
                       v-on:addfile="(error, file) => updateImages(false)"
                       v-on:removefile="(error, file) => updateImages(false)"/>
            <draggable
                :list="photosSaved"
                tag="ul"
                class="list-group"
                handle=".handle"
                ghost-class="ghost"
                drag-class="ghost"
                :options="{ forceFallback: true }"
            >
              <!-- <ul class="list-group"> -->
              <li class="list-group-item d-flex justify-content-between align-items-center wrap-anywhere"
                  v-for="(att, index) in photosSaved" :key="index">
                <img width="50%" class="max-height-200" :src="att.image">
                <span class="badge badge-primary badge-pill">
                                    <button class="btn btn-info mr-2 mreye handle cursor-grab"
                                            :disabled="!isEditArticle"
                                            @click.prevent><i class="fa fa-arrows"></i></button>
                                    <button class="btn btn-primary mr-2 mreye" :disabled="!isEditArticle"
                                            @click="utils.download(att.image)"><i class="fa fa-eye"></i></button>
                                    <button class="btn btn-danger" :disabled="!isEditArticle"
                                            @click="deleteImage(att, index, false)"><i class="fa fa-trash"></i></button>
                                </span>
              </li>
              <!-- </ul> -->
            </draggable>
          </div>
        </div>
      </div>
      <!-- <div class="card p-md-3" v-show="visibleTemplate && isEditArticle">
          <h5 class="mb-3">Componenti</h5>
          <template-article :receivedData="characteristic" ref="templateArticle" />
      </div>
      <datatable-template v-show="!isEditArticle && id" ref="datatableTemplate" :header="characteristic"
          :columns="characteristic" :id="id" /> -->
      <div class="mt-3">
        <a class="btn btn-warning mr-3" @click="backRoute"><i class="fa fa-arrow-left"></i> Annulla</a>
        <a class="btn btn-danger mr-3" @click="deleteArticle"><i class="fa fa-trash"></i> Elimina</a>
        <a class="btn btn-success mr-3 ml-3" @click="save"><i class="fa fa-save"></i> Salva</a>
      </div>
    </panel>
    <panel title="Componenti">
      <template v-slot:buttons>
        <a class="btn btn-warning" @click="exportExcel"><i class="fa fa-file-csv"></i> Excel</a>
        <a class="btn btn-secondary" v-if="id" @click="isEditArticle = !isEditArticle"><i class="fa fa-pen"></i>
          {{ isEditArticle ? "Fine Modifica" : "Modifica Dati" }}</a>
        <a class="btn btn-success" v-if="id" @click="changeTable"><i class="fa fa-pen"></i>
          Modifica {{ isEditTable ? "Componenti" : "Dati" }}</a>
      </template>
      <div v-if="!isEditTable" class="row mb-3">
        <div class="col-md-2 form-group vertically-align">
          <label>Caratteristiche</label>
        </div>
        <div class="col-md-6 form-group">
          <select type="text" class="form-select" v-model="selectedTemplate" placeholder="Caratteristiche">
            <option v-for="t in templates" :key="t.value" :value="t.data">{{ t.title }}</option>
          </select>
        </div>
        <div class="col-md-2 form-group">
          <button class="btn btn-primary" :disabled="utils.checkArrayIsUndefinedOrEmpty(selectedTemplate)"
                  @click="editTemplate"><i class="fa fa-upload"></i> Importa
          </button>
        </div>
        <div class="col-md-2 form-group" @click="showTemplate">
          <a class="btn btn-success"><i class="fa fa-plus"></i> Nuovo</a>
        </div>
      </div>
      <div class="card p-md-3" v-show="!isEditTable">
        <h5 class="mb-3">Componenti</h5>
        <template-article :receivedData="characteristic" ref="templateArticle" @update="updateCharacteristic"/>
      </div>
      <datatable-template v-show="isEditTable && id" ref="datatableTemplate" :header="characteristic"
                          :columns="characteristic" :id="id" :isEdit="isEditArticle"/>
      <div class="mt-3">
        <a class="btn btn-warning mr-3" @click="backRoute"><i class="fa fa-arrow-left"></i> Annulla</a>
        <a class="btn btn-danger mr-3" @click="deleteArticle"><i class="fa fa-trash"></i> Elimina</a>
        <a class="btn btn-success mr-3 ml-3" @click="save"><i class="fa fa-save"></i> Salva</a>
      </div>
    </panel>
    <panel v-if="isReserved" title="Utenti">
      <datatable-user ref="users" :users="users" :selectable="true"/>
      <div class="mt-3">
        <a class="btn btn-warning mr-3" @click="backRoute"><i class="fa fa-arrow-left"></i> Annulla</a>
        <a class="btn btn-danger mr-3" @click="deleteArticle"><i class="fa fa-trash"></i> Elimina</a>
        <a class="btn btn-success mr-3 ml-3" @click="save"><i class="fa fa-save"></i> Salva</a>
      </div>
    </panel>
  </div>
</template>

<script>
var xlsx = require("xlsx");
import ArticleService from '../api/ArticleService'
import CategoryService from '../api/CategoryService'
import MaterialService from '../api/MaterialService'
import swal from '../config/swal.js'
import utils from '../config/utils.js'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import CharacteristicTemplateService from '../api/CharacteristicTemplateService'
import {
  mapMutations
} from 'vuex'
import notification from '../config/notification'

export default {
  data() {
    return {
      isSaved: false,
      isEditArticle: true,
      isEditTable: false,
      constants,
      utils,
      id: "",
      published: true,
      reserved: false,
      categories: [],
      category: null,
      materials: [],
      galleryOrientation: "V",
      code: "",
      order: 0,
      templates: [],
      visibleTemplate: false,
      material: null,
      coverImage: null,
      newCoverImage: null,
      description: utils.getEmptyObjectLang(),
      notes: utils.getEmptyObjectLang(),
      availableLangs: [],
      selectedTemplate: [],
      characteristic: [],
      index: 0,
      components: [],
      photos: [],
      technicals: [],
      photosSaved: [],
      users: [],
      technicalsSaved: [],
      zincato: null,
      thumbnail: "",
      oldData: "",
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true,
          index: 0
        },
        {
          label: 'Titolo',
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: "Titolo"
          },
          index: 1
        }
      ]
    }
  },
  async mounted() {
    loader.showLoader()
    await CategoryService.fetchCategories()
        .then(resp => {
          this.categories = resp.data.data
        })
        .catch(err => {
          console.log(err)
        })
    await MaterialService.fetchMaterials()
        .then(resp => {
          this.materials = resp.data.data
        })
        .catch(err => {
          console.log(err)
        })
    var string = utils.getStringFilter(this.columns)
    string = "&columns[0][data]=title&columns[0][search][value]=Zincato"
    await MaterialService.fetchMaterials(string)
        .then(resp => {
          this.zincato = resp.data.data[0]
        })
        .catch(err => {
          console.log(err)
        })
    await CharacteristicTemplateService.fetchTemplates()
        .then(resp => {
          this.templates = resp.data.data
        })
        .catch(err => {
          console.log(err)
        })
    if (this.isReserved) {
      this.reserved = true
    }
    if (this.isEdit) {
      this.isEditTable = true
      this.isEditArticle = false
      this.id = this.$route.params.id
      if (this.isReserved) {
        await ArticleService.getArticleReserved(this.id)
            .then(resp => {
              this.setData(resp)
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        await ArticleService.getArticleComponents(this.id)
            .then(resp => {
              this.setData(resp)
            })
            .catch(err => {
              console.log(err)
            })
      }
    }
    this.oldData = utils.getJsonData(this.$data)
    loader.hideLoader()
  },
  computed: {
    isEdit() {
      return this.$route.name.includes("Edit")
    },
    isReserved() {
      return this.$route.name.includes("Reserved")
    },
    selectedUsers() {
      if (this.isReserved) {
        return this.$refs.users.getSelected
      }
      return []
    }
  },
  // watch: {
  //     "this.$refs.templateArticle.data": {
  //         deep: true,
  //         handler: function() {
  //             console.log(this.$refs.templateArticle.data)
  //         }
  //     }
  // },
  methods: {
    updateCharacteristic(data) {
      if (utils.checkArrayIsUndefinedOrEmpty(data)) {
        return
      }
      this.characteristic = data
    },
    exportExcel() {
      // loader.showLoader()
      var header = []
      var header2 = []
      var secondRow = []
      this.characteristic.forEach(col => {
        header = header.concat(Array(col.cols).fill(col.title)).flat();
        // var listHeader = []
        col.children.forEach(child => {
          header2.push(child.title)
          secondRow.push(child)
        });
        // header2 = header2.concat(Array(listHeader).flat()).flat();
      });
      var wb = xlsx.utils.book_new();
      wb.SheetNames.push("Componenti");
      var ws_data = []
      ws_data.push(header)
      ws_data.push(header2)
      this.components.forEach(component => {
        var newRowExcel = []
        secondRow.forEach(row => {
          if (utils.checkIsUndefinedOrEmpty(component.data[row.id])) {
            newRowExcel.push("")
          } else {
            newRowExcel.push(utils.decodeHtml(component.data[row.id]))
          }
        });
        // newRowExcel.push(this.formatFn(row.timestamp))
        // newRowExcel.push(this.getString(row.action))
        // var stringData = ""
        // for(var key in row.data) {
        //     stringData += `${key}: ${row.data[key]}, `
        // }
        // newRowExcel.push(stringData)
        // if(utils.checkIsUndefinedOrEmpty(row.user)){
        //     newRowExcel.push(``)
        // } else {
        //     newRowExcel.push(`${row.user.username}`)
        // }
        // newRowExcel.push(row.remote_addr)
        ws_data.push(newRowExcel)
      });
      var ws = xlsx.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Componenti"] = ws;
      var lastCol = 0
      const merges = this.characteristic.reduce((acc, curr, idx) => {
        acc.push({
          s: {r: 0, c: lastCol},
          e: {r: 0, c: lastCol + curr.cols - 1}
        });
        if (idx === 1) {
          lastCol += curr.cols
        } else {
          lastCol += curr.cols
        }

        return acc;
      }, []);
      ws["!merges"] = merges;
      xlsx.writeFile(wb, `Componenti.xlsx`, {bookType: "xlsx"});
      loader.hideLoader()
    },
    changeTable() {
      if (!this.isEditTable) {
        this.characteristic = this.$refs.templateArticle.data
      }
      this.isEditTable = !this.isEditTable
    },
    async generate() {
      loader.showLoader()
      await ArticleService.generateArticle(this.id)
          .then(() => {
            notification.notifySuccess("Articolo generato con successo")
          })
          .catch(err => {
            console.log(err)
            notification.notifyError("Errore durante la generazione, riprova")
          })
      loader.hideLoader()
    },
    ...mapMutations([
      'setLoader'
    ]),
    setData(resp) {
      if (this.isReserved) {
        this.users = resp.data.users
      }
      this.thumbnail = resp.data.thumbnail
      this.coverImage = resp.data.cover_image
      this.description = resp.data.description
      this.published = resp.data.published
      if (!utils.checkObjectIsUndefinedOrEmpty(resp.data.notes)) {
        this.notes = resp.data.notes
      }
      if (!utils.checkIsUndefinedOrEmpty(resp.data.material)) {
        this.material = resp.data.material.id
      }
      if (!utils.checkIsUndefinedOrEmpty(resp.data.category)) {
        this.category = resp.data.category.id
      }
      this.galleryOrientation = resp.data.gallery_orientation
      this.code = resp.data.code
      this.order = resp.data.order
      this.characteristic = resp.data.characteristic
      // if (this.characteristic.length > 0) {
      this.visibleTemplate = true
      // }
      this.$refs.datatableTemplate.data = resp.data.components
      this.components = resp.data.components
      resp.data.images.forEach(image => {
        switch (image.type) {
          case constants.IMAGE_PHOTO:
            this.photosSaved.push(image)
            break
          case constants.IMAGE_TECHNICAL:
            this.technicalsSaved.push(image)
            break
          default:
            break
        }
      });
      if (!utils.checkIsUndefined(this.description)) {
        for (var key in this.title) {
          this.availableLangs.push(key)
        }
      }
    },
    async deleteImage(file, index, isTechnical) {
      swal.withConfirm(
          'Sei sicuro di eliminare l\'allegato?',
          `Confermando l'allegato verrà eliminato definitivamente!`,
          'Allegato eliminato!',
          async () => {
            loader.showLoader()
            await ArticleService.deleteImage(file.id)
                .then(() => {
                  if (isTechnical) {
                    this.technicalsSaved.splice(index, 1)
                    return
                  }
                  this.photosSaved.splice(index, 1)
                })
                .catch(err => {
                  console.log(err)
                })

            loader.hideLoader()
          })
    },
    updateImages(isTechnical) {
      if (isTechnical) {
        this.technicals = this.$refs[`technicals`].getFiles()
        return
      }
      this.photos = this.$refs[`photos`].getFiles()
    },
    showTemplate() {
      this.visibleTemplate = true
    },
    editTemplate() {
      this.$refs.templateArticle.data = this.selectedTemplate
      // this.characteristic = this.selectedTemplate
      this.showTemplate()
    },
    updateCoverImage(files) {
      this.newCoverImage = files[0]
    },
    changePublished() {
      swal.withConfirm(
          'Sei sicuro di voler cambiare la visibilità?',
          `Confermando ${this.published ? "non" : ""} sarà visibile!`,
          'Visibilità cambiata. Ricordati di salvare!',
          () => {
            this.published = !this.published
          })
    },
    backRoute() {
      if (this.isReserved) {
        this.$router.push({
          name: 'ReservedArticles'
        })
        return
      }
      this.$router.push({
        name: 'Articles'
      })
    },
    async save() {
      if (utils.checkIsUndefinedOrEmpty(this.code)) {
        notification.notifyWarning("Inserisci un n° articolo valido")
        return
      }
      if (utils.checkIsUndefinedOrEmpty(this.category)) {
        notification.notifyWarning("Inserisci una categoria valida")
        return
      }
      if (utils.checkIsUndefinedOrEmpty(this.order)) {
        notification.notifyWarning("Inserisci un numero di ordinamento valido")
        return
      }
      loader.showLoader()
      this.$refs.datatableTemplate.data.forEach((element, index) => {
        let parcom = new FormData()
        parcom.append("data", JSON.stringify(element.data))
        parcom.append("code", element.data["0"])
        parcom.append("published", element.published)
        parcom.append("article", element.article)
        parcom.append("weight", index)
        parcom.append("on_request", element.on_request)
        if (!utils.checkObjectIsUndefinedOrEmpty(element.file) && typeof element.file != "string") {
          parcom.append("file", element.file)
        }
        parcom.append("search_parameter", JSON.stringify(element.search_parameter))
        if (utils.checkIsUndefinedOrEmpty(element.id)) {
          ArticleService.newComponent(parcom)
              .then(() => {
                console.log("Componenti Salvati")
              })
              .catch(err => {
                console.log(err)
              })
        } else {
          ArticleService.changeComponent(element.id, parcom)
              .then(() => {
                console.log("Componenti Salvati")
              })
              .catch(err => {
                console.log(err)
              })
        }
      });
      let params = new FormData()
      params.append("reserved", this.reserved)
      params.append("published", this.published)
      params.append("code", this.code)
      params.append("order", this.order)
      params.append("category", this.category)
      if (utils.checkIsUndefinedOrEmpty(this.material && !utils.checkObjectIsUndefinedOrEmpty(this.zincato))) {
        this.material = this.zincato.id
      }
      params.append("material", this.material)
      params.append("gallery_orientation", this.galleryOrientation)
      params.append("characteristic", this.$refs.templateArticle.getJson())
      if (!utils.checkIsUndefined(this.$refs.langTab.langs)) {
        var description = {}
        var notes = {}
        this.$refs.langTab.langs.forEach(ls => {
          if (ls.visible) {
            var langValue = ls.value
            if (utils.checkIsUndefined(this.description[langValue])) {
              description[langValue] = ""
            } else {
              description[langValue] = this.description[langValue]
            }
            if (utils.checkIsUndefined(this.notes[langValue])) {
              notes[langValue] = ""
            } else {
              notes[langValue] = this.notes[langValue]
            }
          }
        })
        params.append('description', JSON.stringify(description))
        params.append('notes', JSON.stringify(notes))
      }
      if (!utils.checkIsUndefinedOrEmpty(this.newCoverImage) && typeof this.newCoverImage != "string") {
        params.append('cover_image', this.newCoverImage.file)
      }
      if (this.isEdit) {
        this.users.forEach(async user => {
          var row = utils.findObject(this.selectedUsers, user.user, "id")
          if (utils.checkIsUndefined(row)) {
            await ArticleService.deleteArticleReserved(user.id)
                .then(resp => console.log(resp))
                .catch(err => console.log(err))
          }
        });
        await ArticleService.changeArticle(this.id, params)
            .then(async () => {
              await this.saveImages(this.id)
              this.newUser(this.id)
              this.oldData = utils.getJsonData(this.$data)
              this.isSaved = true
              notification.notifySuccess("Articolo Salvato")
              this.$router.go(0)
            })
            .catch(err => {
              notification.notifyError("ERRORE")
              console.log(err)
            })
      } else {
        await ArticleService.newArticle(params)
            .then(async resp => {
              await this.saveImages(resp.data.id)
              this.newUser(resp.data.id)
              this.oldData = utils.getJsonData(this.$data)
              this.isSaved = true
              notification.notifySuccess("Articolo Salvato")
              if (this.isReserved) {
                this.$router.push({name: "EditReservedArticle", params: {id: resp.data.id}})
              } else {
                this.$router.push({name: "EditArticle", params: {id: resp.data.id}})
              }
            })
            .catch(err => {
              notification.notifyError("ERRORE")
              console.log(err)
            })
      }
      loader.hideLoader()
    },
    newUser(id) {
      loader.showLoader()
      this.selectedUsers.forEach(async (row) => {
        var user = utils.findObject(this.users, row.id, "user")
        if (utils.checkIsUndefined(user)) {
          var paramUser = {
            user: row.id,
            article: id
          }
          // var paramUser = new FormData()
          // paramUser.append("user", row.id)
          // paramUser.append("article", id)
          await ArticleService.newArticleReserved(paramUser)
              .then(resp => console.log(resp))
              .catch(err => console.log(err))
        }
      });
      loader.hideLoader()
    },
    async saveImages(id) {
      loader.showLoader()
      await Promise.all(this.photosSaved.map(async (photo, index) => {
        var parpho = new FormData()
        // parpho.append("image", photo.file)
        // parpho.append("article", id)
        // parpho.append("type", constants.IMAGE_PHOTO)
        parpho.append("weight", index)
        await ArticleService.changeImage(photo.id, parpho)
            .then(() => {
              console.log("Immagini Salvate")
            })
            .catch(err => {
              console.log(err)
            })
      }))
      await Promise.all(this.photos.map(async (photo, index) => {
            var parpho = new FormData()
            parpho.append("image", photo.file)
            parpho.append("article", id)
            parpho.append("type", constants.IMAGE_PHOTO)
            parpho.append("weight", this.photosSaved.length + index)
            await ArticleService.newImage(parpho)
                .then(() => {
                  console.log("Immagini Salvate")
                })
                .catch(err => {
                  console.log(err)
                })
          })
      )
      await Promise.all(this.technicalsSaved.map(async (technical, index) => {
            var partec = new FormData()
            // partec.append("image", technical.file)
            // partec.append("article", id)
            // partec.append("type", constants.IMAGE_TECHNICAL)
            partec.append("weight", index)
            await ArticleService.changeImage(technical.id, partec)
                .then(() => {
                  console.log("Immagini Salvate")
                })
                .catch(err => {
                  console.log(err)
                })
          })
      )
      await Promise.all(this.technicals.map(async (technical, index) => {
            var partec = new FormData()
            partec.append("image", technical.file)
            partec.append("article", id)
            partec.append("type", constants.IMAGE_TECHNICAL)
            partec.append("weight", this.technicalsSaved.length + index)
            await ArticleService.newImage(partec)
                .then(() => {
                  console.log("Immagini Salvate")
                })
                .catch(err => {
                  console.log(err)
                })
          })
      )
      loader.hideLoader()
    },
    async deleteArticle() {
      swal.withConfirm(
          'Sei sicuro di eliminare?',
          `Confermando verrà eliminato definitivamente!`,
          'Eliminato!',
          async () => {
            loader.showLoader()
            await ArticleService.deleteArticle(this.id)
                .then(() => {
                  console.log("Articolo Eliminato")
                  this.backRoute()
                })
                .catch(err => {
                  console.log(err)
                })
            loader.hideLoader()
          })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
      next()
      return
    }
    swal.alertLeave(next)
  }
}
</script>

<style>

</style>