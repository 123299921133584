<template>
    <panel title="Dettaglio Template" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a @click="duplicate" v-if="id" class="btn btn-warning"><i class='fa fa-copy mr-2 ' /> Duplica</a>
            <a class="btn btn-danger" v-if="id" @click="deleteTemplate"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="form-group mb-4">
            <label>Titolo</label>
            <input type="text" v-model="title" class="form-control" placeholder="Titolo">
        </div>
        <div class="form-group mb-4">
            <label>Dati</label>
            <template-article ref="templateArticle" :receivedData="data" />
        </div>
    </panel>
</template>

<script>
import CharacteristicTemplateService from '../api/CharacteristicTemplateService'
// import utils from '../config/utils'
import constants from '../config/constants'
import loader from '../config/loader'
import notification from '../config/notification'
import swal from '../config/swal'
import utils from '../config/utils'
export default {
    data() {
        return {
            constants,
            id: "",
            title: "",
            index: 0,
            data: [],
            isSaved: false,
            oldData: ""
        }
    },
    async mounted() {
        loader.showLoader()
        if (this.isEdit) {
            this.id = this.$route.params.id
            await CharacteristicTemplateService.getTemplate(this.id)
                .then(resp => {
                    this.title = resp.data.title
                    this.data = resp.data.data
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditTemplate"
        }
    },
    methods: {
        async duplicate() {
            await CharacteristicTemplateService.duplicateTemplate(this.id)
                .then(resp => {
                    this.$router.push({name: "EditTemplate", params: {id: resp.data.id}})
                    notification.notifySuccess("Duplicazione effettuata")
                })
                .catch(err => {
                    console.log(err)
                    notification.notifyError("Errore durante la duplicazione")
                })
        },
        backRoute() {
            this.$router.push({
                name: 'CharacteristicTemplate'
            })
        },
        async save() {
            loader.showLoader()
            let params = new FormData()
            params.append("title", this.title)
            params.append("data", this.$refs.templateArticle.getJson())
            if (this.isEdit) {
                await CharacteristicTemplateService.changeTemplate(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await CharacteristicTemplateService.newTemplate(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteTemplate() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await CharacteristicTemplateService.deleteTemplate(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }
}
</script>