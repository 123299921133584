<template>
    <panel title="Dettaglio Traduzione" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-danger" v-if="id" @click="deleteTranslation"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="form-group mb-3">
            <label>Chiave</label>
            <input type="text" v-model="key" class="form-control" placeholder="Chiave">
        </div>
        <div class="form-check form-switch mb-3">
            <label class="form-check-label" for="flexSwitchCheckDefault">HTML</label>
            <input v-model="html" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        </div>
        <div v-for="lang in langs" :key="lang.value">
            <h5><span :class="`flag-icon flag-icon-${lang.icon}`"></span> {{ lang.title }}</h5>
            <div class="form-group mb-3">
                <!-- <label>Titolo</label> -->
                <textarea v-if="html" v-model="value[lang.value]" class="form-control" placeholder="Testo Html" rows="10" />
                <Editor v-else v-model="value[lang.value]"
                        tinymce-script-src="/tinymce/tinymce.min.js"
                        :init="{
                            plugins: 'lists link image table code help wordcount'
                        }" />
            </div>
        </div>
        <!-- <lang-tabs>
            <template v-slot:content="props">
                <div class="form-group mb-3">
                    <label>Titolo</label>
                    <input type="text" v-model="title[props.selectedLang]" class="form-control" placeholder="Titolo">
                </div>
            </template>
        </lang-tabs> -->
    </panel>
</template>
<script>
import TranslationService from '../api/TranslationService.js'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import notification from '../config/notification.js'
import swal from '../config/swal.js'
import utils from '../config/utils.js'
import Editor from '@tinymce/tinymce-vue'
export default {
    components: {
        Editor
    },
    data() {
        return {
            id: "",
            html: false,
            value: utils.getEmptyObjectLang(),
            utils: utils,
            isSaved: false,
            oldData: "",
            langs: constants.LANGS_TABS,
            key: "",
            translations: []
        }
    },
    async mounted() {
        loader.showLoader()
        var string = `&start=0&length=9999`
        await TranslationService.fetchTranslations(string)
        .then(resp => {
            this.translations = resp.data.data
        })
        .catch(err => {
            console.log(err)
        })
        if(this.isEdit) {
            this.id = this.$route.params.id
            await TranslationService.getTranslation(this.id)
                .then(resp => {
                    if(!utils.checkObjectIsUndefinedOrEmpty(resp.data.value)) {
                        this.value = resp.data.value
                    }
                    this.key = resp.data.key
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditTranslation"
        }
    },
    methods: {
        backRoute(){
            this.$router.push({name: 'Translations'})
        },
        async save(){

            loader.showLoader()
            let params = new FormData()
                params.append("key", this.key)
                params.append("value", JSON.stringify(this.value))
            if(this.isEdit) {
                await TranslationService.changeTranslation(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                var object = this.translations.find(e => e.key === this.key)
                if(!utils.checkObjectIsUndefinedOrEmpty(object)){
                    notification.notifyError("È gia presente una traduzione con questa chiave")
                    loader.hideLoader()
                    return
                }
                await TranslationService.newTranslation(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteTranslation() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await TranslationService.deleteTranslation(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }


}
</script>