<template>
    <panel title="Curriculum">
        <template v-slot:buttons>
            <a class="btn btn-warning" @click="exportExcel"><i class="fa fa-file-csv"></i> Excel</a>
            <!-- <a class="btn btn-success" @click="newCurriculum"><i class="fa fa-plus"></i> Nuovo Curriculum</a> -->
        </template>
        <datatable :columns="columns" :rows="rows" @edit="edit" @fetchData="fetchData" :recordsTotal="recordsTotal" />
    </panel>
</template>

<script>
import CurriculumService from '../api/CurriculumService.js'
import constants from '../config/constants.js';
import loader from '../config/loader.js';
import utils from '../config/utils.js';
var xlsx = require("xlsx");
import curriculum from '../config/curriculum.js'
import curriculum_keys from '../config/curriculum_keys'
export default {
    data() {
        return {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'Numero',
                    field: 'sequential_number',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Numero"
                    },
                    index: 1
                },
                {
                    label: 'Nome',
                    field: 'first_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Nome"
                    },
                    index: 2
                },
                {
                    label: 'Cognome',
                    field: 'last_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Cognome"
                    },
                    index: 3
                },
                {
                    label: 'Data di compilazione',
                    field: 'submission_date',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'dd/MM/yyyy',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Data di compilazione"
                    },
                    index: 4
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false
                }
            ],
            rows: [],
            recordsTotal: 0,
        };
    },
    async mounted() {
        if(utils.checkArrayIsUndefinedOrEmpty(utils.getLocalStorageFilter(this.$route.name))){
            this.fetchData()
        } else {
            utils.getLocalStorageFilter(this.$route.name).forEach(element => {
                if(element.type === constants.SEARCH_KEY) {
                    this.$set(this.columns[element.index].filterOptions, "filterValue", element.value)
                }
            });
        }
    },
    methods: {
        async exportExcel() {
            loader.showLoader()
            var header = []
            var headerKeys = []
            for(var i in curriculum) {
                if(i === 'FILE') {
                    continue
                }
                curriculum[i].forEach(element => {
                    if(['competences_it_other_name', 'competences_it_other'].includes(element)) {
                        return
                    }
                    headerKeys.push(element)
                    if(utils.checkIsUndefinedOrEmpty(curriculum_keys[element])) {
                        header.push(element)
                        return
                    }
                    header.push(curriculum_keys[element])
                });
            }
            // this.columns.forEach(col => {
            //     if(col.hidden){
            //         return
            //     }
            //     header.push(col.label)
            // });
            var wb = xlsx.utils.book_new();
            wb.SheetNames.push("Curriculum");
            var ws_data = []
            ws_data.push(header)
            var excelRows = []
            await CurriculumService.fetchCurriculum(`&start=0&length=9999999`)
                .then(response => {
                    excelRows = response.data.data
                })
                .catch(err => {
                    console.log(err)
                })
            excelRows.forEach(row => {
                var newRowExcel = []
                // console.log(row)
                headerKeys.forEach(key => {
                    const value = row[key]
                    if(typeof value === 'boolean'){
                        newRowExcel.push(value ? 'Si' : 'No')
                        return
                    }
                    if(key.includes('competences')) {
                        const comp = utils.findObject(curriculum_keys.competences_value, value, 'id')
                        if(utils.checkObjectIsUndefinedOrEmpty(comp)) {
                            newRowExcel.push('')
                            return
                        }
                        newRowExcel.push(comp.value)
                        return
                    }
                    if(key.includes('travel')) {
                        const travel = utils.findObject(curriculum_keys.travel_value, value, 'id')
                        if(utils.checkObjectIsUndefinedOrEmpty(travel)) {
                            newRowExcel.push('')
                            return
                        }
                        newRowExcel.push(travel.value)
                        return
                    }
                    if(key === 'contract_month_retribution_type') {
                        const contract = utils.findObject(curriculum_keys.contract_value, value, 'id')
                        if(utils.checkObjectIsUndefinedOrEmpty(contract)) {
                            newRowExcel.push('')
                            return
                        }
                        newRowExcel.push(contract.value)
                        return
                    }
                    if(key === "experience") {
                        var exp = ""
                        value.forEach(experience => {
                            exp += `Azienda: ${experience.company} dal ${experience.from.split('T')[0]} al ${experience.to.split('T')[0]} task: ${experience.task};`
                        });
                        newRowExcel.push(exp)
                        return
                    }
                    newRowExcel.push(value)
                });
                // newRowExcel.push(this.formatFn(row.timestamp))
                // newRowExcel.push(this.getString(row.action))
                // var stringData = ""
                // for(var key in row.data) {
                //     stringData += `${key}: ${row.data[key]}, `
                // }
                // newRowExcel.push(stringData)
                // if(utils.checkIsUndefinedOrEmpty(row.user)){
                //     newRowExcel.push(``)
                // } else {
                //     newRowExcel.push(`${row.user.username}`)
                // }
                // newRowExcel.push(row.remote_addr)
                ws_data.push(newRowExcel)
            });
            var ws = xlsx.utils.aoa_to_sheet(ws_data);
            wb.Sheets["Curriculum"] = ws;
            xlsx.writeFile(wb, `Curriculum.xlsx`, { bookType: "xlsx" });
            loader.hideLoader()
        },
        async fetchData(stringReceived) {
            loader.showLoader()
            var string = utils.getStringFilter(this.columns)
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
            } else {
                string += stringReceived
            }
            await CurriculumService.fetchCurriculum(string)
            .then(resp => {
                console.log(resp)
                this.rows = resp.data.data
                this.recordsTotal = resp.data.recordsFiltered
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
        },
        newCurriculum() {
            this.$router.push({name: "NewCurriculum"})
        },
        edit(id) {
            this.$router.push({name: "EditCurriculum", params: {id: id}})
        }
    }
}
</script>