<template>
    <panel :title="isReserved ? 'Categorie Riservate' : 'Categorie'">
        <template v-slot:buttons>
            <a class="btn btn-success" @click="newCategory"><i class="fa fa-plus"></i> Nuova Categoria</a>
        </template>
        <datatable :columns="columns" :rows="rows" @edit="edit" @fetchData="fetchData" :recordsTotal="recordsTotal" />
    </panel>
</template>

<script>
import CategoryService from '../api/CategoryService.js'
import constants from '../config/constants.js';
import loader from '../config/loader.js';
import utils from '../config/utils.js';
export default {
    data() {
        return {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'Titolo',
                    field: 'title',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Titolo"
                    },
                    index: 1
                },
                {
                    label: 'Sottotitolo',
                    field: 'summary',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Sottotitolo"
                    },
                    index: 2
                },
                {
                    label: 'Ordine',
                    field: 'weight',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Ordine"
                    },
                    index: 3
                },
                {
                    label: 'Riservato',
                    field: 'reserved',
                    hidden: true,
                    index: 4
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false
                }
            ],
            rows: [],
            recordsTotal: 0,
        };
    },
    computed: {
        isReserved() {
            return this.$route.name === "ReservedCategories"
        }
    },
    async mounted() {
        if(utils.checkArrayIsUndefinedOrEmpty(utils.getLocalStorageFilter(this.$route.name))){
            this.fetchData()
        } else {
            utils.getLocalStorageFilter(this.$route.name).forEach(element => {
                if(element.type === constants.SEARCH_KEY) {
                    this.$set(this.columns[element.index].filterOptions, "filterValue", element.value)
                }
            });
        }
    },
    methods: {
        async fetchData(stringReceived) {
            loader.showLoader()
            var string = utils.getStringFilter(this.columns)
            if(this.isReserved ) {
                string += `&columns[4][search][value]=${constants.FLAG_FILTER_TRUE}`
            } else {
                string += `&columns[4][search][value]=${constants.FLAG_FILTER_FALSE}`
            }
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
            } else {
                string += stringReceived
            }
            await CategoryService.fetchCategories(string)
                .then(resp => {
                    console.log(resp)
                    this.rows = resp.data.data
                this.recordsTotal = resp.data.recordsFiltered
                })
                .catch(err => {
                    console.log(err)
                })
            loader.hideLoader()
        },
        newCategory() {
            if(this.isReserved) {
                this.$router.push({name: "NewReservedCategory"})
                return
            }
            this.$router.push({name: "NewCategory"})
        },
        edit(id) {
            if(this.isReserved) {
                this.$router.push({name: "EditReservedCategory", params: {id: id}})
                return
            }
            this.$router.push({name: "EditCategory", params: {id: id}})
        }
    }
}
</script>