import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchPriceLists(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/price_list/?format=datatables' + extra)
    },
    getPriceList(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/price_list/${id}/`)
    },
    changePriceList(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/price_list/${id}/`, params)
    },
    newPriceList(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/price_list/`, params)
    },
    deletePriceList(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/price_list/${id}/`)
    }
}
