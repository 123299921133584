<template>
	<div>

		<!-- BEGIN panel -->
		<panel title="Home">
			<!-- <div class="container"> -->
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<h1>Catalogo</h1>
							<div class="col-md-6 pointer" @click="clickCard(key)" v-for="(element, key) in catalogData" :key="element + key">
								<div class="card-counter primary">
									<img height="100%" :src="icons[key]" alt=""/>
									<span class="count-numbers">{{ element }}</span>
									<span class="count-name">{{ dashboard[key] }}</span>
								</div>
							</div>
						</div>
						<div class="row">
							<h1>Novità</h1>
							<div class="col-md-6 pointer" @click="clickCard(key)" v-for="(element, key) in newsData" :key="element + key">
								<div class="card-counter success">
									<img height="100%" :src="icons[key]" alt=""/>
									<span class="count-numbers">{{ element }}</span>
									<span class="count-name">{{ dashboard[key] }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<h1>Download</h1>
							<div class="col-md-6 pointer" @click="clickCard(key)" v-for="(element, key) in downloadData" :key="element + key">
								<div class="card-counter danger">
									<img height="100%" :src="icons[key]" alt=""/>
									<span class="count-numbers">{{ element }}</span>
									<span class="count-name">{{ dashboard[key] }}</span>
								</div>
							</div>
						</div>
						<div class="row">
							<h1>Utenti</h1>
							<div class="col-md-6 pointer" @click="clickCard(key)" v-for="(element, key) in usersData" :key="element + key">
								<div class="card-counter info">
									<img height="100%" :src="icons[key]" alt=""/>
									<!-- <i class="fa fa-users"></i> -->
									<span class="count-numbers">{{ element }}</span>
									<span class="count-name">{{ dashboard[key] }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			<!-- </div> -->
			
		</panel>
		<!-- END panel -->
	</div>
</template>

<script>
import DashboardService from '../api/DashboardService.js'
import loader from '../config/loader.js'
import dashboard from '../config/dashboard.js'
import icons from '../config/icons.js'
export default {
	data() {
		return {
			dashboard,
			icons,
			catalogData: {},
			downloadData: {},
			newsData: {},
			usersData: {}
		}
	},
	async mounted() {
		loader.showLoader()
		await DashboardService.getDashbordCatalog()
			.then(resp => {
				for(var key in resp.data) {
					switch (key) {
						// case "materials":
						// 	this.catalogData[key] = resp.data[key]
						// 	break;
						case "categories":
							this.catalogData[key] = resp.data[key]
							break;
						case "articles_public":
							this.catalogData[key] = resp.data[key]
							break;
						default:
							break;
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
		await DashboardService.getDashbordDownload()
			.then(resp => {
				for(var key in resp.data) {
					switch (key) {
						case "downloads":
							this.downloadData[key] = resp.data[key]
							break;
						case "downloads_reserved":
							this.downloadData[key] = resp.data[key]
							break;
						default:
							break;
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
		await DashboardService.getDashbordNews()
			.then(resp => {
				for(var key in resp.data) {
					switch (key) {
						case "news_public":
							this.newsData[key] = resp.data[key]
							break;
						default:
							break;
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
		await DashboardService.getDashbordUsers()
			.then(resp => {
				for(var key in resp.data) {
					switch (key) {
						case "users_active":
							this.usersData[key] = resp.data[key]
							break;
						case "users":
							this.usersData[key] = resp.data[key]
							break;
						default:
							break;
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
		this.$forceUpdate()
		loader.hideLoader()
	}, 
	methods: {
		clickCard(key) {
			switch (key) {
				case "categories":
					this.$router.push({name: "Categories"})
					break;
				case "articles_public":
					this.$router.push({name: "Articles"})
					break;
				case "downloads":
					this.$router.push({name: "Downloads"})
					break;
				case "downloads_reserved":
					this.$router.push({name: "ReservedDownloads"})
					break;
				case "news_public":
					this.$router.push({name: "News"})
					break;
				default:
					this.$router.push({name: "Users"})
					break;
			}
		}
	}
}
</script>