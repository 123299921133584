import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchDownloads(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/download/?format=datatables' + extra)
    },
    getDownload(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/download/${id}/`)
    },
    changeDownload(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/download/${id}/`, params)
    },
    newDownload(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/download/`, params)
    },
    deleteDownload(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/download/${id}/`)
    },
    getDownloadReserved(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/download_reserved/${id}/`)
    },
    newDownloadReserved(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/reserved_download/`, params)
    },
    deleteDownloadReserved(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/reserved_download/${id}/`)
    },
}
