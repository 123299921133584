import Vue from 'vue'

export default {
    notifyError,
    notifyWarning,
    notifySuccess
}

function notify(type, title, text) {
    Vue.notify({
        group: 'alert',
        type: type,
        title: title,
        text: text
      })
}

function notifyError(text) {
    notify('error', "ERRORE", text)
}

function notifyWarning(text) {
    notify('warn', "ATTENZIONE", text)
}

function notifySuccess(text) {
    notify('success', "SUCCESSO", text)
}