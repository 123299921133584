import Vue from 'vue'
import Vuex from 'vuex'
import router from './config/AppRoutes'
import store from './store/store.js'
import LoginService from './api/LoginService'
import Multiselect from 'vue-multiselect'
import Vuetify from 'vuetify'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import DateRangePicker from 'vue2-daterange-picker'
import VueJwtDecode from 'vue-jwt-decode'
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Chrome } from 'vue-color'
import Swal from 'sweetalert2'
import Vue2Editor from "vue2-editor";
import Loading from 'vue-loading-overlay';
import draggable from 'vuedraggable'


//components
import Datatable from './components/datatable/Datatable.vue'
import LangTabs from './components/lang-tabs/LangTabs.vue'
import LanguagesModal from './components/modal/LanguagesModal.vue'
import FileModal from './components/modal/FileModal.vue'
import SearchParameterModal from './components/modal/SearchParameterModal.vue'
import TemplateArticle from './components/template-article/TemplateArticle.vue'
import DatatableTemplate from './components/datatable/DatatableTemplate.vue'
import DatatableUser from './components/datatable/DatatableUser.vue'
import PanelCurriculum from './components/panel/PanelCurriculum.vue'

// plugins css
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icons.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'bootstrap/dist/css/bootstrap.css'
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css"
import 'flag-icon-css/css/flag-icons.min.css'
// import 'sweetalert2/src/sweetalert2.scss'
import 'vuetify/dist/vuetify.min.css'
import 'vue-loading-overlay/dist/vue-loading.css';

// color admin css
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'

// import '@tinymce/tinymce.min'
// import '@tinymce/themes/silver/theme.min'


import App from './App.vue'
import vuetify from './plugins/vuetify'
// import vuetify from './plugins/vuetify.js'

Vue.prototype.$swal = Swal

Vue.config.productionTip = false

const FilePond = vueFilePond(
	FilePondPluginFileValidateType,
	FilePondPluginImagePreview,
	FilePondPluginFileValidateSize
);

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(Vue2Editor);
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(Vuetify)

Vue.component("draggable", draggable)
Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('date-range-picker', DateRangePicker)
Vue.component("datatable", Datatable)
Vue.component("lang-tabs", LangTabs)
Vue.component('file-pond', FilePond)
Vue.component('multiselect', Multiselect)
Vue.component('chrome-picker', Chrome)
Vue.component('languages-modal', LanguagesModal)
Vue.component('file-modal', FileModal)
Vue.component('search-parameter-modal', SearchParameterModal)
Vue.component('template-article', TemplateArticle)
Vue.component('datatable-template', DatatableTemplate)
Vue.component('datatable-user', DatatableUser)
Vue.component("loading", Loading);
Vue.component("panel-curriculum", PanelCurriculum)


// const router = new VueRouter({
// 	routes
// })

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.name === 'Logout')){
        store.dispatch("logout");
		next()
		return
	}
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (localStorage.getItem('token') === null || localStorage.getItem('token') === "") {
				next({name: 'Login', query: {redirect: to.path}})
				return
		} else {
			const jwt = VueJwtDecode.decode(localStorage.getItem('token'))
			var exp = new Date(0).setUTCSeconds(jwt.exp)
			var date = new Date().getTime()
			if((exp - date) < 0){
				store.commit("logoutUserWithoutLogin");
				next({name: 'Login', query: {redirect: to.path}}) 
				return
			} else {
				var params = {
					refresh: localStorage.getItem('refresh')
				}
				LoginService.refreshToken(params)
					.then(resp => {
						store.commit("setToken", resp.data.access)
					})
					.catch(err => {
						console.log(err.response)
						store.dispatch("logout");
						next()
						return
					})
			}
			next()
		}
	} else {
		if(to.path.includes("reset")){
			var user_id = to.query.user_id
			var timestamp = to.query.timestamp
			var signature = to.query.signature
			next({name: "ResetPassword", query: {user_id: user_id, timestamp: timestamp, signature: signature}})
			return
		}
		if(to.path.includes("verify")){
			var user_id1 = to.query.user_id
			var timestamp1 = to.query.timestamp
			var signature1 = to.query.signature
			next({name: "VerifyAccount", query: {user_id: user_id1, timestamp: timestamp1, signature: signature1}})
			return
		}
		next() // make sure to always call next()!
	}
})

new Vue({
    store,
    render: h => h(App),
    vuetify,
    router
}).$mount('#app')