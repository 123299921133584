import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchRegistrationRequests(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/registration_request/?format=datatables' + extra)
    },
    getRegistrationRequest(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/registration_request/${id}/`)
    },
    changeRegistrationRequest(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/registration_request/${id}/`, params)
    },
    newRegistrationRequest(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/registration_request/`, params)
    },
    deleteRegistrationRequest(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/registration_request/${id}/`)
    },
    discardRegistrationRequest(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/registration_request/${id}/discard/`)
    }
}
