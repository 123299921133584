<template>
    <b-modal id="modal-center" v-model="show" size="sm" centered title="Seleziona Lingue" no-close-on-backdrop>
        <ul class="list-group">
            <li  v-for="(la) in newLangs" :key="la.value" class="list-group-item d-flex justify-content-between align-items-center">
                <span :class="`flag-icon flag-icon-${la.icon}`"></span>{{ la.title }}
                <span class="badge badge-primary badge-pill">
                    <b-form-checkbox v-model="la.visible" />
                </span>
            </li>
        </ul>
        <template #modal-footer>
            <b-button
            variant="primary"
            class="float-right"
            @click="confirm"
          >
            Conferma
          </b-button>
            <b-button
            variant="danger"
            class="float-right"
            @click="cancel"
          >
            Annulla
          </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: ["langs"],
    data() {
        return {
            show: true,
            newLangs: []
        }
    },
    mounted() {
        this.newLangs = JSON.parse(JSON.stringify(this.langs))
    },
    destroyed() {
        console.log("DESTROYED")
    },
    methods: {
        confirm() {
            this.$emit('confirm', this.newLangs)
        },
        cancel() {
            this.$emit('cancel', this.newLangs)
        }
    }
}
</script>