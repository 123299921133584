import store from "../store/store";

export default {
    showLoader,
    hideLoader
}

function showLoader() {
    store.commit("setLoader", true)
}

function hideLoader() {
    store.commit("setLoader", false)
}