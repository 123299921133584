<template>
    <datatable ref="table" :columns="columns" :rows="rows" @fetchData="fetchData" @edit="editUser" :recordsTotal="recordsTotal" :selectable="selectable" :pagination="pagination" />
</template>

<script>
import UserService from '../../api/UserService';
import constants from '../../config/constants.js';
import loader from '../../config/loader';
import utils from '../../config/utils.js';
var xlsx = require("xlsx");
export default {
    props: ["selectable", "users"],
    data() {
        return {
            constants,
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'Extra',
                    field: 'extra',
                    hidden: true,
                    filterOptions: {
                        enabled: true
                    },
                    index: 1
                },
                {
                    label: 'Azienda',
                    field: 'extra.company',
                    filterOptions: {
                        enabled: true
                    },
                    index: 2
                },
                {
                    label: 'Email',
                    field: 'email',
                    filterOptions: {
                        enabled: true
                    },
                    index: 3
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false, 
                    hidden: this.selectable
                }
            ],
            rows: [],
            recordsTotal: 0,
            pagination: constants.PAGINATION_OPTIONS
        };
    },
    mounted() {
        this.fetchData()
    },
    computed: {
        getSelected() {
            return this.$refs.table.$refs.table.selectedRows
        }
    },
    watch: {
        users: {
            deep: true,
            handler: function() {
                this.setSelectedUser()
            }
        }
    },
    methods: {
        async exportXlsx() {
            loader.showLoader()
            var header = []
            // this.columns.forEach(col => {
            //     if(col.hidden || col.field === "cmd"){
            //         return
            //     }
            //     header.push(col.label)
            // });
            header.push("ID")
            header.push("Azienda")
            header.push("Email")
            header.push("Lingua")
            header.push("Profilo")
            header.push("Nome file catalogo riservato")
            var wb = xlsx.utils.book_new();
            wb.SheetNames.push("Utenti");
            var ws_data = []
            ws_data.push(header)
            var excelRows = []
            await this.doCall(`&start=0&length=9999999`)
                .then(response => {
                    excelRows = response
                })
                .catch(err => {
                    console.log(err)
                })
            excelRows.forEach(row => {
                var newRowExcel = []
                newRowExcel.push(row.id)
                newRowExcel.push(utils.checkObjectIsUndefinedOrEmpty(row.extra) || utils.checkIsUndefinedOrEmpty(row.extra.company) ? '' : row.extra.company)
                newRowExcel.push(row.email)
                const lang = utils.findObject(constants.LANGS_TABS, row.language, 'value')
                newRowExcel.push(utils.checkObjectIsUndefinedOrEmpty(lang) ? row.language : lang.title)
                if(utils.checkObjectIsUndefinedOrEmpty(row.extra) || utils.checkIsUndefinedOrEmpty(row.extra.profile)) {
                    newRowExcel.push("")
                } else {
                    const profile = utils.findObject(constants.PROFILE_TABS, row.extra.profile, 'value')
                    newRowExcel.push(utils.checkObjectIsUndefinedOrEmpty(lang) ? row.extra.profile : profile.title)
                }
                newRowExcel.push(row.filename)
                ws_data.push(newRowExcel)
            });
            var ws = xlsx.utils.aoa_to_sheet(ws_data);
            wb.Sheets["Utenti"] = ws;
            xlsx.writeFile(wb, `Registro_download_utenti_ac.xlsx`, { bookType: "xlsx" });
            loader.hideLoader()
        },
        editUser(id){
            this.$router.push({name: "EditUser", params: {id: id}})
        },
        setSelectedUser() {
            if(!utils.checkObjectIsUndefinedOrEmpty(this.users)) {
                this.users.forEach(user => {
                    var row = utils.findObject(this.rows, user.user, "id")
                    if(!utils.checkIsUndefined(row)) {
                        this.$set(row, 'vgtSelected', true);
                        // row["vgtSelected"] = true
                        // this.$refs.table.$refs.table.selectedRows.push(row)
                    }
                });
            }
        },
        async doCall(stringReceived) {
            loader.showLoader()
            var rows = []
            await UserService.fetchUsers(stringReceived)
            .then(resp => {
                rows = resp.data.data
                this.recordsTotal = resp.data.recordsFiltered
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
            return rows
        },
        async fetchData(stringReceived) {
            loader.showLoader()
            if(this.selectable){
                this.pagination = constants.PAGINATION_OPTIONS_USERS
            }
            var string = utils.getStringFilter(this.columns)
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${this.pagination.perPage}`
            } else {
                string += stringReceived
            }
            await this.doCall(string)
            .then(resp => {
                this.rows = resp
                // this.recordsTotal = resp.data.recordsFiltered
                this.setSelectedUser()
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
        },
    }
}
</script>