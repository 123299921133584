<template>
    <panel title="Utenti Area Clienti">
        <template v-slot:buttons>
            <a class="btn btn-success" @click="newUser"><i class="fa fa-plus"></i> Nuovo Utente</a>
            <a class="btn btn-warning" @click="exportXlsx"><i class="fa fa-file-csv"></i> Excel</a>
        </template>
        <datatable-user ref="users"/>
    </panel>
</template>

<script>
export default {
    methods: {
        newUser() {
            this.$router.push({name: "NewUser"})
        },
        exportXlsx() {
            this.$refs.users.exportXlsx()
        }
    }
}
</script>

