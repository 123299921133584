<script>
import Vue from 'vue'
import Router from 'vue-router'

import Home from '../pages/Home.vue'
import Articles from '../pages/Articles.vue'
import PriceList from '../pages/PriceList.vue'
import DetailArticle from '../pages/DetailArticle.vue'
import News from '../pages/News.vue'
import DetailNews from '../pages/DetailNews.vue'
import Downloads from '../pages/Downloads.vue'
import DetailDownload from '../pages/DetailDownload.vue'
import Users from '../pages/Users.vue'
import DetailUser from '../pages/DetailUser.vue'
import Login from '../pages/Login.vue'
import Categories from '../pages/Categories.vue'
import DetailCategory from '../pages/DetailCategory.vue'
import Materials from '../pages/Materials.vue'
import DetailMaterial from '../pages/DetailMaterial.vue'
import CharacteristicTemplate from '../pages/CharacteristicTemplate.vue'
import DetailTemplate from '../pages/DetailTemplate.vue'
import Profile from '../pages/Profile.vue'
import Translations from '../pages/Translations.vue'
import DetailTranslation from '../pages/DetailTranslation.vue'
import Curriculum from '../pages/Curriculum.vue'
import DetailCurriculum from '../pages/DetailCurriculum.vue'
import DetailPriceList from '../pages/DetailPriceList.vue'
import Audit from '../pages/Audit.vue'
import ImportComponents from '../pages/ImportComponents.vue'
import EmailTemplates from '../pages/EmailTemplates.vue'
import DetailEmailTemplate from '../pages/DetailEmailTemplate.vue'
import RegistrationRequests from '../pages/RegistrationRequests.vue'
import Configs from '../pages/Configs.vue'
import DetailConfig from '../pages/DetailConfig.vue'

Vue.use(Router)

const routes = new Router({
  mode: 'hash',
  routes: [{
    path: '/',
    component: Home,
    name: "Home",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: Login,
    name: "Login"
  },
  {
    path: '/logout',
    component: Login,
    name: "Logout"
  },
  {
    path: '/articles',
    component: Articles,
    name: "Articles",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/new',
    component: DetailArticle,
    name: "NewArticle",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/:id',
    component: DetailArticle,
    name: "EditArticle",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-articles',
    component: Articles,
    name: "ReservedArticles",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-articles/new',
    component: DetailArticle,
    name: "NewReservedArticle",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-articles/:id',
    component: DetailArticle,
    name: "EditReservedArticle",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/import-components',
    component: ImportComponents,
    name: "ImportComponents",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/price-list',
    component: PriceList,
    name: "PriceList",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/price-list/new',
    component: DetailPriceList,
    name: "NewPriceList",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/price-list/:id',
    component: DetailPriceList,
    name: "EditPriceList",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/news',
    component: News,
    name: "News",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/news/new',
    component: DetailNews,
    name: "NewNews",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/news/:id',
    component: DetailNews,
    name: "EditNews",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/download',
    component: Downloads,
    name: "Downloads",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/download/new',
    component: DetailDownload,
    name: "NewDownload",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/download/:id',
    component: DetailDownload,
    name: "EditDownload",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-download',
    component: Downloads,
    name: "ReservedDownloads",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-download/new',
    component: DetailDownload,
    name: "NewReservedDownload",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-download/:id',
    component: DetailDownload,
    name: "EditReservedDownload",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    component: Categories,
    name: "Categories",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories/new',
    component: DetailCategory,
    name: "NewCategory",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories/:id',
    component: DetailCategory,
    name: "EditCategory",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    component: Users,
    name: "Users",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/new',
    component: DetailUser,
    name: "NewUser",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id',
    component: DetailUser,
    name: "EditUser",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-categories',
    component: Categories,
    name: "ReservedCategories",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-categories/new',
    component: DetailCategory,
    name: "NewReservedCategory",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reserved-categories/:id',
    component: DetailCategory,
    name: "EditReservedCategory",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/materials',
    component: Materials,
    name: "Materials",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/materials/new',
    component: DetailMaterial,
    name: "NewMaterial",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/materials/:id',
    component: DetailMaterial,
    name: "EditMaterial",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/characteristic-template',
    component: CharacteristicTemplate,
    name: "CharacteristicTemplate",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/characteristic-template/new',
    component: DetailTemplate,
    name: "NewTemplate",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/characteristic-template/:id',
    component: DetailTemplate,
    name: "EditTemplate",
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/profile',
    component: Profile,
    name: "Profile",
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/translations',
    component: Translations,
    name: "Translations",
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/translations/new',
    component: DetailTranslation,
    name: "NewTranslation",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/translations/:id',
    component: DetailTranslation,
    name: "EditTranslation",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/configs',
    component: Configs,
    name: "Configs",
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/configs/new',
    component: DetailConfig,
    name: "NewConfig",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/configs/:id',
    component: DetailConfig,
    name: "EditConfig",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/curriculum',
    component: Curriculum,
    name: "Curriculum",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/curriculum/new',
    component: DetailCurriculum,
    name: "NewCurriculum",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/curriculum/:id',
    component: DetailCurriculum,
    name: "EditCurriculum",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/audit',
    component: Audit,
    name: "Audit",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/email-templates',
    component: EmailTemplates,
    name: "EmailTemplates",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/email-templates/new',
    component: DetailEmailTemplate,
    name: "NewEmailTemplate",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/email-templates/:id',
    component: DetailEmailTemplate,
    name: "EditEmailTemplate",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration-requests',
    component: RegistrationRequests,
    name: "RegistrationRequests",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration-requests/:id',
    component: DetailUser,
    name: "EditRegistrationRequests",
    meta: {
      requiresAuth: true
    }
  },
]})

export default routes;
</script>
