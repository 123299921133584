<template>
    <div>
        <v-data-table class="template" mobile-breakpoint="0" hide-default-header :items="data" :search="search"
            :hide-default-footer="!isPagination" :disable-sort="!sortable" :items-per-page="100">
            <template slot="header">
                <thead>
                    <tr>
                        <td></td>
                        <td class="text-center">Pubblicato</td>
                        <td class="text-center">Su Richiesta</td>
                        <td class="text-center divider-table" v-for="el in header" :key="el.title" :rowspan="el.rows"
                            :colspan="el.cols">{{ el.title }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center min-width-50" v-for="el in secondRowHeader" :key="el.id + el.title" :rowspan="el.rows"
                            :colspan="el.cols">{{ el.title }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <draggable
                :list="data"
                tag="tbody"
                handle=".handle"
                ghost-class="ghost"
                drag-class="ghost"
                :options="{ forceFallback: true }"
                >
                <!-- <tbody> -->
                    <tr v-for="(item, indexRow) in items" :key="indexRow">
                        <td class="px-3"><i class="fa fa-align-justify handle cursor-grab"></i></td>
                        <td class="text-center"><input type="checkbox" v-model="item.published" /></td>
                        <td class="text-center"><input type="checkbox" v-model="item.on_request" /></td>
                        <td v-for="(col, i) in secondRowHeader" class="text-center min-width-50" :ref="'td' + col.id + i + indexRow"
                            @blur="event => onBlur(col.id, i, indexRow)" @paste="event => onPaste(event, col.id, indexRow)" :key="col.id + i + indexRow" :contenteditable="isEdit" v-html="item.data[col.id]">
                        </td>
                        <td>
                            <button :disabled="!isEdit" class="btn btn-secondary file-btn btn-components" :class="utils.checkIsUndefinedOrEmpty(item.file) ? 'file-btn__none' : ''" @click="showModal(indexRow, item.file, true)">
                                <img v-if="utils.checkIsUndefinedOrEmpty(item.file)" height="100%" src="@/assets/icons/tavole_no.svg" class="image-icon-18" alt="">
                                <img v-else height="100%" src="@/assets/icons/tavole_si.svg" class="image-icon-18" alt="">
                            </button>
                        </td>
                        <td>
                            <button :disabled="!isEdit" class="btn btn-primary btn-components" @click="showModal(indexRow, item.search_parameter, false)"><i class="fa fa-search"></i></button>
                        </td>
                        <td>
                            <button :disabled="!isEdit" class="btn btn-danger btn-components" @click="deleteComponent(item, indexRow)"><i
                                    class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="100%">
                            <button :disabled="!isEdit" class="btn btn-success w-100" @click="newComponent"><i class="fa fa-plus"></i></button>
                        </td>
                    </tr>
                <!-- </tbody> -->
                </draggable>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
            </v-alert>
        </v-data-table>
        <search-parameter-modal v-if="show" ref="modal" :searchParameter="actualSearchParameter" @confirm="confirmParameter"
            @cancel="cancelParameter" />
        <file-modal v-if="showFile" ref="modalFile" :file="actualFile" @cancel="cancelFile" @confirm="confirmFile" />
    </div>
</template>

<script>
import ArticleService from '../../api/ArticleService';
import swal from '../../config/swal.js'
import utils from '../../config/utils';
import SearchParameterModal from '../modal/SearchParameterModal.vue';
export default {
  components: { SearchParameterModal },
    props: ['searchable', 'isPagination', 'sortable', "header", "id", "isEdit"],
    data: () => ({
        utils,
        show: false,
        showFile: false,
        data: [],
        pagination: {
            sortBy: 'code'
        },
        search: '',
        secondRowHeader: [],
        actualIndex: 0,
        actualSearchParameter: {},
        actualFile: null,
        columns: [{
                text: "CODICE",
                align: 'left',
                value: 'code'
            },
            {
                text: "CODICE",
                value: 'diameters'
            },
            {
                text: "CODICE",
                value: 'bolts'
            },
            {
                text: "CODICE",
                value: 'packs'
            },
            {
                text: "CODICE",
                value: 'dimension'
            },
        ],
    }),
    mounted() {
        if (!utils.checkObjectIsUndefinedOrEmpty(this.header)) {
            this.header.forEach(element => {
                if (element.children.length === 0) {
                    return
                }
                element.children.forEach(child => {
                    this.secondRowHeader.push(child)
                });
            });
        }
    },
    methods: {
        showModal(index, element, isFile) {
            this.actualIndex = index
            if(isFile){
                this.showFile = true
                this.actualFile = element
                return
            }
            this.actualSearchParameter = element
            this.show = true
        },
        cancelParameter() {
            this.show = false
        },
        cancelFile() {
            this.showFile = false
        },
        confirmParameter(parameter) {
            this.$set(this.data[this.actualIndex], "search_parameter", parameter)
            this.cancelParameter()
        },
        confirmFile(file) {
            this.$set(this.data[this.actualIndex], "file", file)
            this.cancelFile()
        },
        newComponent() {
            this.data.push(utils.newComponent(this.secondRowHeader.length, this.id))
        },
        async deleteComponent(component, index) {
            swal.withConfirm(
                'Sei sicuro di voler eliminare il componente?',
                `Confermando il componente verrà eliminato definitivamente!`,
                'Componente eliminato!',
                async () => {
                    if (utils.checkIsUndefinedOrEmpty(component.id)) {
                        this.data.splice(index, 1)
                        return
                    }
                    await ArticleService.deleteComponent(component.id)
                        .then(() => {
                            this.data.splice(index, 1)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
        },
        onBlur(element, index, indexRow) {
            this.$set(this.data[indexRow].data, element, this.$refs["td" + element + index + indexRow][0].innerHTML.trim())
        },
        onPaste (evt, element, indexRow) {
            evt.preventDefault();

            // get text representation of clipboard
            var text = (evt.originalEvent || evt).clipboardData.getData('text/plain');

            this.$set(this.data[indexRow].data, element, text.trim())
        }
    },

    watch: {
        header: {
            deep: true,
            handler: function () {
                this.secondRowHeader = []
                if (utils.checkObjectIsUndefinedOrEmpty(this.header)) {
                    return
                }
                this.header.forEach(element => {
                    if (element.children.length === 0) {
                        return
                    }
                    element.children.forEach(child => {
                        this.secondRowHeader.push(child)
                    });
                });
            }
        }
    }
}
</script>


<style lang="scss" scoped>

</style>