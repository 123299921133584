<template>
	<!-- BEGIN login -->
	<div class="login login-v1">
		<!-- BEGIN login-container -->
		<div class="login-container">
			<!-- BEGIN login-header -->
			<div class="login-header">
				<div class="brand">
					<div class="d-flex align-items-center">
						<img src="./../assets/image/sm_logo_def.svg" width="400px" />
					</div>
					<!-- <small>Bootstrap 5 Responsive Admin Template</small> -->
				</div>
			</div>
			<!-- END login-header -->
			
			<!-- BEGIN login-body -->
			<div class="login-body">
				<!-- BEGIN login-content -->
				<div class="login-content fs-13px">
					<form>
						<div class="form-floating mb-20px">
							<input type="email" v-model="email" class="form-control fs-13px h-45px" id="emailAddress" placeholder="Email" />
							<label for="emailAddress" class="d-flex align-items-center py-0">Email</label>
						</div>
						<div class="form-floating mb-20px">
							<input type="password" @keyup.enter="login" v-model="password" class="form-control fs-13px h-45px" id="password" placeholder="Password" />
							<label for="password" class="d-flex align-items-center py-0">Password</label>
						</div>
						<!-- <div class="form-check mb-20px">
							<input class="form-check-input" type="checkbox" value="" id="rememberMe" />
							<label class="form-check-label" for="rememberMe">
								Remember Me
							</label>
						</div> -->
						<div class="login-buttons">
							<a @click="login" class="btn h-45px bg-main color-white center-flex w-100 btn-lg">Accedi</a>
						</div>
					</form>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-body -->
		</div>
		<!-- END login-container -->
	</div>
	<!-- END login -->
</template>

<script>
import AppOptions from '../config/AppOptions.vue'

export default {
    data() {
        return {
            email: "",
            password: ""
        }
    },
	created() {
		AppOptions.appEmpty = true;
	},
	beforeRouteLeave (to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	methods: {
		login () {
			// if(this.$refs.form.validate()){
                const user = {
                    username: this.email,
                    password: this.password
                };
                this.$store.dispatch("signinUser", {
                    user
                });
            // }
		}
	}
}
</script>