<script>
const sidebarMenu = [
  { path: '/', title: 'Home', name: "Home", img: require('@/assets/icons/home.svg'), img_inactive: require('@/assets/icons/inactive/home.svg')},
  { path: '/users', title: 'Utenti Area Clienti', name: "Users", img: require('@/assets/icons/utenti.svg'), img_inactive: require('@/assets/icons/inactive/utenti.svg')},
  { path: '/registration-requests', title: 'Lista Richieste Registrazione', name: "RegistrationRequests", img: require('@/assets/icons/lista_richieste.svg'), img_inactive: require('@/assets/icons/inactive/lista_richieste.svg')},
  { path: '/audit', title: 'Audit', name: "Audit", img: require('@/assets/icons/audit.svg'), img_inactive: require('@/assets/icons/inactive/audit.svg')},
  { path: '/news', title: 'Novità', name: "News", img: require('@/assets/icons/novita.svg'), img_inactive: require('@/assets/icons/inactive/novita.svg')},
  { path: '/articles', title: 'Articoli', name: "Articles", img: require('@/assets/icons/articoli.svg'), img_inactive: require('@/assets/icons/inactive/articoli.svg')},
  { path: '/reserved-articles', title: 'Articoli Riservati', name: "ReservedArticles", img: require('@/assets/icons/articoli_riservati.svg'), img_inactive: require('@/assets/icons/inactive/articoli_riservati.svg')},
  { path: '/materials', title: 'Materiali', name: "Materials", img: require('@/assets/icons/materiali.svg'), img_inactive: require('@/assets/icons/inactive/materiali.svg')},
  { path: '/categories', title: 'Categorie', name: "Categories", img: require('@/assets/icons/categorie.svg'), img_inactive: require('@/assets/icons/inactive/categorie.svg')},
  { path: '/reserved-categories', title: 'Categorie Riservate', name: "ReservedCategories", img: require('@/assets/icons/categorie_riservate.svg'), img_inactive: require('@/assets/icons/inactive/categorie_riservate.svg')},
  { path: '/download', title: 'Download', name: "Download", img: require('@/assets/icons/download.svg'), img_inactive: require('@/assets/icons/inactive/download.svg')},
  { path: '/reserved-download', title: 'Download Riservati', name: "ReservedDownload", img: require('@/assets/icons/download_riservati.svg'), img_inactive: require('@/assets/icons/inactive/download_riservati.svg')},
  { path: '/price-List', title: 'Listino Prezzi', name: "PriceList", img: require('@/assets/icons/listino_prezzi.svg'), img_inactive: require('@/assets/icons/inactive/listino_prezzi.svg')},
  { path: '/translations', title: 'Traduzioni', name: "Translations", img: require('@/assets/icons/traduzioni.svg'), img_inactive: require('@/assets/icons/inactive/traduzioni.svg')},
  { path: '/characteristic-template', title: 'Template Tabelle', name: "CharacteristicTemplate", img: require('@/assets/icons/template.svg'), img_inactive: require('@/assets/icons/inactive/template.svg')},
  { path: '/email-templates', title: 'Email Template', name: "EmailTemplates", img: require('@/assets/icons/email_template.svg'), img_inactive: require('@/assets/icons/inactive/email_template.svg')},
  { path: '/import-components', title: 'Importa Tavole Tecniche', name: "ImportComponents", img: require('@/assets/icons/importa_tecniche.svg'), img_inactive: require('@/assets/icons/inactive/importa_tecniche.svg')},
  { path: '/curriculum', title: 'Curriculum', name: "Curriculum", img: require('@/assets/icons/curriculum.svg'), img_inactive: require('@/assets/icons/inactive/curriculum.svg')},
  { path: '/configs', title: 'Configurazioni', name: "Configs", img: require('@/assets/icons/configurazioni.svg'), img_inactive: require('@/assets/icons/inactive/configurazioni.svg')},
]

export default sidebarMenu;
</script>
