import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchCurriculum(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/curriculum/?format=datatables' + extra)
    },
    getCurriculum(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/curriculum/${id}/`)
    },
    changeCurriculum(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/curriculum/${id}/`, params)
    },
    newCurriculum(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/curriculum/`, params)
    },
    deleteCurriculum(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/curriculum/${id}/`)
    }
}
