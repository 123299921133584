<template>
  <b-modal id="modal-center" v-model="show" size="sm" centered title="Parametri di ricerca" no-close-on-backdrop>
    <div class="row">
      <div class="col-6">
        <label>Da (mm)</label>
        <input type="number" class="form-control" v-model="newParameter.from"
               placeholder="Da (mm)"/>
      </div>
      <div class="col-6">
        <label>A (mm)</label>
        <input type="number" class="form-control" v-model="newParameter.to"
               placeholder="A (mm)"/>
      </div>
    </div>
    <!-- <ul class="list-group">
        <li  v-for="(la) in newLangs" :key="la.value" class="list-group-item d-flex justify-content-between align-items-center">
            <span :class="`flag-icon flag-icon-${la.icon}`"></span>{{ la.title }}
            <span class="badge badge-primary badge-pill">
                <b-form-checkbox v-model="la.visible" />
            </span>
        </li>
    </ul> -->
    <template #modal-footer>
      <b-button
          variant="primary"
          class="float-right"
          @click="confirm"
      >
        Conferma
      </b-button>
      <b-button
          variant="danger"
          class="float-right"
          @click="cancel"
      >
        Annulla
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import utils from '../../config/utils'
import notification from "@/config/notification";

export default {
  props: ["searchParameter"],
  data() {
    return {
      show: true,
      newParameter: utils.getSearcParameter()
    }
  },
  mounted() {
    if (utils.checkObjectIsUndefinedOrEmpty(this.searchParameter)) {
      this.newParameter = utils.getSearcParameter()
    } else {
      this.newParameter = JSON.parse(JSON.stringify(this.searchParameter))
    }
  },
  destroyed() {
    console.log("DESTROYED")
  },
  methods: {
    confirm() {
      if (utils.checkIsUndefinedOrEmpty(this.newParameter.from)) {
        notification.notifyWarning('Inserisci un valore "da"')
        return
      }
      if (utils.checkIsUndefinedOrEmpty(this.newParameter.to)) {
        notification.notifyWarning('Inserisci un valore "a"')
        return
      }
      const from = parseInt(this.newParameter.from)
      const to = parseInt(this.newParameter.to)
      if (isNaN(from)) {
        notification.notifyWarning('Inserisci un valore "da" valido')
        return
      }
      if (isNaN(to)) {
        notification.notifyWarning('Inserisci un valore "aa" valido')
        return
      }
      if (from > to) {
        notification.notifyWarning('"da" non può essere maggiore di "a"')
        return
      }
      this.$emit('confirm', utils.getSearcParameter(from, to))
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>