<template>
  <panel title="Lista Richieste Registrazioni">
        <datatable :columns="columns" :rows="rows" @edit="edit" @fetchData="fetchData" :recordsTotal="recordsTotal" />
    </panel>
</template>

<script>
import RegistrationRequestService from '../api/RegistrationRequestService.js'
import constants from '../config/constants.js';
import loader from '../config/loader.js';
import utils from '../config/utils.js';
export default {
    data() {
        return {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'user',
                    field: 'user',
                    hidden: true,
                    index: 1
                },
                {
                    label: 'Numero',
                    field: 'sequential_number',
                    index: 2
                },
                {
                    label: 'Azienda',
                    field: 'company',
                    index: 3
                },
                {
                    label: 'Data',
                    field: 'date_created',
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ssX", // expects 2018-03-16
                    dateOutputFormat: 'dd/MM/yyyy',
                    index: 4
                },
                {
                    label: 'Stato',
                    field: 'state',
                    index: 5
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false
                }
            ],
            rows: [],
            recordsTotal: 0,
        };
    },
    async mounted() {
        if(utils.checkArrayIsUndefinedOrEmpty(utils.getLocalStorageFilter(this.$route.name))){
            this.fetchData()
        } else {
            utils.getLocalStorageFilter(this.$route.name).forEach(element => {
                if(element.type === constants.SEARCH_KEY) {
                    this.$set(this.columns[element.index].filterOptions, "filterValue", element.value)
                }
            });
        }
    },
    methods: {
        async fetchData(stringReceived) {
            loader.showLoader()
            var string = utils.getStringFilter(this.columns)
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
            } else {
                string += stringReceived
            }
            await RegistrationRequestService.fetchRegistrationRequests(string)
            .then(resp => {
                this.rows = resp.data.data
                this.recordsTotal = resp.data.recordsFiltered
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
        },
        edit(id) {
            this.$router.push({name: "EditRegistrationRequests", params: {id: id}})
        }
    }
}
</script>

<style>

</style>