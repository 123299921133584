import Api from './index.js'
import store from '../store/store.js'

export default {
    authenticate (params) {
        return Api().post('api/account/token-auth/', params)
    },
    refreshToken (params) {
        return Api().post('api/account/token/token-refresh/', params)
    },
    register (params) {
        return Api().post('api/account/register/', params)
    },
    verifyAccount (params) {
        return Api().post('api/account/verify-registration/', params)
    },
    sendResetPassword(params) {
        return Api().post('api/account/send-reset-password-link/', params)
    },
    resetPassword(params) {
        return Api().post('api/account/reset-password/', params)
    },
    changePassword(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post('api/account/change-password/', params)
    },
    changeAccount(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch('api/account/profile/', params)
    },
}
