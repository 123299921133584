<template>
    <panel title="Importa Tavole Tecniche">
        <template v-slot:buttons>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="col-12">
                <file-pond
                    v-model="files"
                    class="mt-2" :maxFileSize="constants.MAX_FILE_SIZE_COMPONENT"
                    labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                    name="test"
                    ref="coverImage"
                    label-idle="Clicca qui o trascina i file"
                    v-bind:allow-multiple="true"
                    :accepted-file-types="constants.ACCEPTED_FILE_TYPES_PDF"
                    :maxFiles="constants.MAX_FILES_COMPONENT"
                    />
                    <!-- v-on:updatefiles="updateCoverImage" -->
            </div>
        </div>
    </panel>
</template>
<script>
import utils from '../config/utils.js'
// import swal from '../config/swal.js'
import loader from '../config/loader.js'
import constants from '../config/constants.js'
import ArticleService from '../api/ArticleService.js'
import notification from '../config/notification.js'
export default {
    data() {
        return {
            files: [],
            constants,
        }
    },
    async mounted() {},
    methods: {
        async save(){
            loader.showLoader()
            let params = new FormData()
            this.files.forEach((file, index) => {
                params.append(`file${index}`, file.file)
            });
            await ArticleService.importComponents(params)
                .then(resp => {
                    console.log(resp)
                    if(utils.checkIsUndefinedOrEmpty(resp.data.skipped)){
                        notification.notifySuccess("Tavole tecniche caricate correttamente")
                        return
                    }
                    var skips = resp.data.skipped.split(',')
                    var msg = 'I seguenti file non hanno un componente correlato:<br/>'
                    skips.forEach(skip => {
                        msg += `${skip}<br/>`
                    });
                    notification.notifyWarning(msg)
                    
                })
                .catch(err => {
                    console.log(err)
                    notification.notifyError("Errore durante l'importazione, riprova")
                })
            loader.hideLoader()
        },
    },
    // beforeRouteLeave(to, from, next) {
    //     if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
    //         next()
    //         return
    //     }
    //     swal.alertLeave(next)
    // }

}
</script>