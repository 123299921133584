<template>
    <panel :title="isReserved ? 'Articoli Riservati' : 'Articoli'">
        <template v-slot:buttons>
            <input id="fileUpload" @change="inputFile" type="file" hidden accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            <a v-if="isReserved" class="btn btn-primary" @click="chooseFiles()"><i class="fa fa-file-excel"></i> Importa Excel</a>
            <a v-if="isReserved" class="btn btn-warning" @click="excel"><i class="fa fa-file-excel"></i> Scarica Excel</a>
            <a class="btn btn-success" @click="newArticle"><i class="fa fa-plus"></i> Nuovo Articolo</a>
        </template>
        <datatable ref="datatable" :columns="columns" :rows="rows" @edit="edit" @fetchData="fetchData" :recordsTotal="recordsTotal"/>
    </panel>
</template>

<script>
import ArticleService from "../api/ArticleService.js"
import CategoryService from '../api/CategoryService'
import MaterialService from '../api/MaterialService'
import constants from '../config/constants.js';
import loader from '../config/loader.js';
import notification from '../config/notification.js';
import utils from '../config/utils.js';
export default {
    data() {
        return {
            recordsTotal: 0,
            materials: [],
            categories: [],
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'Ordinamento Articolo',
                    field: 'order',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Ordinamento"
                    },
                    index: 1
                },
                {
                    label: 'N° Articolo',
                    field: 'code',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Codice"
                    },
                    index: 2
                },
                {
                    label: 'Descrizione',
                    field: 'description',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Descrizione"
                    },
                    index: 3
                },
                {
                    label: 'Categoria',
                    field: 'category',
                    filterOptions: {
                        placeholder: "Categoria",
                        enabled: true,
                        filterDropdownItems: this.arrayCategories
                    },
                    index: 4,
                    hidden: true
                },
                {
                    label: 'Categoria',
                    field: 'category__id',
                    filterOptions: {
                        styleClass: 'select-with-dropdown',
                        enabled: true,
                        placeholder: "Categoria",
                        filterDropdownItems: this.arrayCategories
                    },
                    index: 5,
                    sortable: false
                },
                {
                    label: 'Materiale',
                    field: 'material',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Materiale",
                        filterDropdownItems: this.arrayMaterials
                    },
                    index: 6,
                    hidden: true
                },
                {
                    label: 'Materiale',
                    field: 'material__id',
                    filterOptions: {
                        styleClass: 'select-with-dropdown',
                        enabled: true,
                        placeholder: "Materiale",
                        filterDropdownItems: this.arrayMaterials
                    },
                    index: 7,
                    sortable: false
                },
                {
                    label: 'Riservato',
                    field: 'reserved',
                    hidden: true,
                    index: 8
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false
                }
            ],
            rows: [],
        };
    },
    computed: {
        arrayMaterials() {
            var array = []
            this.materials.forEach(material => {
                var element = {
                    value: material.id,
                    text: material.title.it
                }
                array.push(element)
            });
            return array
        },
        arrayCategories() {
            var array = []
            this.categories.forEach(category => {
                if(category.title.it.toLowerCase() != 'articoli riservati') {
                    var element = {
                        value: category.id,
                        text: category.title.it
                    }
                    array.push(element)
                }
            });
            return array
        },
        isReserved() {
            return this.$route.name === "ReservedArticles"
        }
    },
    async mounted() {
        
        // loader.showLoader()
        await CategoryService.fetchCategories()
            .then(resp => {
                this.categories = resp.data.data
            })
            .catch(err => {
                console.log(err)
            })
        await MaterialService.fetchMaterials()
            .then(resp => {
                this.materials = resp.data.data
            })
            .catch(err => {
                console.log(err)
            })
        this.$set(this.columns[5].filterOptions, "filterDropdownItems", this.arrayCategories)
        this.$set(this.columns[7].filterOptions, "filterDropdownItems", this.arrayMaterials)
        if(utils.checkArrayIsUndefinedOrEmpty(utils.getLocalStorageFilter(this.$route.name))){
            this.fetchData()
        } else {
            utils.getLocalStorageFilter(this.$route.name).forEach(element => {
                if(element.type === constants.SEARCH_KEY) {
                    this.$set(this.columns[element.index].filterOptions, "filterValue", element.value)
                }
            });
        }
        // loader.hideLoader()
    },
    methods: {
        async inputFile(e) {
            loader.showLoader()
            var file = e.target.files[0]
            let params = new FormData()
            params.append("file", file)
            await ArticleService.importExcel(params)
                .then(resp => {
                    console.log(resp)
                    document.getElementById("fileUpload").value = ""
                })
                .catch(err => {
                    console.log(err)
                })
            loader.hideLoader()
        },
        chooseFiles() {
            document.getElementById("fileUpload").click()
        },
        async excel() {
            loader.showLoader()
            ArticleService.exportExcel()
                .then(resp => {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    var blob = new Blob([resp.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = resp.headers['content-disposition'].split('filename=')[1]
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    notification.notifyError("Errore durante il download")
                    console.log(err)
                })
            loader.hideLoader()
        },
        async fetchData(stringReceived) {
            loader.showLoader()
            var string = utils.getStringFilter(this.columns)
            if(this.isReserved ) {
                string += `&columns[8][search][value]=${constants.FLAG_FILTER_TRUE}`
            } else {
                string += `&columns[8][search][value]=${constants.FLAG_FILTER_FALSE}`
            }
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
            } else {
                string += stringReceived
            }
            await ArticleService.fetchArticles(string)
            .then(resp => {
                this.recordsTotal = resp.data.recordsFiltered
                this.rows = resp.data.data
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
        },
        newArticle() {
            if(this.isReserved) {
                this.$router.push({name: "NewReservedArticle"})
                return
            }
            this.$router.push({name: "NewArticle"})
        },
        edit(id) {
            if(this.isReserved) {
                this.$router.push({name: "EditReservedArticle", params: {id: id}})
                return
            }
            this.$router.push({name: "EditArticle", params: {id: id}})
        }
    }
}
</script>