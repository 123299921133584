<template>
    <b-modal id="modal-center" v-model="show" size="lg" centered title="Tavola Tecnica" no-close-on-backdrop>
        <div class="col-md-5 col-lg-4 col-xl-4" v-if="typeof file === 'string' && !utils.checkIsUndefinedOrEmpty(file)">
            <label class="form-check-label" for="flexSwitchCheckDefault">File Attuale</label>
            <button class="btn btn-primary d-block w-100 mt-4" @click="utils.download(file)"><i :class="`fa fa-eye`"></i> Visualizza</button>
        </div>
        <file-pond class="mt-2" name="test" ref="file"
            label-idle="Clicca qui o trascina il file" v-bind:allow-multiple="false" v-on:updatefiles="updateFile" />

            <b-button
            variant="warning"
            class="float-right"
            @click="checkFile"
            v-if="!utils.checkIsUndefined(file)"
          >
            Pdf Attuale
          </b-button>
        <template #modal-footer>
            <b-button
            variant="primary"
            class="float-right"
            @click="confirm"
          >
            Conferma
          </b-button>
            <b-button
            variant="danger"
            class="float-right"
            @click="cancel"
          >
            Annulla
          </b-button>
        </template>
    </b-modal>
</template>

<script>
import utils from '../../config/utils'
export default {
    props: ["file"],
    data() {
        return {
            utils,
            show: true,
            newFile: []
        }
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.populate()
        // })
    },
    watch: {
        "this.$refs.file": {
            deep: true,
            handler: function() {
                console.log(this.$ref.file)
            }
        }
    },
    methods: {
        checkFile() {
            window.open(this.file.file, '_blank')
        },
        populate() {
            this.$nextTick(() => {
                console.log(this.file)
                if(!utils.checkIsUndefined(this.file) && typeof this.file != "string") {
                    this.$refs.file.addFile(this.file.file)
                }
            })
        },
        updateFile(files) {
            this.newFile = files[0]
        },
        confirm() {
            if(utils.checkObjectIsUndefinedOrEmpty(this.newFile)){
                this.$emit('confirm', null)
                return
            }
            this.$emit('confirm', this.newFile.file)
        },
        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>