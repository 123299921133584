<template>
    <panel title="Dettaglio Download" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-secondary" v-if="!isReserved" @click="footer = !footer"><i :class="`fa fa-arrow-down`"></i> Footer: {{ footer ? "SI" : "NO" }}</a>
            <a class="btn btn-primary" @click="changePublished"><i :class="`fa fa-${published ? 'eye' : 'eye-slash'}`"></i> Pubblicato: {{ published ? "SI" : "NO" }}</a>
            <a class="btn btn-danger" v-if="id" @click="deleteDownload"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-4" >
                <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Attuale</label>
                <img :src="coverImage" class="pointer mt-2" @click="utils.download(coverImage)" width="100%">
            </div>
            <div class="col-md-7 col-lg-8 col-xl-8">
                <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Copertina</label>
                <file-pond
                    class="mt-2" :maxFileSize="constants.MAX_FILE_SIZE"
                    labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                    name="test"
                    ref="coverImage"
                    label-idle="Clicca qui o trascina il file"
                    v-bind:allow-multiple="false"
                    :accepted-file-types="constants.ACCEPTED_FILE_TYPES"
                    v-on:updatefiles="updateCoverImage"
                    />
            </div>
        </div>
        <div class="row mt-4">
            <!-- <div class="col-md-5 col-lg-4 col-xl-4" v-if="typeof file === 'string'">
                <label class="form-check-label" for="flexSwitchCheckDefault">File Attuale</label>
                <button class="btn btn-primary d-block w-100 mt-4" @click="utils.download(file)"><i :class="`fa fa-eye`"></i> Visualizza</button>
            </div>
            <div :class="[typeof file === 'string' ? 'col-md-7 col-lg-8 col-xl-8' : 'col-md-12']">
                <label class="form-check-label" for="flexSwitchCheckDefault">File</label>
                <file-pond
                    :maxFileSize="constants.MAX_FILE_SIZE_BIG"
                    labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                    class="mt-2"
                    name="test"
                    ref="file"
                    label-idle="Clicca qui o trascina il file"
                    v-bind:allow-multiple="false"
                    v-on:updatefiles="updateFile"
                    />
            </div> -->
            <div class="form-group col-md-4 my-3">
                <label>Tag</label>
                <div class="input-group">
                    <multiselect v-model="tag" :options="constants.TAG_INPUT" :searchable="false" :multiple="true" :close-on-select="false" :show-labels="false" placeholder="Scegli un tag"></multiselect>
                    <!-- <input @blur="setValue($event)" :value="tag" @click.stop class="form-control" list="datalistTag" id="exampleDataList" placeholder="Tag">
                    <datalist id="datalistTag" @click.stop placeholder="Titolo">
                        <option v-for="tag in constants.TAG_INPUT" :key="tag" :value="tag">
                            {{ tag }}</option>
                    </datalist> -->
                </div>
            </div>
            <div class="form-group col-md-4 my-3">
                <label>Ordine</label>
                <div class="input-group">
                    <input v-model="weight" class="form-control" placeholder="Ordine">
                </div>
            </div>
        </div>
        <lang-tabs ref="langTab" :availableLangs="availableLangs">
            <template v-slot:content="props">
                <div class="form-group mb-3">
                    <label>Titolo</label>
                    <input type="text" v-model="title[props.selectedLang]" class="form-control" placeholder="Titolo">
                </div>
                <div class="form-group mb-3">
                    <label>Sottotitolo</label>
                    <input type="text" v-model="summary[props.selectedLang]" class="form-control" placeholder="Sottotitolo">
                </div>
                <div class="row">
                    <div class="col-md-5 col-lg-4 col-xl-4" v-if="typeof $data[`fetch_file_${props.selectedLang}`] === 'string'">
                        <label class="form-check-label" for="flexSwitchCheckDefault">File Attuale</label>
                        <button class="btn btn-primary d-block w-100 mt-4" @click="utils.download($data[`fetch_file_${props.selectedLang}`])"><i :class="`fa fa-eye`"></i> Visualizza</button>
                    </div>
                    <div :class="[typeof $data[`fetch_file_${props.selectedLang}`] === 'string' ? 'col-md-7 col-lg-8 col-xl-8' : 'col-md-12']">
                        <label class="form-check-label" for="flexSwitchCheckDefault">File</label>
                        <file-pond
                            :maxFileSize="constants.MAX_FILE_SIZE_BIG"
                            labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                            class="mt-2"
                            name="test"
                            ref="file"
                            label-idle="Clicca qui o trascina il file"
                            :files="$data[`file_${props.selectedLang}`]"
                            v-bind:allow-multiple="false"
                            v-on:updatefiles="files => updateFile(files, [props.selectedLang])"
                            />
                    </div>
                </div>
            </template>
        </lang-tabs>
        <div v-if="isReserved" class="mt-3">
            <datatable-user ref="users" :users="users" :selectable="true"/>
        </div>
    </panel>
</template>
<script>
import DownloadService from '../api/DownloadService.js'
import utils from '../config/utils.js'
import swal from '../config/swal.js'
import loader from '../config/loader.js'
import constants from '../config/constants.js'
export default {
    data() {
        return {
            id: "",
            tag: null,
            title: utils.getEmptyObjectLang(),
            summary: utils.getEmptyObjectLang(),
            published: true,
            coverImage: null,
            newCoverImage: null,
            file: {},
            file_it: [],
            file_en: [],
            file_fr: [],
            file_de: [],
            file_es: [],
            fetch_file_it: [],
            fetch_file_en: [],
            fetch_file_fr: [],
            fetch_file_de: [],
            fetch_file_es: [],
            utils: utils,
            reserved: false,
            users: [],
            availableLangs: [],
            isSaved: false,
            oldData: "",
            footer: false,
            constants,
            weight: 0
        }
    },
    async mounted() {
        loader.showLoader()
        if(this.isReserved) {
            this.reserved = true
        }
        if(this.isEdit) {
            this.id = this.$route.params.id
            if(this.isReserved) {
                await DownloadService.getDownloadReserved(this.id)
                    .then(resp => {
                        this.title = resp.data.title
                        this.summary = resp.data.summary
                        this.published = resp.data.published
                        this.coverImage = resp.data.cover_image
                        this.tag = utils.checkIsUndefinedOrEmpty(resp.data.tag) ? null : resp.data.tag.split(',')
                        this.users = resp.data.users
                        this.weight = resp.data.weight
                        this.footer = resp.data.in_footer
                        for(var i in resp.data.files){
                            this.$data[`fetch_file_${i}`] = resp.data.files[i].file
                        }
                        if(!utils.checkIsUndefined(this.title)){
                            for(var key in this.title){
                                this.availableLangs.push(key)
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await DownloadService.getDownload(this.id)
                    .then(resp => {
                        this.title = resp.data.title
                        this.summary = resp.data.summary
                        this.published = resp.data.published
                        this.tag = utils.checkIsUndefinedOrEmpty(resp.data.tag) ? null : resp.data.tag.split(',')
                        this.weight = resp.data.weight
                        this.coverImage = resp.data.cover_image
                        this.footer = resp.data.in_footer
                        for(var i in resp.data.files){
                            this.$data[`fetch_file_${i}`] = resp.data.files[i].file
                        }
                        if(!utils.checkIsUndefined(this.title)){
                            for(var key in this.title){
                                this.availableLangs.push(key)
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            this.oldData = utils.getJsonData(this.$data)
        }
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name.includes("Edit")
        },
        isReserved() {
            return this.$route.name.includes("Reserved")
        },
        selectedUsers() {
            if(this.isReserved) {
                return this.$refs.users.getSelected
            }
            return []
        }
    },
    methods: {
        setValue(event) {
            this.tag = event.target.value
        },
        changePublished() {
            swal.withConfirm(
                'Sei sicuro di voler cambiare la visibilità?', 
                `Confermando ${this.published ? "non" : ""} sarà visibile!`, 
                'Visibilità cambiata. Ricordati di salvare!',
                () => {
                    this.published = !this.published
                })
        },
        updateCoverImage(files) {
            this.newCoverImage = files[0]
        },
        updateFile(files, lang) {
            this[`file_${lang[0]}`] = files
        },
        backRoute(){
            if(this.isReserved) {
                this.$router.push({name: 'ReservedDownloads'})
                return
            }
            this.$router.push({name: 'Downloads'})
        },
        async save(){
            loader.showLoader()
            let params = new FormData()
                if(this.isReserved){
                    this.footer = false
                }
                params.append("reserved", this.reserved)
                params.append("published", this.published)
                params.append("in_footer", this.footer)
                params.append("weight", this.weight)
                if(!utils.checkIsUndefined(this.tag)) {
                    params.append("tag", this.tag)
                }
                if(!utils.checkIsUndefined(this.$refs.langTab.langs)) {
                    var title = {}
                    var summary = {}
                    this.$refs.langTab.langs.forEach(ls => {
                        if(ls.visible){
                            var langValue = ls.value
                            if(utils.checkIsUndefined(this.summary[langValue])){
                                summary[langValue] = ""
                            } else {
                                summary[langValue] = this.summary[langValue]
                            }
                            if(utils.checkIsUndefined(this.title[langValue])){
                                title[langValue] = ""
                            } else {
                                title[langValue] = this.title[langValue]
                            }
                            if(!utils.checkArrayIsUndefinedOrEmpty(this[`file_${langValue}`]) && typeof this[`file_${langValue}`]!= "string"){
                                params.append(`file_${langValue}`, this[`file_${langValue}`][0].file)
                            }
                        }
                    })
                    params.append('summary', JSON.stringify(summary))
                    params.append('title', JSON.stringify(title))
                }
                if(!utils.checkIsUndefinedOrEmpty(this.newCoverImage) && typeof this.newCoverImage != "string"){
                    params.append('cover_image', this.newCoverImage.file)
                }
                // if(!utils.checkIsUndefinedOrEmpty(this.file) && typeof this.file != "string"){
                //     params.append('file', this.file.file)
                // }
            if(this.isEdit) {
                
                this.users.forEach(async user => {
                    var row = utils.findObject(this.selectedUsers, user.user, "id")
                    if(utils.checkIsUndefined(row)) {
                        await DownloadService.deleteDownloadReserved(user.id)
                            .then(resp => console.log(resp))
                            .catch(err => console.log(err))
                    }
                });
                await DownloadService.changeDownload(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.newUser(this.id)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await DownloadService.newDownload(params)
                    .then(resp => {
                        this.newUser(resp.data.id)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        newUser(id) {
            this.selectedUsers.forEach(async (row) => {
                var user = utils.findObject(this.users, row.id, "user")
                if (utils.checkIsUndefined(user)) {
                    var paramUser = new FormData()
                    paramUser.append("user", row.id)
                    paramUser.append("download", id)
                    await DownloadService.newDownloadReserved(paramUser)
                        .then(resp => console.log(resp))
                        .catch(err => console.log(err))
                }
            });
        },
        async deleteDownload() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await DownloadService.deleteDownload(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }

}
</script>