<template>
    <div class="mt-4">
        <b-card no-body>
            <b-card-header header-tag="nav">
                <b-nav card-header tabs>
                    <b-nav-item :active="selectedLang === lang.value" @click="selectedLang = lang.value"
                        v-for="lang in langs" :key="lang.value"
                        v-show="lang.visible"><span
                            :class="`flag-icon flag-icon-${lang.icon}`"></span> {{ lang.title }}</b-nav-item>
                        <b-nav-item @click="showModal">
                            <span class="fa fa-edit" style="color: #348fe2;"></span> Seleziona Lingue</b-nav-item>
                </b-nav>
            </b-card-header>

            <b-card-body>
                <slot name="content" v-bind:selectedLang="selectedLang">
                </slot>
            </b-card-body>
        </b-card>
        <languages-modal v-if="show" ref="modal" :langs="langs" @confirm="confirmLang" @cancel="cancelLang" />
    </div>
</template>

<script>
import constants from '../../config/constants.js'
import utils from '../../config/utils.js'
export default {
    props: ['availableLangs'],
    data() {
        return {
            show: false,
            selectedLang: "it",
            langs: constants.LANGS_TABS
        }
    },
    methods: {
        showModal() {
            this.show = true
            // this.$refs.modal.show = true
        },
        cancelLang() {
            this.show = false
        },
        confirmLang(selectedLangs){
            this.cancelLang()
            this.langs = Object.assign([], selectedLangs)
        }
    },
    watch: {
        availableLangs() {
            var arrayIndex = []
            this.availableLangs.forEach(lang => {
                var index = utils.findIndexWithAttr(this.langs, 'value', lang)
              console.log(index)
                if(utils.checkIsUndefinedOrEmpty(index)){
                    return
                }
                arrayIndex.push(index)
            });
            for(var i in this.langs){
                if(arrayIndex.includes(parseInt(i))){
                  this.langs[i].visible = true
                    continue;
                }
                this.langs[i].visible = false
            }
        }
    }
}
</script>