export default {
    submission_date: "Data di compilazione",
    available_from_date: "Disponibilità per assunzione dal",
    first_name: "Nome",
    last_name: "Cognome",
    birth_city: "Luogo di nascita",
    birth_province: "Provincia di nascita",
    birth_country: "Nazione di nascita",
    birth_date: "Data di nascita",
    residence_city: "Luogo di residenza",
    residence_postal_code: "CAP",
    residence_province: "Provincia",
    residence_address: "Via o piazza",
    residence_address_number: "N Civico",
    residence_country: "Nazionalità",
    phone_number: "Telefono",
    email: "E-mail",
    family_father: "Padre",
    family_mother: "Madre",
    family_partner: "Coniuge/compagno",
    family_sons: "Figlio",
    study_primary_school: "Licenza elementare",
    study_middle_school: "Licenza media",
    study_diploma: "Diploma",
    study_degree: "Laurea",
    study_other: "Altro",
    work_administration_control: "Amministrazione e controllo",
    work_sales_italy: "Commerciale Italia",
    work_sales_foreign: "Commerciale Estero",
    work_warehouse_shipping: "Magazzino e spedizioni",
    work_packaging: "Imballaggio",
    work_tooling: "Attrezzeria",
    work_production: "Produzione",
    work_technical_office: "Ufficio tecnico",
    work_other: "Altro",
    health_suitability_any_job: "Idoneità a qualsiasi mansione",
    generalNews: "Notizie generali",
    driver_own_vehicle: "Munito di proprio mezzo",
    driver_license_type: "Patente di guida e tipo",
    detailLastTitle: "Dettagli ultimo titolo di studio conseguito",
    study_last_year: "Anno di conseguimento",
    study_last_institute: "Istituto",
    study_last_city: "Città",
    study_last_province: "Provincia",
    study_last_score: "Votazione finale",
    knowledgeForeignLanguages: "Conoscenza lingue straniere",
    home: "Home",
    back: "Indietro",
    next: "Successivo",
    comprehension_listen: "COMPRENSIONE - Ascolto",
    comprehension_read: "COMPRENSIONE - Lettura",
    verbal_interaction: "PARLATO - Interazione",
    verbal_oral: "PARLATO - Produzione Orale",
    write: "SCRITTO",
    levelsLanguage: "Livelli: A1/2: Elementare - B1/2: Intermedio - C1/2 Avanzato",
    commonEuropean: "Quadro comune europeo di riferimento per la conoscenza delle lingue.",
    knowledges: "Conoscenze",
    nothing: "Nessuna",
    decent: "Discreta",
    great: "Ottima",
    technicalDesign: "Disegno Tecnico",
    informatics: "Informatiche",
    metalworkers: "Metalmeccaniche Generali",
    drawing_read: "Sono in grado di leggere ed interpretare un disegno",
    drawing_draw: "Sono in grado di disegnare",
    drawing_cad: "Sono in grado di disegnare con l'ausilio di mezzi informatici (CAD/CAM)",
    windows: "Sistemi Operativi Windows",
    linux: "Sistemi Operativi Unix",
    sap: "SAP",
    docs: "Applicativi per Scrittura",
    calc: "Fogli di Calcolo",
    other: "Altro",
    other_name: "Nome altro",
    forklifts: "Utilizzo di carelli elevatori",
    welding: "Saldatura manuale",
    mechanical_presses: "Utilizzo di presse meccaniche",
    molds_on_press: "Montaggio stampi su pressa",
    mold_construction: "Costruzione/riparazione stampi",
    manual_control: "Utilizzo di macchine utensili a controllo manuale",
    cnc: "Programmazione di macchine utensili automatiche (CNC)",
    robot: "Programmazione di robot",
    competences_technical_drawing_read: "Sono in grado di leggere ed interpretare un disegno",
    competences_technical_drawing_draw: "Sono in grado di disegnare",
    competences_technical_drawing_cad: "Sono in grado di disegnare con l'ausilio di mezzi informatici (CAD/CAM)",
    competences_it_windows: "Sistemi Operativi Windows",
    competences_it_linux: "Sistemi Operativi Unix",
    competences_it_sap: "SAP",
    competences_it_docs: "Applicativi per Scrittura",
    competences_it_calc: "Fogli di Calcolo",
    competences_metalworkers_forklifts: "Utilizzo di carelli elevatori",
    competences_metalworkers_welding: "Saldatura manuale",
    competences_metalworkers_mechanical_presses: "Utilizzo di presse meccaniche",
    competences_metalworkers_molds_on_press: "Montaggio stampi su pressa",
    competences_metalworkers_mold_construction: "Costruzione/riparazione stampi",
    competences_metalworkers_manual_control: "Utilizzo di macchine utensili a controllo manuale",
    competences_metalworkers_cnc: "Programmazione di macchine utensili automatiche (CNC)",
    competences_metalworkers_robot: "Programmazione di robot",
    experiences: "Esperienze",
    from: "Dal",
    to: "Al",
    company: "Azienda/Ente",
    task: "Mansione",
    italy: "Italia",
    abroad: "Estero",
    businessTrips: "Viaggi di lavoro (presso clienti o fornitori), Fiere e Manifestazioni",
    oneDay: "1 giorno",
    fiveDays: "5 giorni",
    beyond: "Oltre",
    contract: "Contratto",
    contractualExpectations: "Aspettative Contrattuali",
    contract_part_time: "Part-Time",
    contract_apprentice: "Contratto di apprendistato",
    contract_fixed_term: "Contratto a tempo determinato",
    contract_permanent: "Contratto a tempo indeterminato",
    contract_collaboration: "Collaborazione/consulenze",
    contract_month_retribution: "Minimo Contrattuale",
    contract_month_retribution_type: "Retribuzione Mensile Desiderata",
    contract_month_payouts: "Numero di Mensilità",
    contract_minimum: "Minimo Contrattuale",
    gross: "Lorda",
    net: "Netta",
    contract_last_salary: "Ultima Retribuzione Netta",
    note: "Note di Interesse",
    file: "Allegato",
    experience: "Esperienze",
    lang_it_comprehension_listen: "ITALIANO - comprensione ascolto",
    lang_it_comprehension_read: "ITALIANO - comprensione lettura",
    lang_it_verbal_interaction: "ITALIANO - interazione verbale",
    lang_it_verbal_oral: "ITALIANO - produzione orale",
    lang_it_write: "ITALIANO - scrittura",
    lang_en_comprehension_listen: "INGLESE - comprensione ascolto",
    lang_en_comprehension_read: "INGLESE - comprensione lettura",
    lang_en_verbal_interaction: "INGLESE - interazione verbale",
    lang_en_verbal_oral: "INGLESE - produzione orale",
    lang_en_write: "INGLESE - scrittura",
    lang_fr_comprehension_listen: "FRANCESE - comprensione ascolto",
    lang_fr_comprehension_read: "FRANCESE - comprensione lettura",
    lang_fr_verbal_interaction: "FRANCESE - interazione verbale",
    lang_fr_verbal_oral: "FRANCESE - produzione orale",
    lang_fr_write: "FRANCESE - scrittura",
    lang_de_comprehension_listen: "TEDESCO - comprensione ascolto",
    lang_de_comprehension_read: "TEDESCO - comprensione lettura",
    lang_de_verbal_interaction: "TEDESCO - interazione verbale",
    lang_de_verbal_oral: "TEDESCO - produzione orale",
    lang_de_write: "TEDESCO - scrittura",
    lang_es_comprehension_listen: "SPAGNOLO - comprensione ascolto",
    lang_es_comprehension_read: "SPAGNOLO - comprensione lettura",
    lang_es_verbal_interaction: "SPAGNOLO - interazione verbale",
    lang_es_verbal_oral: "SPAGNOLO - produzione orale",
    lang_es_write: "SPAGNOLO - scrittura",
    lang_other_notes: "ALTRO - note",
    travel_italy: "Viaggio di lavoro in Italia", 
    exhibitions_italy: "Fiere e Manifestazioni in Italia", 
    exhibitions_foreign: "Fiere e Manifestazioni all'estero",
    travel_foreign: "Viaggio di lavoro all'estero",
    send_to_user_email: "Inoltra i dati inseriti al mio indirizzo E-mail",
    privacy_consent: "È necessario aprire il presente link affinché la casella diventi selezionabile. Ho letto l'informativa dei dati personali e presto il consenso.",
    competences_value: [
        {
            id: "",
            value: ""
        },
        {
            id: "n",
            value: "Nessuna"
        },
        {
            id: "d",
            value: "Discreta"
        },
        {
            id: "g",
            value: "Ottima"
        }
    ],
    travel_value: [
        {
            id: "",
            value: ""
        },
        {
            id: "n",
            value: "Nessuna"
        },
        {
            id: "o",
            value: "1 giorno"
        },
        {
            id: "f",
            value: "5 giorni"
        },
        {
            id: "b",
            value: "Oltre"
        }
    ],
    contract_value: [
        {
            id: "",
            value: ""
        },
        {
            id: "g",
            value: "Lorda"
        },
        {
            id: "n",
            value: "Netta"
        }
    ]
}