<template>
    <panel title="Dettaglio Listino Prezzi" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-danger" v-if="id" @click="deleteMaterial"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="col-md-5">
                <label>Utente</label>
                <select type="text" v-model="user" class="form-select"
                    placeholder="Utente">
                    <option v-for="us in users" :key="us.id" :value="us.id">{{ us.username }}</option>
                </select>
            </div>
        </div>
        <lang-tabs ref="langTab" :availableLangs="availableLangs">
            <template v-slot:content="props">
                <div class="form-group">
                    <label>Titolo</label>
                    <input type="text" v-model="name[props.selectedLang]" class="form-control" placeholder="Titolo">
                </div>
                <div class="row">
                    <div class="col-md-5 col-lg-4 col-xl-4" v-if="typeof $data[`fetch_file_${props.selectedLang}`] === 'string'">
                        <label class="form-check-label" for="flexSwitchCheckDefault">File Attuale</label>
                        <button class="btn btn-primary d-block w-100 mt-4" @click="utils.download($data[`fetch_file_${props.selectedLang}`])"><i :class="`fa fa-eye`"></i> Visualizza</button>
                    </div>
                    <div :class="[typeof $data[`fetch_file_${props.selectedLang}`] === 'string' ? 'col-md-7 col-lg-8 col-xl-8' : 'col-md-12']">
                        <label class="form-check-label" for="flexSwitchCheckDefault">File</label>
                        <file-pond
                            :maxFileSize="constants.MAX_FILE_SIZE_BIG"
                            labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                            class="mt-2"
                            name="test"
                            ref="file"
                            label-idle="Clicca qui o trascina il file"
                            :files="$data[`file_${props.selectedLang}`]"
                            v-bind:allow-multiple="false"
                            v-on:updatefiles="files => updateFile(files, [props.selectedLang])"
                            />
                    </div>
                </div>
            </template>
        </lang-tabs>
    </panel>
</template>
<script>
import PriceListService from '../api/PriceListService.js'
import UserService from '../api/UserService.js'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import swal from '../config/swal.js'
import utils from '../config/utils.js'
export default {
    data() {
        return {
            id: "",
            name: utils.getEmptyObjectLang(),
            utils: utils,
            isSaved: false,
            oldData: "",
            langs: constants.LANGS_TABS,
            weight: 0,
            constants,
            availableLangs: [],
            file: {},
            file_it: [],
            file_en: [],
            file_fr: [],
            file_de: [],
            file_es: [],
            fetch_file_it: [],
            fetch_file_en: [],
            fetch_file_fr: [],
            fetch_file_de: [],
            fetch_file_es: [],
            user: "",
            users: []
        }
    },
    async mounted() {
        loader.showLoader()
        await UserService.fetchUsers()
            .then(resp => {
                this.users = resp.data.data
            })
            .catch(err => {
                console.log(err)
            })
        if(this.isEdit) {
            this.id = this.$route.params.id
            await PriceListService.getPriceList(this.id)
                .then(resp => {
                    this.name = resp.data.name
                    this.file = resp.data.file
                    this.user = resp.data.user.id
                    for(var i in resp.data.files){
                        this.$data[`fetch_file_${i}`] = resp.data.files[i].file
                    }
                    if(!utils.checkIsUndefined(this.name)){
                        for(var key in this.title){
                            this.availableLangs.push(key)
                        }
                    }
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditPriceList"
        }
    },
    methods: {
        backRoute(){
            this.$router.push({name: 'PriceList'})
        },
        async save(){
            loader.showLoader()
            let params = new FormData()
            params.append("user", this.user)
            if(!utils.checkIsUndefined(this.$refs.langTab.langs)) {
                var name = {}
                this.$refs.langTab.langs.forEach(ls => {
                    if(ls.visible){
                        var langValue = ls.value
                        if(utils.checkIsUndefined(this.name[langValue])){
                            name[langValue] = ""
                        } else {
                            name[langValue] = this.name[langValue]
                        }
                        if(!utils.checkArrayIsUndefinedOrEmpty(this[`file_${langValue}`]) && typeof this[`file_${langValue}`]!= "string"){
                            params.append(`file_${langValue}`, this[`file_${langValue}`][0].file)
                        }
                    }
                })
                params.append('name', JSON.stringify(name))
            }
            if(this.isEdit) {
                await PriceListService.changePriceList(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await PriceListService.newPriceList(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        updateFile(files, lang) {
            this[`file_${lang[0]}`] = files
        },
        async deleteMaterial() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await PriceListService.deletePriceList(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }


}
</script>