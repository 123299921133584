<template>
    <panel title="Dettaglio Utente" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <!-- <a class="btn btn-primary" @click="changeActive"><i :class="`fa fa-${active ? 'eye' : 'eye-slash'}`"></i> Attivo: {{ active ? "SI" : "NO" }}</a> -->
            <a class="btn btn-danger" v-if="isEditUser" @click="deleteUser"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-danger" v-if="isRequest" @click="discardRequest"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <!-- <div class="col-md-3">
                <div class="form-group">
                    <label>Nome</label>
                    <input :disabled="isRequest" type="text" v-model="firstName" class="form-control" placeholder="Nome">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Cognome</label>
                    <input :disabled="isRequest" type="text" v-model="lastName" class="form-control" placeholder="Cognome">
                </div>
            </div> -->
            <div class="col-md-3">
                <div class="form-group">
                    <label>Azienda</label>
                    <input :disabled="isRequest" type="text" v-model="company" class="form-control" placeholder="Azienda">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Email</label>
                    <input :disabled="isRequest" type="text" v-model="email" class="form-control" placeholder="Email">
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" v-model="password" class="form-control" placeholder="Password">
                </div>
            </div> -->
            <div class="col-md-3">
                <div class="form-group">
                    <label>Nazione</label>
                    <input :disabled="isRequest" type="text" v-model="country" class="form-control" placeholder="Nazione">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Via o piazza</label>
                    <input :disabled="isRequest" type="text" v-model="address" class="form-control" placeholder="Via o piazza ">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Città</label>
                    <input :disabled="isRequest" type="text" v-model="city" class="form-control" placeholder="Città">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>CAP</label>
                    <input :disabled="isRequest" type="text" v-model="postalCode" class="form-control" placeholder="CAP">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Telefono</label>
                    <input :disabled="isRequest" type="text" v-model="telephone" class="form-control" placeholder="Telefono">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Fax</label>
                    <input :disabled="isRequest" type="text" v-model="fax" class="form-control" placeholder="Fax">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Profilo</label>
                    <select :disabled="isRequest" type="text" v-model="profile" class="form-select" placeholder="Profilo">
                        <option value=""></option>
                        <option v-for="profile in constants.PROFILE_TABS" :key="profile.value" :value="profile.value">{{ profile.title }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Lingua</label>
                    <select :disabled="isRequest" type="text" v-model="language" class="form-select" placeholder="Lingua">
                        <!-- <option value=""></option> -->
                        <option v-for="lang in constants.LANGS_TABS" :key="lang.value" :value="lang.value">{{ lang.title }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Nome File</label>
                    <input :disabled="isRequest" type="text" v-model="filename" class="form-control" placeholder="Nome File">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label>Note</label>
                    <textarea :disabled="isRequest" type="text" v-model="note" class="form-control" placeholder="Note" rows="10"/>
                </div>
            </div>
        </div>
        <div v-if="isRequest" class="mt-3">
            <h2>Gestione Richiesta</h2>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Stato</label>
                        <select v-model="state" class="form-select" placeholder="Stato">
                            <option v-for="s in constants.STATE_REQUEST" :key="s.value" :value="s.value">{{ s.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Note Interne</label>
                        <textarea type="text" v-model="notes" class="form-control" placeholder="Note" rows="10"/>
                    </div>
                </div>
            </div>
        </div>
    </panel>
</template>

<script>
import RegistrationRequestService from '../api/RegistrationRequestService'
import UserService from '../api/UserService'
import constants from '../config/constants'
import loader from '../config/loader'
import swal from '../config/swal'
import utils from '../config/utils'
export default {
    data() {
        return {
            constants,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            isSaved: false,
            oldData: "",
            active: true,
            address: "",
            city: "",
            filename: "",
            language: "",
            profile: "",
            company: "",
            country: "",
            fax: "",
            note: "",
            postalCode: "",
            telephone: "",
            notes: "",
            state: null
        }
    },
    async mounted() {
        loader.showLoader()
        if(this.isEditUser) {
            await UserService.getUser(this.id)
                .then(resp => {
                    this.setUser(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
        } else if (this.isRequest) {
            await RegistrationRequestService.getRegistrationRequest(this.id)
                .then(resp => {
                    this.setUser(resp.data.user)
                    this.notes = resp.data.notes
                    this.state = resp.data.state
                })
                .catch(err => {
                    console.log(err)
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    methods: {
        setUser(data) {
            this.firstName = data.first_name
            this.lastName = data.last_name
            this.filename = data.filename
            this.email = data.email
            this.active = data.is_active
            this.language = data.language
            if(!utils.checkObjectIsUndefinedOrEmpty(data.extra)) {
                this.address = data.extra.address
                this.city = data.extra.city
                this.company = data.extra.company
                this.country = data.extra.country
                this.fax = data.extra.fax
                this.note = data.extra.note
                this.postalCode = data.extra.postalCode
                this.telephone =  data.extra.telephone
                this.profile = utils.checkIsUndefinedOrEmpty(data.extra.profile) ? "" : data.extra.profile
            }
        },
        // changeActive() {
        //     swal.withConfirm(
        //         'Sei sicuro?', 
        //         `Confermando ${this.active ? "non" : ""} sarà attivo!`, 
        //         'Confermato. Ricordati di salvare!',
        //         () => {
        //             this.active = !this.active
        //         })
        // },
        backRoute(){
            if(this.isRequest){
                this.$router.push({name: 'RegistrationRequests'})
                return
            }
            this.$router.push({name: 'Users'})
        },
        async save(){
            loader.showLoader()
            let user = {
                "first_name": this.firstName,
                "last_name": this.lastName,
                "email": this.email,
                "username": this.email,
                "filename": this.filename,
                "language": this.language,
                "is_active": this.active,
                "extra": {
                    address: this.address,
                    city: this.city,
                    company: this.company,
                    country: this.country,
                    fax: this.fax,
                    note: this.note,
                    postalCode: this.postalCode,
                    telephone: this.telephone,
                    profile: this.profile
                }
            }
                // params.append("first_name", this.firstName)
                // params.append("last_name", this.lastName)
                // params.append("email", this.email)
                // params.append("username", this.email)
                // params.append("is_active", this.active)
                // if(!utils.checkIsUndefinedOrEmpty(this.password)) {
                //     params.append("password", this.password)
                // }
            if(this.isEditUser) {
                await UserService.changeUser(this.id, user)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if(this.isRequest) {
                var params = {
                    user: user,
                    notes: this.notes,
                    state: this.state
                }
                if(this.state === 'A') {
                    swal.withConfirm("Attenzione",
                    "Se “accetti la richiesta” verà inviata in automatico la mail al cliente. Vuoi proseguire?",
                    'Richiesta accettata!',
                    async () => {
                        await RegistrationRequestService.changeRegistrationRequest(this.id, params)
                            .then(() => {
                                this.oldData = utils.getJsonData(this.$data)
                                this.isSaved = true
                                this.backRoute()
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })
                } else {
                    await RegistrationRequestService.changeRegistrationRequest(this.id, params)
                        .then(() => {
                            this.oldData = utils.getJsonData(this.$data)
                            this.isSaved = true
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
               
            } else {
                await UserService.newUser(user)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteUser() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await UserService.deleteUser(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        },
        async discardRequest() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `L'utente verrà disabilitato!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await RegistrationRequestService.discardRegistrationRequest(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        isEditUser() {
            return this.$route.name === "EditUser"
        },
        isRequest() {
            return this.$route.name === "EditRegistrationRequests"
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }
}
</script>