<template>
    <panel title="Traduzioni">
        <template v-slot:buttons>
            <a class="btn btn-success" @click="newTranslation"><i class="fa fa-plus"></i> Nuova Traduzione</a>
        </template>
        <datatable :columns="columns" :rows="rows" @edit="edit" @fetchData="fetchData" :recordsTotal="recordsTotal" />
    </panel>
</template>

<script>
import TranslationService from '../api/TranslationService.js'
import constants from '../config/constants.js';
import loader from '../config/loader.js';
import utils from '../config/utils.js';
export default {
    data() {
        return {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'Chiave',
                    field: 'key',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Chiave"
                    },
                    index: 1
                },
                {
                    label: 'Valore',
                    field: 'value',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Valore"
                    },
                    index: 2
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false
                }
            ],
            rows: [],
            recordsTotal: 0,
        };
    },
    async mounted() {
        if(utils.checkArrayIsUndefinedOrEmpty(utils.getLocalStorageFilter(this.$route.name))){
            this.fetchData()
        } else {
            utils.getLocalStorageFilter(this.$route.name).forEach(element => {
                if(element.type === constants.SEARCH_KEY) {
                    this.$set(this.columns[element.index].filterOptions, "filterValue", element.value)
                }
            });
        }
    },
    methods: {
        async fetchData(stringReceived) {
            loader.showLoader()
            var string = utils.getStringFilter(this.columns)
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
            } else {
                string += stringReceived
            }
            await TranslationService.fetchTranslations(string)
            .then(resp => {
                console.log(resp)
                this.rows = resp.data.data
                this.recordsTotal = resp.data.recordsFiltered
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
        },
        newTranslation() {
            this.$router.push({name: "NewTranslation"})
        },
        edit(id) {
            this.$router.push({name: "EditTranslation", params: {id: id}})
        }
    }
}
</script>