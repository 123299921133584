<template>
    <panel title="Dettaglio Profilo">
         <template v-slot:buttons>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Nome</label>
                    <input type="text" v-model="firstName" class="form-control" placeholder="Nome">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Cognome</label>
                    <input type="text" v-model="lastName" class="form-control" placeholder="Cognome">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" v-model="email" class="form-control" placeholder="Email">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" v-model="password" class="form-control" placeholder="Password">
                </div>
            </div>
        </div>
    </panel>
</template>

<script>
// import UserService from '../api/UserService'
import loader from '../config/loader'
import swal from '../config/swal'
import utils from '../config/utils'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            isSaved: false,
            oldData: "",
            active: true
        }
    },
    async mounted() {
        loader.showLoader()
        this.firstName = this.user.first_name
        this.lastName = this.user.last_name
        this.email = this.user.email
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        // backRoute(){
        //     this.$router.push({name: 'Users'})
        // },
        async save(){
            loader.showLoader()
            // let params = new FormData()
            //     params.append("first_name", this.firstName)
            //     params.append("last_name", this.lastName)
            //     params.append("email", this.email)
            //     params.append("username", this.email)
            //     params.append("is_active", this.active)
            //     if(!utils.checkIsUndefinedOrEmpty(this.password)) {
            //         params.append("password", this.password)
            //     }
            // if(this.isEdit) {
            //     await UserService.changeUser(this.id, params)
            //         .then(resp => {
            //             console.log(resp)
            //             this.oldData = utils.getJsonData(this.$data)
            //             this.isSaved = true
            //             this.backRoute()
            //         })
            //         .catch(err => {
            //             console.log(err)
            //         })
            // } else {
            //     await UserService.newUser(params)
            //         .then(resp => {
            //             console.log(resp)
            //             this.oldData = utils.getJsonData(this.$data)
            //             this.isSaved = true
            //             this.backRoute()
            //         })
            //         .catch(err => {
            //             console.log(err)
            //         })
            // }
            loader.hideLoader()
        },
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }
}
</script>