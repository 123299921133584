export default {
    CATALOG_DOWNLOAD_ARTICLE_PDF: 'Download PDF Articolo',
    CATALOG_DOWNLOAD_ARTICLE_COMPONENT_PDF: 'Download PDF Tavola Tecnica',
    CATALOG_VIEW_ARTICLE: 'Vista Articolo',
    CATALOG_VIEW_ARTICLE_IMAGE: 'Vista Immagine Articolo',
    CATALOG_SEARCH: 'Ricerca Prodotti',
    CATALOG_DOWNLOAD_RESERVED_CATALOG: 'Download Catalogo Riservato',
    DOWNLOAD_PRICE_LIST: 'Download Listino Prezzi',
    DOWNLOAD_DOWNLOAD: 'Download Download',
    DOWNLOAD_RESERVED_DOWNLOAD: 'Download Download Riservati',
}