<template>
    <panel :title="title.toUpperCase()" class="panel__border">
        <div v-for="(key, index) in keys" :key="key">
            <div v-if="key === 'experience'" class="form-group">
                <div v-for="(exp, keyExp) in data[key]" :key="exp + keyExp">
                    <!-- <label>{{ keyExp}}:</label><br/> -->
                    <div class="card mb-3 p-3">
                        <div class="mb-3">{{ curriculum_keys["from"] }}: <strong class="ms-3">{{ getDate(exp.from) }}</strong></div>
                        <div class="mb-3">{{ curriculum_keys["to"] }}: <strong class="ms-3">{{ getDate(exp.to) }}</strong></div>
                        <div class="mb-3">{{ curriculum_keys["task"] }}: <strong class="ms-3">{{ exp.task }}</strong></div>
                        <div class="mb-3">{{ curriculum_keys["company"] }}: <strong class="ms-3">{{ exp.company }}</strong></div>
                    </div>
                </div>
            </div>
            <div v-else-if="key === 'lang_other_notes'">
                {{ data[key] }}
            </div>
            <div v-else-if="key === 'files'">
                <button v-for="(file) in data[key]" :key="file.id" class="btn btn-primary mr-3" @click="utils.download(file.file)"><i :class="`fa fa-eye`"></i> Visualizza</button>
            </div>
            <div v-else-if="key.includes('lang') || key.includes('competences')" class="form-group">
                
                <p :class="keys.length > 1 && index < keys.length - 1  ? '' : 'mb-0'"><span class="me-2" v-if="keys.length > 1">
                    {{ curriculum_keys[getSplitKey(key)] }}: </span><strong>{{getValue(key, data[key])}}</strong>
                </p>
                <!-- <div v-for="(exp, keyExp) in data[key]" :key="exp + keyExp">
                    <div class="card p-3">
                        <div v-for="(param, keyParam) in exp" :key="param + keyParam" :class="keyParam === 'company'  ? 'mb-0' : 'mb-3'">
                            {{ curriculum_keys[keyParam] }}: <strong class="ms-3">{{ param }}</strong></div>
                    </div>
                </div> -->
            </div>
            <div v-else-if="typeof data[key] === 'boolean'" class="form-group">
                <p :class="keys.length > 1 && index < keys.length - 1 ? '' : 'mb-0'"><span class="me-2" v-if="keys.length > 1">{{ curriculum_keys[key] }}: </span><strong class="ms-2">{{ data[key] ? "SI" : "NO"}}</strong></p>
            </div>
            <div v-else-if="utils.isValidHttpUrl(data[key])" class="form-group">
                <button class="btn btn-primary" @click="utils.download(data[key])"><i :class="`fa fa-eye`"></i> Visualizza</button>
            </div>
            <div v-else-if="['submission_date', 'available_from_date'].includes(key)" class="form-group">
                <p><strong>{{getDate(getValue(key, data[key]))}}</strong></p>
            </div>
            <div v-else class="form-group">
                <p :class="keys.length > 1 && index < keys.length - 1  ? '' : 'mb-0'"><span class="me-2" v-if="keys.length > 1">{{ curriculum_keys[key] }}: </span><strong>{{getValue(key, data[key])}}</strong></p>
            </div>
        </div>
    </panel>
</template>

<script>
import utils from '../../config/utils'
import curriculum_keys from '../../config/curriculum_keys'
export default {
    props: ['keys', 'data', 'title'],
    data() {
        return {
            curriculum_keys,
            utils
        }
    },
    methods: {
        getDate(date) {
            if(utils.checkIsUndefinedOrEmpty(date)){
                return ""
            }
            let newDate = date
            if(date.includes('T')){
                newDate = date.split('T')[0]
            }
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(newDate).toLocaleDateString('it-IT', options)
        },
        getSplitKey(key) {
            var string = key.split("_")
            if(string.length === 3) {
                return string[2]
            }
            if(string.length === 4) {
                return `${string[2]}_${string[3]}`
            }
            return `${string[2]}_${string[3]}_${string[4]}`
        },
        getValue(key, value) {
            console.log(key, value)
            var element = null
            if(key.includes("competences")){
                element = utils.findObject(curriculum_keys.competences_value, value, 'id')
                if(!utils.checkObjectIsUndefinedOrEmpty(element)){
                    return element.value
                }
            }
            if(key.includes("travel") || key.includes("exhibitions")){
                element = utils.findObject(curriculum_keys.travel_value, value, 'id')
                if(!utils.checkObjectIsUndefinedOrEmpty(element)){
                    return element.value
                }
            }
            if(key === "contract_month_retribution_type"){
                element = utils.findObject(curriculum_keys.contract_value, value, 'id')
                if(!utils.checkObjectIsUndefinedOrEmpty(element)){
                    return element.value
                }
            }
            return value
        }
    }
}
</script>