<template>
    <panel title="Dettaglio Curriculum" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-danger" v-if="id" @click="deleteCurriculum"><i class="fa fa-trash"></i> Elimina</a>
            <!-- <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a> -->
        </template>
        <h2>Curriculum n° {{ data.sequential_number }}</h2>
        <div class="row">
            <div class="col-md-6">
                <panel-curriculum title="DATA DI COMPILAZIONE" :data="data" :keys="curriculum.SUBMISSION_DATE" />
                <panel-curriculum title="DATI ANAGRAFICI" :data="data" :keys="curriculum.PERSONAL_DATA" />
                <panel-curriculum title="nucleo familiare" :data="data" :keys="curriculum.FAMILY" />
                <panel-curriculum title="TITOLO DI STUDIO" :data="data" :keys="curriculum.STUDY" />
            </div>
            <div class="col-md-6">
                <panel-curriculum title="DISPONIBILITÀ PER ASSUNZIONE DAL" :data="data" :keys="curriculum.AVAILABLE_FROM" />
                <panel-curriculum title="RICHIESTA SETTORE DI LAVORO" :data="data" :keys="curriculum.WORK" />
                <panel-curriculum title="Idoneità a qualsiasi mansione" :data="data" :keys="curriculum.HEALTH" />
                <panel-curriculum title="NOTIZIE GENERALI" :data="data" :keys="curriculum.DRIVER" />
                <panel-curriculum title="DETTAGLI ULTIMO TITOLO DI STUDIO CONSEGUITO" :data="data" :keys="curriculum.STUDY_LAST" />
            </div>
            <div class="col-md-6">
<!--                <panel-curriculum title="CONOSCENZA ITALIANO" :data="data" :keys="curriculum.LANG_IT" />-->
                <panel-curriculum title="CONOSCENZA FRANCESE" :data="data" :keys="curriculum.LANG_FR" />
                <panel-curriculum title="CONOSCENZA SPAGNOLO" :data="data" :keys="curriculum.LANG_ES" />
                <panel-curriculum title="Competenze tecniche" :data="data" :keys="curriculum.COMPETENCES_TECHNICAL" />
            </div>
            <div class="col-md-6">
                <panel-curriculum title="CONOSCENZA INGLESE" :data="data" :keys="curriculum.LANG_EN" />
                <panel-curriculum title="CONOSCENZA TEDESCO" :data="data" :keys="curriculum.LANG_DE" />
                <panel-curriculum title="CONOSCENZA ALTRO" :data="data" :keys="curriculum.LANG_OT" />
                <panel-curriculum title="Competenze informatiche" :data="data" :keys="curriculum.COMPETENCES_IT" />
                <panel-curriculum title="Competenze metalmeccaniche generali" :data="data" :keys="curriculum.COMPETENCES_METALWORKERS" />
            </div>
            <div class="col-md-12">
                <panel-curriculum title="esperienze" :data="data" :keys="curriculum.EXPERIENCE" />
            </div>
            <div class="col-md-6">
                <panel-curriculum title="viaggio di lavoro in italia" :data="data" :keys="curriculum.TRAVEL_ITALY" />
            </div>
            <div class="col-md-6">
                <panel-curriculum title="viaggio di lavoro all'estero" :data="data" :keys="curriculum.TRAVEL_FOREIGN" />
            </div>
            <div class="col-md-12">
                <panel-curriculum title="contratto" :data="data" :keys="curriculum.CONTRACT" />
            </div>
            <div class="col-md-12">
                <panel-curriculum title="note di interesse" :data="data" :keys="curriculum.NOTE" />
            </div>
            <div class="col-md-6">
                <panel-curriculum title="file" :data="data" :keys="curriculum.FILE" />
            </div>
            <div class="col-md-6">
                <panel-curriculum title="consensi" :data="data" :keys="curriculum.CONSENT" />
            </div>
        </div>
    </panel>
</template>
<script>
import CurriculumService from '../api/CurriculumService.js'
// import MaterialService from '../api/MaterialService.js'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import swal from '../config/swal.js'
import utils from '../config/utils.js'
import curriculum from '../config/curriculum.js'
export default {
    data() {
        return {
            curriculum,
            id: "",
            title: utils.getEmptyObjectLang(),
            utils: utils,
            isSaved: false,
            oldData: "",
            langs: constants.LANGS_TABS,
            weight: 0,
            data: {}
        }
    },
    async mounted() {
        loader.showLoader()
        if(this.isEdit) {
            this.id = this.$route.params.id
            await CurriculumService.getCurriculum(this.id)
                .then(resp => {
                    this.data = resp.data
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditCurriculum"
        }
    },
    methods: {
        backRoute(){
            this.$router.push({name: 'Curriculum'})
        },
        async save(){
            loader.showLoader()
            let params = new FormData()
                // params.append("weight", this.weight)
                // params.append("title", JSON.stringify(this.title))
            if(this.isEdit) {
                await CurriculumService.changeCurriculum(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await CurriculumService.newCurriculum(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteCurriculum() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await CurriculumService.deleteCurriculum(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }


}
</script>