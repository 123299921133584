import Api from './index.js'
import store from '../store/store.js'

export default {
    getDashbordCatalog() {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/dashboard/catalog/')
    },
    getDashbordDownload() {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/dashboard/download/')
    },
    getDashbordNews() {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/dashboard/news/')
    },
    getDashbordUsers() {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/dashboard/users/')
    },
}
