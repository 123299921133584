import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchAudit(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/audit/?format=datatables' + extra)
    },
    fetchAuditActions(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/audit/actions/?format=datatables' + extra)
    },
    getAudit(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/audit/${id}/`)
    },
    changeAudit(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/audit/${id}/`, params)
    },
    newAudit(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/audit/`, params)
    },
    deleteAudit(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/audit/${id}/`)
    },
    exportAudit(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/audit/export/?format=datatables' + extra,{
            responseType: 'blob'
          })
    },
}
