<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1" v-for="(element, index) in data" :key="element.title +  index">
        <b-card-header v-b-toggle="element.title" header-tag="header" class="p-1" role="tab">
          <div class="row mb-1">
            <div class="col-md-8 form-group">
              <label>Titolo</label>
              <select @click.stop type="text" v-model="element.title" class="form-select" placeholder="Tipo">
                <option v-for="t in translations" :key="t.key" :value="t.key">
                  {{ t.key }}
                </option>
              </select>
              <!-- <input @blur="setValue($event, index)" :value="element.title" @click.stop class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Titolo">
              <datalist id="datalistOptions" @click.stop placeholder="Titolo">
                  <option v-for="t in constants.TITLE_INPUT" :key="t.value" :value="t.value">
                      {{ t.title }}</option>
              </datalist> -->
            </div>
            <div class="col-md-2 form-group">
              <label>Tipo</label>
              <select @click.stop type="text" v-model="element.type" class="form-select" placeholder="Tipo">
                <option v-for="t in constants.TYPE_INPUT" :key="t.value" :value="t.value">
                  {{ t.title }}
                </option>
              </select>
            </div>
            <div class="col-md-2 form-group relative">
              <a class="btn btn-success absolute-bottom" @click.stop="addChildren(element.children)"><i
                  class="fa fa-plus"></i></a>
              <a class="btn btn-danger absolute-bottom ml-5" @click="deleteChild(data, index)"><i
                  class="fa fa-trash"></i></a>
            </div>
          </div>
        </b-card-header>
        <b-collapse :id="element.title" visible :accordion="element.title" role="tabpanel">
          <b-card-body>
            <div class="row mb-3" v-for="(child, indexChildren) in element.children" :key="child.id + indexChildren">
              <div class="col-md-9 form-group">
                <label>Titolo</label>
                <input @blur="setValue($event, index, indexChildren)" :value="child.title" @click.stop
                       class="form-control" list="datalistOptionsChildren" id="exampleDataList" placeholder="Titolo">
                <datalist id="datalistOptionsChildren" @click.stop placeholder="Titolo">
                  <option v-for="t in constants.SUBTITLE_INPUT" :key="t" :value="t">
                    {{ t }}
                  </option>
                </datalist>
              </div>
              <div class="col-md-2 form-group">
                <label>Tipo</label>
                <select type="text" v-model="child.type" class="form-select" placeholder="Tipo">
                  <option v-for="t in constants.TYPE_INPUT" :key="t.value" :value="t.value">
                    {{ t.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-1 form-group relative">
                <a class="btn btn-danger absolute-bottom"
                   @click="deleteChild(element.children, indexChildren)"><i
                    class="fa fa-trash"></i></a>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <a class="btn w-100 btn-success mb-3" @click="addElement(data)"><i class="fa fa-plus"></i></a>
  </div>
</template>

<script>
import utils from '../../config/utils.js'
import constants from '../../config/constants.js'
import loader from '../../config/loader.js'
import TranslationService from '../../api/TranslationService.js'

export default {
  props: ["receivedData"],
  data() {
    return {
      constants,
      data: [utils.newTemplate()],
      index: 0,
      translations: [],
      startNew: false
    }
  },

  async mounted() {
    loader.showLoader()
    if (this.$route.name === 'NewTemplate') {
      this.startNew = true
    }
    var string = `&start=0&length=9999`
    await TranslationService.fetchTranslations(string)
        .then(resp => {
          this.translations = resp.data.data.sort((a, b) => {
            return a.key.localeCompare(b.key)
          })
        })
        .catch(err => {
          console.log(err)
        })
    loader.hideLoader()
  },
  methods: {
    setValue(event, index, indexChildren) {
      if (utils.checkIsUndefinedOrEmpty(indexChildren)) {
        this.$set(this.data[index], "title", event.target.value)
        return
      }
      this.$set(this.data[index].children[indexChildren], "title", event.target.value)
    },
    addElement(array) {
      array.push(utils.newTemplate())
    },
    addChildren(array) {
      array.push(utils.newTemplateChildren())
    },
    deleteChild(array, index) {
      array.splice(index, 1)
    },
    getJson() {
      var paramsData = []
      this.data.forEach((element, indexParent) => {
        if (element.children.length === 0) {
          element.children.push(utils.newTemplateChildren())
        }
        element.cols = element.children.length
        element.children.forEach((child, indexChildren) => {
          if (utils.checkIsUndefinedOrEmpty(child.id)) {
            child.id = this.index
            this.index++
          }
          if (utils.checkIsUndefinedOrEmpty(child.columnIndex)) {
            child["columnIndex"] = indexParent + indexChildren
          }
        });
        paramsData.push(element)
      });
      return JSON.stringify(paramsData)
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function () {
        var maxIndex = 0
        if(this.data.length === 0) {
            this.startNew = true
        }
        this.data.forEach(element => {
          element.children.forEach(child => {
            if (utils.checkIsUndefinedOrEmpty(child.id)) {
              return
            }
            if (parseInt(child.id) > maxIndex) {
              maxIndex = parseInt(child.id)
            }
          })
        })
        // this.index = 0
        if(!this.startNew || this.data.length > 1) {
          maxIndex++
        }
        this.data.forEach(element => {
          element.children.forEach(child => {
            if (utils.checkIsUndefinedOrEmpty(child.id)) {
              child.id = maxIndex
              maxIndex++
            }
            this.index = maxIndex
            // if (child.id >= this.index) {
            //   this.index = child.id + 1
            // }
          });
        });
        this.$emit("update", this.data)
      }
    },
    receivedData: {
      deep: true,
      handler: function () {
        if (utils.checkObjectIsUndefinedOrEmpty(this.receivedData) || utils.checkArrayIsUndefinedOrEmpty(this.receivedData)) {
          this.data = [utils.newTemplate()]
          return
        }
        if (JSON.stringify(this.receivedData) === JSON.stringify(this.data)) {
          return
        }
        this.index = 0
        this.data = JSON.parse(JSON.stringify(this.receivedData))
        this.data.forEach(element => {
          element.children.forEach(child => {
            if (child.id >= this.index) {
              this.index = child.id + 1
            }
          });
        });
      }
    }
  }
}
</script>