import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchTranslations(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/translations/?format=datatables' + extra)
    },
    getTranslation(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/translations/${id}/`)
    },
    changeTranslation(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/translations/${id}/`, params)
    },
    newTranslation(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/translations/`, params)
    },
    deleteTranslation(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/translations/${id}/`)
    }
}
