<template>
    <panel title="Dettaglio Novità" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-primary" @click="changePublished"><i :class="`fa fa-${published ? 'eye' : 'eye-slash'}`"></i> Pubblicato: {{ published ? "SI" : "NO" }}</a>
            <a class="btn btn-danger" v-if="id" @click="deleteNews"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-4" >
                <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Attuale</label>
                <img :src="coverImage" class="pointer mt-2" @click="utils.download(coverImage)" width="100%">
            </div>
            <div class="col-md-7 col-lg-8 col-xl-8">
                <label class="form-check-label" for="flexSwitchCheckDefault">Immagine Copertina</label>
                <file-pond
                    class="mt-2" :maxFileSize="constants.MAX_FILE_SIZE"
                    labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                    name="test"
                    ref="coverImage"
                    label-idle="Clicca qui o trascina il file"
                    v-bind:allow-multiple="false"
                    :accepted-file-types="constants.ACCEPTED_FILE_TYPES"
                    v-on:updatefiles="updateCoverImage"
                    />
            </div>
            <div class="form-group col-md-4 my-3">
                <label>Ordine</label>
                <div class="input-group">
                    <input type="text" v-model="weight" class="form-control" placeholder="Ordine">
                </div>
            </div>
        </div>
        <lang-tabs ref="langTab" :availableLangs="availableLangs">
            <template v-slot:content="props">
                <div class="form-group mb-4">
                    <label>Titolo</label>
                    <input type="text" v-model="title[props.selectedLang]" class="form-control" placeholder="Titolo">
                </div>
                <div class="form-group mb-4">
                    <label>Sottotitolo</label>
                    <input type="text" v-model="summary[props.selectedLang]" class="form-control" placeholder="Sottotitolo">
                </div>
                <div class="form-group mb-4">
                    <label>Testo</label>
                    <vue-editor v-model="text[props.selectedLang]" />
                    <!-- <input type="text" v-model="text[props.selectedLang]" class="form-control" placeholder="Testo"> -->
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Allegati</label>
                        <file-pond
                            class="mt-3"
                            name="test"
                            :ref="`attachments_${props.selectedLang}`"
                            label-idle="Clicca qui o trascina i file"
                            v-bind:allow-multiple="true"
                            :files="attachments[props.selectedLang]"
                            v-on:addfile="(error, file) => updateAttachments(props.selectedLang)"
                            v-on:removefile="(error, file) => updateAttachments(props.selectedLang)"
                            />

                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(att, index) in attachmentsSaved[props.selectedLang]" :key="att.id">
                                {{ att.description }}
                                <span class="badge badge-primary badge-pill">
                                    <a class="btn btn-primary mreye" @click="utils.download(att.file)"><i class="fa fa-eye"></i></a>
                                    <a class="btn btn-danger" @click="deleteAttachment(att, index, props.selectedLang)"><i class="fa fa-trash"></i></a>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Immagini</label>
                        <file-pond
                            class="mt-3"
                            name="test" :maxFileSize="constants.MAX_FILE_SIZE" :maxFiles="constants.MAX_FILES"
                            labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                            :ref="`images_${props.selectedLang}`"
                            label-idle="Clicca qui o trascina i file"
                            v-bind:allow-multiple="true"
                            :files="images[props.selectedLang]"
                            :accepted-file-types="constants.ACCEPTED_FILE_TYPES"
                            v-on:addfile="(error, file) => updateImages(props.selectedLang)"
                            v-on:removefile="(error, file) => updateImages(props.selectedLang)"
                            />
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(att, index) in imagesSaved[props.selectedLang]" :key="att.id">
                                <img width="50%" class="max-height-200" :src="att.image">
                                <span class="badge badge-primary badge-pill">
                                    <a class="btn btn-primary mr-2 mreye" @click="utils.download(att.image)"><i class="fa fa-eye"></i></a>
                                    <a class="btn btn-danger" @click="deleteImage(att, index, props.selectedLang)"><i class="fa fa-trash"></i></a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </lang-tabs>
    </panel>
</template>
<script>
import NewsService from '../api/NewsService.js'
import utils from '../config/utils.js'
import swal from '../config/swal.js'
import loader from '../config/loader.js'
import constants from '../config/constants.js'

export default {
    data() {
        return {
            constants,
            id: "",
            title: utils.getEmptyObjectLang(),
            summary: utils.getEmptyObjectLang(),
            text: utils.getEmptyObjectLang(),
            published: true,
            coverImage: null,
            newCoverImage: null,
            attachments: utils.getEmptyObjectLangArray(),
            images: utils.getEmptyObjectLangArray(),
            attachmentsSaved: utils.getEmptyObjectLangArray(),
            imagesSaved: utils.getEmptyObjectLangArray(),
            utils: utils,
            availableLangs: [],
            isSaved: false,
            oldData: "",
            weight: 0
        }
    },
    async mounted() {
        loader.showLoader()
        if (this.isEdit) {
            this.id = this.$route.params.id
            await NewsService.getNews(this.id)
                .then(resp => {
                    this.title = resp.data.title
                    this.summary = resp.data.summary
                    this.text = resp.data.text
                    this.published = resp.data.published
                    this.coverImage = resp.data.cover_image
                    this.weight = resp.data.weight
                    for (var lang in resp.data.attachments) {
                        this.attachmentsSaved[lang] = resp.data.attachments[lang]
                    }
                    for (var langImg in resp.data.images) {
                        this.imagesSaved[langImg] = resp.data.images[langImg]
                    }
                    if(!utils.checkIsUndefined(this.title)){
                        for(var key in this.title){
                            this.availableLangs.push(key)
                        }
                    }
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditNews"
        }
    },
    methods: {
        changePublished() {
            swal.withConfirm(
                'Sei sicuro di voler cambiare la visibilità?', 
                `Confermando ${this.published ? "non" : ""} sarà visibile!`, 
                'Visibilità cambiata. Ricordati di salvare!',
                () => {
                    this.published = !this.published
                })
        },
        async deleteAttachment(file, index, lang) {
            swal.withConfirm(
                'Sei sicuro di eliminare l\'allegato?', 
                `Confermando l'allegato verrà eliminato definitivamente!`, 
                'Allegato eliminato!',
                async () => {
                    loader.showLoader()
                    await NewsService.deleteAttachment(file.id)
                        .then(resp => {
                            console.log(resp)
                            this.attachmentsSaved[lang].splice(index, 1)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        },
        async deleteImage(file, index, lang) {
            swal.withConfirm(
                'Sei sicuro di eliminare l\'allegato?', 
                `Confermando l'allegato verrà eliminato definitivamente!`, 
                'Allegato eliminato!',
                async () => {
                    loader.showLoader()
                    await NewsService.deleteImage(file.id)
                        .then(resp => {
                            console.log(resp)
                            this.imagesSaved[lang].splice(index, 1)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        },
        updateCoverImage(files) {
            this.newCoverImage = files[0]
        },
        updateAttachments(lang) {
            this.attachments[lang] = this.$refs[`attachments_${lang}`].getFiles()
        },
        updateImages(lang) {
            this.images[lang] = this.$refs[`images_${lang}`].getFiles()
        },
        backRoute() {
            this.$router.push({
                name: 'News'
            })
        },
        async save() {
            loader.showLoader()
            let params = new FormData()
            params.append("published", this.published)
            params.append("weight", this.weight)
            if(!utils.checkIsUndefined(this.$refs.langTab.langs)) {
                var title = {}
                var summary = {}
                var text = {}
                var attachments = {}
                var images = {}
                this.$refs.langTab.langs.forEach(ls => {
                    if(ls.visible){
                        var langValue = ls.value
                        if(utils.checkIsUndefined(this.summary[langValue])){
                            summary[langValue] = ""
                        } else {
                            summary[langValue] = this.summary[langValue]
                        }
                        if(utils.checkIsUndefined(this.title[langValue])){
                            title[langValue] = ""
                        } else {
                            title[langValue] = this.title[langValue]
                        }
                        if(utils.checkIsUndefined(this.text[langValue])){
                            text[langValue] = ""
                        } else {
                            text[langValue] = this.text[langValue]
                        }
                        attachments[langValue] = []
                        if(!utils.checkIsUndefined(this.attachments[langValue])){
                            this.attachments[langValue].forEach(att => {
                                if(utils.checkIsUndefinedOrEmpty(att) || typeof att == "string"){
                                    return
                                }
                                attachments[langValue].push(att.file)
                            });
                        }
                        images[langValue] = []
                        if(!utils.checkIsUndefined(this.images[langValue])){
                            this.images[langValue].forEach(att => {
                                if(utils.checkIsUndefinedOrEmpty(att) || typeof att == "string"){
                                    return
                                }
                                images[langValue].push(att.file)
                            });
                        }
                    }
                })
                params.append('summary', JSON.stringify(summary))
                params.append('text', JSON.stringify(text))
                params.append('title', JSON.stringify(title))
                for (var lang in attachments) {
                    var files = attachments[lang]
                    for (var idx in files) {
                        params.append('attachments_'+ lang, files[idx])
                    }
                }
                for (var langImg in images) {
                    var filesImg = images[langImg]
                    for (var idi in filesImg) {
                        params.append('images_'+ langImg, filesImg[idi])
                    }
                }
            }
            if (!utils.checkIsUndefinedOrEmpty(this.newCoverImage) && typeof this.newCoverImage != "string") {
                params.append('cover_image', this.newCoverImage.file)
            }
            if (this.isEdit) {
                await NewsService.changeNews(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await NewsService.newNews(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteNews() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await NewsService.deleteNews(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }


}
</script>