<template>
    <panel title="Dettaglio Materiale" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-danger" v-if="id" @click="deleteMaterial"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="form-group mb-3">
            <label>Ordine</label>
            <input type="text" v-model="weight" class="form-control" placeholder="Ordine">
        </div>
        <div v-for="lang in langs" :key="lang.value">
            <h5><span :class="`flag-icon flag-icon-${lang.icon}`"></span> {{ lang.title }}</h5>
            <div class="form-group mb-3">
                <!-- <label>Titolo</label> -->
                <input type="text" v-model="title[lang.value]" class="form-control" placeholder="Titolo">
            </div>
        </div>
        <!-- <lang-tabs>
            <template v-slot:content="props">
                <div class="form-group mb-3">
                    <label>Titolo</label>
                    <input type="text" v-model="title[props.selectedLang]" class="form-control" placeholder="Titolo">
                </div>
            </template>
        </lang-tabs> -->
    </panel>
</template>
<script>
import MaterialService from '../api/MaterialService.js'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import swal from '../config/swal.js'
import utils from '../config/utils.js'
export default {
    data() {
        return {
            id: "",
            title: utils.getEmptyObjectLang(),
            utils: utils,
            isSaved: false,
            oldData: "",
            langs: constants.LANGS_TABS,
            weight: 0
        }
    },
    async mounted() {
        loader.showLoader()
        if(this.isEdit) {
            this.id = this.$route.params.id
            await MaterialService.getMaterial(this.id)
                .then(resp => {
                    this.title = resp.data.title
                    this.weight = resp.data.weight
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditMaterial"
        }
    },
    methods: {
        backRoute(){
            this.$router.push({name: 'Materials'})
        },
        async save(){
            loader.showLoader()
            let params = new FormData()
                params.append("weight", this.weight)
                params.append("title", JSON.stringify(this.title))
            if(this.isEdit) {
                await MaterialService.changeMaterial(this.id, params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                await MaterialService.newMaterial(params)
                    .then(resp => {
                        console.log(resp)
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteMaterial() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await MaterialService.deleteMaterial(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    beforeRouteLeave(to, from, next) {
        if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
            next()
            return
        }
        swal.alertLeave(next)
    }


}
</script>