import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchNews(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/news/?format=datatables' + extra)
    },
    getNews(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/news/${id}/`)
    },
    changeNews(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/news/${id}/`, params)
    },
    newNews(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/news/`, params)
    },
    deleteNews(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/news/${id}/`)
    },
    deleteAttachment(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/news_attachment/${id}/`)
    },
    deleteImage(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/news_image/${id}/`)
    }
}
