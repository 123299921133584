import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchEmailTemplates(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/email/template/?format=datatables' + extra)
    },
    getEmailTemplate(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/email/template/${id}/`)
    },
    changeEmailTemplate(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/email/template/${id}/`, params)
    },
    newEmailTemplate(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/email/template/`, params)
    },
    deleteEmailTemplate(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/email/template/${id}/`)
    },
    deleteEmailTemplateFile(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/email/template-attachment/${id}/`)
    }
}
