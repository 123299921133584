<template>
    <panel title="Dettaglio Traduzione" :back="true" @backFn="backRoute">
        <template v-slot:buttons>
            <a class="btn btn-danger" v-if="id" @click="deleteConfig"><i class="fa fa-trash"></i> Elimina</a>
            <a class="btn btn-success" @click="save"><i class="fa fa-save"></i> Salva</a>
        </template>
        <div class="form-group mb-3">
            <label>Chiave</label>
            <input type="text" v-model="key" class="form-control" placeholder="Chiave">
        </div>
        <div class="form-check form-switch mb-3">
            <label class="form-check-label" for="flexSwitchCheckDefault">File?</label>
            <input v-model="is_file" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        </div>
        <div v-if="is_file">
            <div class="mb-3">
                <label class="form-check-label" for="flexSwitchCheckDefault">File</label>
                <file-pond
                    :maxFileSize="constants.MAX_FILE_SIZE_BIG"
                    labelMaxFileSizeExceeded="File troppo grande" labelMaxFileSize="Massimo {filesize}"
                    class="mt-2"
                    name="test"
                    ref="file"
                    label-idle="Clicca qui o trascina i file"
                    v-model="file"
                    v-bind:allow-multiple="false"
                    />
            </div>
            <div v-if="fetch_file">
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center wrap-anywhere">
                        <span>{{ fetch_file }}</span>
                        <span class="badge badge-primary badge-pill">
                            <button class="btn btn-primary mr-2 mreye" @click="utils.download(fetch_file)"><i class="fa fa-eye"></i></button>
                            <!-- <button class="btn btn-danger"
                                @click="deleteFile(file, index, props.selectedLang)"><i class="fa fa-trash"></i></button> -->
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-group mb-3" v-else>
            <label>Valore</label>
            <textarea type="text" rows="10" v-model="value" class="form-control" placeholder="Valore"/>
        </div>
    </panel>
</template>
<script>
import ConfigService from '../api/ConfigService.js'
import constants from '../config/constants.js'
import loader from '../config/loader.js'
import notification from '../config/notification.js'
import swal from '../config/swal.js'
import utils from '../config/utils.js'
export default {
    data() {
        return {
            id: "",
            value: "",
            utils: utils,
            constants,
            isSaved: false,
            oldData: "",
            langs: constants.LANGS_TABS,
            key: "",
            configs: [],
            file: null,
            fetch_file: null,
            is_file: false
        }
    },
    async mounted() {
        loader.showLoader()
        var string = `&start=0&length=9999`
        await ConfigService.fetchConfigs(string)
        .then(resp => {
            this.configs = resp.data.data
        })
        .catch(err => {
            console.log(err)
        })
        if(this.isEdit) {
            this.id = this.$route.params.id
            await ConfigService.getConfig(this.id)
                .then(resp => {
                    if(!utils.checkObjectIsUndefinedOrEmpty(resp.data.file)) {
                        this.is_file = true
                    }
                    this.fetch_file = resp.data.file
                    this.value = resp.data.value
                    this.key = resp.data.key
                })
        }
        this.oldData = utils.getJsonData(this.$data)
        loader.hideLoader()
    },
    computed: {
        isEdit() {
            return this.$route.name === "EditConfig"
        }
    },
    methods: {
        backRoute(){
            this.$router.push({name: 'Configs'})
        },
        async save(){

            loader.showLoader()
            let params = new FormData()
            params.append("key", this.key)
            params.append("value", this.value)
            if(!utils.checkArrayIsUndefinedOrEmpty(this.file)){
                params.append("file", this.file[0].file)
            }
            if(this.isEdit) {
                await ConfigService.changeConfig(this.id, params)
                    .then(() => {
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                var object = this.configs.find(e => e.key === this.key)
                if(!utils.checkObjectIsUndefinedOrEmpty(object)){
                    notification.notifyError("È gia presente una configurazione con questa chiave")
                    loader.hideLoader()
                    return
                }
                await ConfigService.newConfig(params)
                    .then(() => {
                        this.oldData = utils.getJsonData(this.$data)
                        this.isSaved = true
                        this.backRoute()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            loader.hideLoader()
        },
        async deleteConfig() {
            swal.withConfirm(
                'Sei sicuro di eliminare?', 
                `Confermando verrà eliminato definitivamente!`, 
                'Eliminato!',
                async () => {
                    loader.showLoader()
                    await ConfigService.deleteConfig(this.id)
                        .then(resp => {
                            console.log(resp)
                            this.backRoute()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    loader.hideLoader()
                })
        }
    },
    // beforeRouteLeave(to, from, next) {
    //     if(this.oldData === utils.getJsonData(this.$data) || this.isSaved) {
    //         next()
    //         return
    //     }
    //     swal.alertLeave(next)
    // }


}
</script>