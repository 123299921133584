import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchCategories(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/catalog/category/?format=datatables' + extra)
    },
    getCategory(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/catalog/category/${id}/`)
    },
    changeCategory(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/catalog/category/${id}/`, params)
    },
    newCategory(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/catalog/category/`, params)
    },
    deleteCategory(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/catalog/category/${id}/`)
    }
}
