<template>
    <panel title="Email Template">
        <template v-slot:buttons>
            <a class="btn btn-success" @click="newTemplate"><i class="fa fa-plus"></i> Nuovo Template</a>
        </template>
        <datatable :columns="columns" :rows="rows" @edit="edit" @fetchData="fetchData" :recordsTotal="recordsTotal" />
    </panel>
</template>

<script>
import EmailTemplateService from '../api/EmailTemplateService';
import constants from '../config/constants.js';
import loader from '../config/loader.js';
import utils from '../config/utils.js';
export default {
    data() {
        return {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    hidden: true,
                    index: 0
                },
                {
                    label: 'Codice',
                    field: 'code',
                    filterOptions: {
                        enabled: true,
                        placeholder: "Codice"
                    },
                    index: 1
                },
                {
                    label: "Comandi",
                    field: 'cmd',
                    width: '10%',
                    sortable: false
                }
            ],
            rows: [],
            recordsTotal: 0,
        };
    },
    async mounted() {
        this.fetchData()
    },
    methods: {
        async fetchData(stringReceived) {
            loader.showLoader()
            var string = utils.getStringFilter(this.columns)
            if(utils.checkIsUndefinedOrEmpty(stringReceived)) {
                string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
            } else {
                string += stringReceived
            }
            await EmailTemplateService.fetchEmailTemplates(string)
            .then(resp => {
                console.log(resp)
                this.rows = resp.data.data
                this.recordsTotal = resp.data.recordsFiltered
            })
            .catch(err => {
                console.log(err)
            })
            loader.hideLoader()
        },
        newTemplate() {
            this.$router.push({name: "NewEmailTemplate"})
        },
        edit(id) {
            this.$router.push({name: "EditEmailTemplate", params: {id: id}})
        }
    }
}
</script>