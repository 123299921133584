<template>
  <panel title="Audit">
    <div class="row mb-3">
      <div class="col-4">
        <label>Da</label>
        <datepicker v-model="from" :language="it" placeholder="Da" input-class="form-control bg-white"></datepicker>
      </div>
      <div class="col-4">
        <label>A</label>
        <datepicker v-model="to" :language="it" placeholder="A" input-class="form-control bg-white"></datepicker>
      </div>
      <div class="col-4">
        <label>Azione</label>
        <select v-model="action" type="text" class="form-select" placeholder="Azione">
          <option value=""></option>
          <option v-for="ac in actions" :key="ac.value" :value="ac.value">{{ getString(ac.name) }}</option>
        </select>
      </div>
      <!-- <div class="col-4">
          <label>Documento</label>
          <input v-model="document" type="text" class="form-control" placeholder="Documento">
      </div> -->
      <div class="col-4">
        <label>Utente</label>
        <select v-model="user" type="text" class="form-select" placeholder="Utente">
          <option value=""></option>
          <option v-for="us in users" :key="us.id" :value="us.id">{{ us.email }}</option>
        </select>
      </div>
      <div class="col-4 end-center-flex">
        <a @click="search" class="btn btn-success w-100">Cerca</a>
      </div>
    </div>

    <template v-slot:buttons>
      <a class="btn btn-warning" @click="exportCsv"><i class="fa fa-file-excel"></i> Excel</a>
    </template>
    <datatable :columns="columns" :rows="rows" @fetchData="fetchData" :recordsTotal="recordsTotal"/>
  </panel>
</template>

<script>
import {it} from 'vuejs-datepicker/dist/locale'
import AuditService from '../api/AuditService.js'
import UserService from '../api/UserService.js';
import constants from '../config/constants.js';
import audit_keys from '../config/audit_keys.js';
import loader from '../config/loader.js';
import utils from '../config/utils.js';
// var xlsx = require("xlsx");
// import xlsx from "xlsx-js-style";
import auditService from "../api/AuditService.js";
import notification from "@/config/notification";

export default {
  data() {
    return {
      it,
      from: '',
      to: '',
      action: '',
      document: '',
      user: '',
      columns: [
        {
          label: 'Id',
          field: 'id',
          hidden: true,
          index: 0
        },
        {
          label: 'Data',
          field: 'timestamp',
          type: 'date',
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ssX", // expects 2018-03-16
          dateOutputFormat: 'dd/MM/yyyy',
          index: 1
        },
        {
          label: 'Azione',
          field: 'action',
          index: 2
        },
        {
          label: 'Dati',
          field: 'data',
          index: 3
        },
        {
          label: 'Utente',
          field: 'user',
          index: 4
        },
        {
          label: 'Utente',
          field: 'user__id',
          hidden: true,
          index: 5
        },
        {
          label: 'Ip User Remoto',
          field: 'remote_addr',
          index: 6
        },
        {
          label: 'Utente',
          field: 'timestamp_gte',
          hidden: true,
          index: 7
        },
        {
          label: 'Utente',
          field: 'timestamp_lte',
          hidden: true,
          index: 8
        }
      ],
      rows: [],
      users: [],
      actions: [],
      recordsTotal: 0,
    };
  },
  async mounted() {
    loader.showLoader()
    var string = `&start=0&length=999999`
    await UserService.fetchUsers(string)
        .then(resp => {
          this.users = resp.data.data
        })
        .catch(err => {
          console.log(err)
        })
    await AuditService.fetchAuditActions()
        .then(resp => {
          this.actions = resp.data.data.actions
        })
        .catch(err => {
          console.log(err)
        })
    loader.hideLoader()
    if (utils.checkArrayIsUndefinedOrEmpty(utils.getLocalStorageFilter(this.$route.name))) {
      this.fetchData()
    } else {
      utils.getLocalStorageFilter(this.$route.name).forEach(element => {
        if (element.type === constants.SEARCH_KEY) {
          this.$set(this.columns[element.index].filterOptions, "filterValue", element.value)
        }
      });
    }
  },
  methods: {
    getString(key) {
      if (utils.checkIsUndefinedOrEmpty(audit_keys[key.toUpperCase()])) {
        return key
      }
      return audit_keys[key.toUpperCase()]
    },
    async exportCsv() {
      loader.showLoader()
      // var wb = xlsx.utils.book_new();
      // wb.SheetNames.push("Audit");
      // var ws_data = []
      // var excelRows = []
      var string = utils.getStringFilter(this.columns)
      if (!utils.checkIsUndefinedOrEmpty(this.from)) {
        string += `&columns[7][search][value]=${this.from.toISOString()}`
      }
      if (!utils.checkIsUndefinedOrEmpty(this.to)) {
        string += `&columns[8][search][value]=${this.to.toISOString()}`
      }
      if (!utils.checkIsUndefinedOrEmpty(this.action)) {
        string += `&columns[2][search][value]=${this.action}`
      }
      if (!utils.checkIsUndefinedOrEmpty(this.user)) {
        string += `&columns[5][search][value]=${this.user}`
      }
      string += `&start=0&length=9999999`
      await auditService.exportAudit(string)
          .then(resp => {
            utils.downloadFile(resp)
            console.log(resp)
          })
          .catch(err => {
            notification.notifyError("Errore durante il download")
            console.log(err)
          })
      // await this.doCall(string)
      //     .then(response => {
      //         excelRows = response
      //     })
      //     .catch(err => {
      //         console.log(err)
      //     })
      // var ws = null
      // if(utils.checkIsUndefinedOrEmpty(this.action)) {
      //     this.audit(excelRows, ws_data)
      //     ws = xlsx.utils.aoa_to_sheet(ws_data);
      //     wb.Sheets["Audit"] = ws;
      //     xlsx.writeFile(wb, `Audit.xlsx`, { bookType: "xlsx" });
      // } else {
      //     switch (this.action) {
      //         case "catalog_download_reserved_catalog":
      //             this.reservedCatalog(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_catalogo_riservato.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'download_price_list':
      //             this.priceList(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_listino_prezzi.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'download_download':
      //             this.downloadAction(excelRows, ws_data, false)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_file.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'download_reserved_download':
      //             this.downloadAction(excelRows, ws_data, true)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_file_riservati.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'catalog_search':
      //             this.searchExcel(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_ricerche.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'catalog_view_article_image':
      //             this.viewArticleImage(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_visualizzazione_immagini.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'catalog_download_article_component_pdf':
      //             this.viewArticleComponent(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_tavole_tecniche.xlsx`, { bookType: "xlsx" });
      //             break;
      //         case 'catalog_download_article_pdf':
      //             this.viewArticle(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_download_articoli.xlsx`, { bookType: "xlsx" });
      //             break;
      //         default:
      //             this.viewArticle(excelRows, ws_data)
      //             ws = xlsx.utils.aoa_to_sheet(ws_data);
      //             wb.Sheets["Audit"] = ws;
      //             xlsx.writeFile(wb, `Registro_visualizzazione_articoli.xlsx`, { bookType: "xlsx" });
      //             break;
      //     }
      // }
      // var ws = xlsx.utils.aoa_to_sheet(ws_data);
      // wb.Sheets["Audit"] = ws;
      // xlsx.writeFile(wb, `Audit.xlsx`, { bookType: "xlsx" });
      loader.hideLoader()
    },
    searchExcel(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      header.push(this.boldHeader('Categorie'))
      header.push(this.boldHeader('Materiali'))
      header.push(this.boldHeader('Descrizione'))
      header.push(this.boldHeader('Articolo'))
      header.push(this.boldHeader('Codice'))
      header.push(this.boldHeader('Unità diametro'))
      header.push(this.boldHeader('Diametro da'))
      header.push(this.boldHeader('Diametro a'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        if (row.data.categories.length == 0) {
          newRowExcel.push("")
        } else {
          var categories = ""
          row.data.categories.forEach(category => {
            categories += `${category.title.it}, `
          });
          newRowExcel.push(categories)
        }
        if (row.data.materials.length == 0) {
          newRowExcel.push("")
        } else {
          var materials = ""
          row.data.materials.forEach(material => {
            materials += `${material.title.it}, `
          });
          newRowExcel.push(materials)
        }
        newRowExcel.push(row.data.title)
        newRowExcel.push(row.data.article)
        newRowExcel.push(row.data.code)
        newRowExcel.push(row.data.diameter.unit)
        newRowExcel.push(row.data.diameter.value[0])
        newRowExcel.push(row.data.diameter.value[1])
        ws_data.push(newRowExcel)
      });
    },
    downloadAction(excelRows, ws_data, isReserved = false) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      header.push(this.boldHeader('File'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        newRowExcel.push(isReserved ? row.data.reserved_download.title.it : row.data.download.title.it)
        ws_data.push(newRowExcel)
      });
    },
    priceList(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      header.push(this.boldHeader('Listino Prezzi'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        newRowExcel.push(row.data.price_list.name.it)
        ws_data.push(newRowExcel)
      });
    },
    viewArticle(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      header.push(this.boldHeader('N articolo'))
      header.push(this.boldHeader('Descrizione'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        newRowExcel.push(row.data.article.code)
        newRowExcel.push(row.data.article.description.it)
        ws_data.push(newRowExcel)
      });
    },
    viewArticleImage(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      header.push(this.boldHeader('N articolo'))
      header.push(this.boldHeader('Descrizione'))
      header.push(this.boldHeader('Immagine'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        newRowExcel.push(row.data.article.code)
        newRowExcel.push(row.data.article.description.it)
        newRowExcel.push(row.data.article_image.id)
        ws_data.push(newRowExcel)
      });
    },
    viewArticleComponent(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      header.push(this.boldHeader('N articolo'))
      header.push(this.boldHeader('Descrizione'))
      header.push(this.boldHeader('Tavola'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        newRowExcel.push(row.data.article.code)
        newRowExcel.push(row.data.article.description.it)
        newRowExcel.push(row.data.component.code)
        ws_data.push(newRowExcel)
      });
    },
    reservedCatalog(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      header.push(this.boldHeader('Data'))
      header.push(this.boldHeader('Azione'))
      header.push(this.boldHeader('Utente'))
      header.push(this.boldHeader('Indirizzo IP'))
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        ws_data.push(newRowExcel)
      });
    },
    audit(excelRows, ws_data) {
      var header = []
      header.push(this.boldHeader('ID'))
      this.columns.forEach(col => {
        if (col.hidden) {
          return
        }
        header.push(this.boldHeader(col.label))
      });
      ws_data.push(header)
      excelRows.forEach(row => {
        var newRowExcel = []
        newRowExcel.push(row.id)
        newRowExcel.push(this.formatFn(row.timestamp))
        newRowExcel.push(this.getString(row.action))
        switch (row.action) {
          case 'catalog_download_reserved_catalog':
            newRowExcel.push('')
            break;
          case 'download_price_list':
            newRowExcel.push(`Listino Prezzi: ${row.data.price_list.name.it}`)
            break;
          case 'download_download':
            newRowExcel.push(`File: ${row.data.download.title.it}`)
            break;
          case 'download_reserved_download':
            newRowExcel.push(`File: ${row.data.reserved_download.title.it}`)
            break;
          case 'catalog_search':
            var stringData = ""
            if (row.data.categories.length > 0) {
              stringData += "Categorie: "
              row.data.categories.forEach(category => {
                stringData += `${category.title.it}, `
              });
            }
            if (row.data.materials.length > 0) {
              stringData += "Materiali: "
              row.data.materials.forEach(material => {
                stringData += `${material.title.it}, `
              });
            }
            if (!utils.checkIsUndefinedOrEmpty(row.data.title)) {
              stringData += `Descrizione: ${row.data.title}, `
            }
            if (!utils.checkIsUndefinedOrEmpty(row.data.article)) {
              stringData += `Articolo: ${row.data.article}, `
            }
            if (!utils.checkIsUndefinedOrEmpty(row.data.code)) {
              stringData += `Codice: ${row.data.code}, `
            }
            if (row.data.diameter.value[1] > 0) {
              stringData += `Diametro: ${row.data.diameter.value[0]} - ${row.data.diameter.value[1]} ${row.data.diameter.unit}`
            }
            newRowExcel.push(stringData)
            break;
          case 'catalog_view_article_image':
            newRowExcel.push(`N articolo: ${row.data.article.code}, Descrizione: ${row.data.article.description.it}, Immagine: ${row.data.article_image.id}`)
            break;
          case 'catalog_download_article_component_pdf':
            newRowExcel.push(`N articolo: ${row.data.article.code}, Descrizione: ${row.data.article.description.it}, Tavola: ${row.data.component.code}`)
            break;
          default:
            newRowExcel.push(`N articolo: ${row.data.article.code}, Descrizione: ${row.data.article.description.it}`)
            break;
        }
        // for(var key in row.data) {
        //     stringData += `${key}: ${row.data[key]}, `
        // }
        // newRowExcel.push(stringData)
        if (utils.checkIsUndefinedOrEmpty(row.user)) {
          newRowExcel.push(``)
        } else {
          newRowExcel.push(`${row.user.username}`)
        }
        newRowExcel.push(row.remote_addr)
        ws_data.push(newRowExcel)
      });
    },
    boldHeader(value) {
      return {v: value, t: "s", s: {font: {bold: true}}}
    },
    formatFn(value) {
      return new Date(value).toLocaleDateString("it-IT")
    },
    async doCall(stringReceived) {
      loader.showLoader()
      var rows = []
      await AuditService.fetchAudit(stringReceived)
          .then(resp => {
            rows = resp.data.data
            this.recordsTotal = resp.data.recordsFiltered
          })
          .catch(err => {
            console.log(err)
          })
      loader.hideLoader()
      return rows
    },
    async fetchData(stringReceived) {
      // loader.showLoader()
      var string = utils.getStringFilter(this.columns)
      if (utils.checkIsUndefinedOrEmpty(stringReceived)) {
        string += `&start=0&length=${constants.PAGINATION_OPTIONS.perPage}`
      } else {
        string += stringReceived
      }
      await this.doCall(string)
          .then(resp => {
            this.rows = resp
          })
          .catch(err => {
            console.log(err)
          })
      // loader.hideLoader()
    },
    async search() {
      var string = utils.getStringFilter(this.columns)
      if (!utils.checkIsUndefinedOrEmpty(this.from)) {
        string += `&columns[7][search][value]=${this.from.toISOString()}`
      }
      if (!utils.checkIsUndefinedOrEmpty(this.to)) {
        string += `&columns[8][search][value]=${this.to.toISOString()}`
      }
      if (!utils.checkIsUndefinedOrEmpty(this.action)) {
        string += `&columns[2][search][value]=${this.action}`
      }
      if (!utils.checkIsUndefinedOrEmpty(this.user)) {
        string += `&columns[5][search][value]=${this.user}`
      }
      this.doCall(string)
          .then(resp => {
            this.rows = resp
          })
          .catch(err => {
            console.log(err)
          })
    }
  }
}
</script>