import Api from './index.js'
import store from '../store/store.js'

export default {
    fetchUsers(extra = "") {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get('api/admin/user/?format=datatables' + extra)
    },
    getUser(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().get(`api/admin/user/${id}/`)
    },
    changeUser(id, params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().patch(`api/admin/user/${id}/`, params)
    },
    newUser(params) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().post(`api/admin/user/`, params)
    },
    deleteUser(id) {
        var token = store.state.token
        Api().setAuthHeaderWithToken(token)
        return Api().delete(`api/admin/user/${id}/`)
    }
}
